import { Card, Checkbox, Col, Input, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VhlAssetValue() {
  return (
    <div>
      <Card bordered={false}>
        <div className="d-flex justify-content-between">
          <h3 className="text-black">Asset Value</h3>
          <h3 style={{ color: "#545454" }}>5,600 € /sqm</h3>
          <h3 className="text-black">23,400,000 €</h3>
        </div>
        <hr className="mt-0 border-top-0 border-2" />
        <Row className="pt-2" gutter={14}>
          <Col>
            <h4 className="text-black">Steps</h4>
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <Input
                  style={{
                    width: 50,
                    border: "1px solid #B7B7B7",
                    padding: "12px",
                  }}
                />
              </div>
              <p className="mb-0 pl-2">BP</p>
            </div>
          </Col>
          <Col>
            <h4 className="text-black">Discount rate %</h4>
            <IncDecCounter />
          </Col>
          <Col>
            <h4 className="text-black">Spread</h4>
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <Input
                  style={{
                    width: 50,
                    border: "1px solid #B7B7B7",
                    padding: "12px",
                  }}
                />
              </div>
              <p className="mb-0 pl-2">BP</p>
            </div>
          </Col>
          <Col>
            <h4 className="text-black">Exit cap rate %</h4>
            <div className="d-flex align-items-center">
              <IncDecCounter className="mr-2" />
              <Checkbox>Lock</Checkbox>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VhlAssetValue;
