/* eslint-disable */
import PropTypes from "prop-types";
import moment from "moment";
import { useState, useEffect } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Menu,
  notification,
  Row,
  Col,
  DatePicker,
} from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteRentRoll,
  getLeaseTermByAssetId,
  updateLeaseTerm,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { updateVisibleColumns } from "../../../../../../../app/features/Lookups/lookup.slice";

function LeaseTerm({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const param = useParams();
  const assetId = param.assetDetailId;
  const [editingKey, setEditingKey] = useState("");
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const [toggle, setToggle] = useState([]);
  const { rentRollListLeaseTerm, isLoading } = useSelector(
    (state) => state.rentRoll
  );
  const [rentRollFilter, setRentRollFilter] = useState({
    Search: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size -
    rentRollFilter.pagination.size +
    1;

  const nextPageNumber =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size >
    rentRollListLeaseTerm?.totalRecords
      ? rentRollListLeaseTerm?.totalRecords
      : rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    option1: null,
    option2: null,
    option3: null,
    isValid: true,
  });
  const dateChange = (value, field) => {
    setDates({
      ...dates,
      [field]: value?._d ? moment(value?._d).format("YYYY-MM-DD") : null,
    });
  };

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "number" ? (
        <Input type={inputType} />
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <>
            <DatePicker
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => dateChange(e, dataIndex)}
              className="w-100 rounded-sm"
              defaultValue={
                dates?.[dataIndex] && moment(dates?.[dataIndex], "YYYY-MM-DD")
              }
              dateFormat="YYYY-MM-DD"
            />

            {(dataIndex === "startDate" || dataIndex === "endDate") &&
              dates.isValid === false && (
                <p className="text-danger mb-0">
                  Start Date Can't be greater than End Date
                </p>
              )}
            {dataIndex === "option1" && dates.option1 === null && (
              <p className="text-danger mb-0">Option 1 is required</p>
            )}
          </>
        ) : (
          children
        )}
      </td>
    );
  }

  const localStorageData = localStorage.getItem("reia_user");
  const UserId = localStorageData ? JSON.parse(localStorageData) : null;

  const isEditing = (record) => record.rentRollId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.rentRollId);
    setDates({
      startDate: record.startDate,
      endDate: record.endDate,
      option1: record.option1,
      option2: record.option2,
      option3: record.option3,
    });
  };
  const cancel = () => {
    setEditingKey("");
  };
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");

    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getLeaseTermByAssetId(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getLeaseTermByAssetId(finalData));
  };

  const validateStartDate = () => {
    let isValidDate = false;
    if (dates.startDate > dates.endDate) {
      isValidDate = false;
    } else {
      isValidDate = true;
    }
    setDates({ ...dates, isValid: isValidDate });
    return isValidDate;
  };
  const save = async (record) => {
    try {
      // const row = await form.validateFields();
      const validateDate = validateStartDate();
      if (!validateDate) {
        return;
      }
      const finalData = {
        rentRollId: record.rentRollId,
        startDate: dates.startDate,
        endDate: dates.endDate,
        option1: dates.option1,
        option2: dates.option2,
        option3: dates.option3,
      };
      dispatch(updateLeaseTerm({ finalData, moveToNext, notifyToaster }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setRentRollFilter(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      projectId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getProjectDetailById(finalData));
  };
  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteRentRoll({ finalData, moveToNextDelete, notifyToaster }));
  };

  const leaseTermColumns = [
    {
      id: 1,
      title: "PID",
      dataIndex: "rentRollId",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "statusType",
      hidden: false,
    },
    {
      id: 3,
      title: "Start",
      dataIndex: "startDate",
      hidden: toggle.includes("startDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "startDate",
        title: "Start",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "End",
      dataIndex: "endDate",
      hidden: toggle.includes("endDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "endDate",
        title: "End",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Option 1",
      dataIndex: "option1",
      hidden: toggle.includes("option1"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "option1",
        title: "Option 1",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "Option 2",
      dataIndex: "option2",
      hidden: toggle.includes("option2"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "option2",
        title: "Option 2",
        editing: isEditing(record),
      }),
    },
    {
      id: 7,
      title: "Option 3",
      dataIndex: "option3",
      hidden: toggle.includes("option3"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "option3",
        title: "Option 3",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const basicDatahideTableColumns = [
    {
      key: 1,
      type: "group",
    },

    {
      key: 2,
      value: "startDate",
      name: "Start",
      isChecked: false,
    },
    {
      key: 3,
      value: "endDate",
      name: "End",
      isChecked: false,
    },
    {
      key: 4,
      value: "option1",
      name: "Option 1",
      isChecked: false,
    },
    {
      key: 5,
      value: "option2",
      name: "Option 2",
      isChecked: false,
    },
    {
      key: 6,
      value: "option3",
      name: "Option 3",
      isChecked: false,
    },
    {
      key: 7,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(basicDatahideTableColumns);

  useEffect(() => {
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getLeaseTermByAssetId(finalData));
  }, [dispatch, rentRollFilter]);
  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    rentRollListLeaseTerm?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [rentRollListLeaseTerm]);

  useEffect(() => {
    if (rentRollListLeaseTerm) {
      const a = rentRollListLeaseTerm.visibleColumns?.map(
        (item) => item.moduleId
      );
      setModuleIdValue(a?.pop());

      const b = rentRollListLeaseTerm.visibleColumns?.map(
        (item) => item.moduleTabId
      );
      setModuleTabIdValue(b?.pop());
    }
  }, [rentRollListLeaseTerm]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 7) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 7) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  const formData = rentRollListLeaseTerm?.leaseTerm
    ? rentRollListLeaseTerm?.leaseTerm.map((item) => {
        const { startDate, endDate, option1, option2, option3 } = item;
        return {
          ...item,
          startDate: startDate && startDate?.split("T")[0],
          endDate: endDate && endDate?.split("T")[0],
          option1: option1 && option1?.split("T")[0],
          option2: option2 && option2?.split("T")[0],
          option3: option3 && option3?.split("T")[0],
        };
      })
    : [];

  return (
    <>
      <div className="rentroll-table">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            columns={leaseTermColumns.filter((item) => item.hidden === false)}
            dataSource={formData}
            pagination={{
              current: rentRollFilter?.pagination?.pageNo || 1,
              pageSize: 10,
              total: rentRollListLeaseTerm?.totalRecords,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                setRentRollFilter({
                  ...rentRollFilter,
                  pagination: {
                    pageNo: page,
                    size: pageSize,
                  },
                });
              },
            }}
            className="antd-table-striped"
            rowSelection={rowSelection}
            loading={isLoading}
            rowKey={(record) => record.rentRollId}
            scroll={{
              x: 900,
              y: "calc(100vh - 472px)",
            }}
          />
        </Form>
      </div>
      {rentRollListLeaseTerm?.leaseTerm?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {rentRollListLeaseTerm?.totalRecords} entries
            </h5>
          </div>

          <Row gutter={24} className="ml-0">
            <Col
              xs={24}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </>
  );
}

export default LeaseTerm;

// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };
LeaseTerm.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};
