import { CheckCircleFilled } from "@ant-design/icons";
import { Dropdown, Menu, Typography } from "antd";
// import PropTypes from "prop-types";
import { useState } from "react";
import { BsFillEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
import { useSelector } from "react-redux";
// import { MdArchive, MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
// import { getAllCompletedProjects } from "../../../../../app/features/Projects/project.slice";
import DataTable from "../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../utils/path";

export default function CompleteProjects() {
  // const { showCompletedProject } = props;
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { Text } = Typography;

  const { getCompletedProjects, isLoading } = useSelector(
    (state) => state.projects
  );
  const [completeProjectFilter, setCompleteProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  // profile Dropdown
  // const profileDropdown = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <div
  //             className="d-flex justify-content-left align-items-center table-dropdown"
  //             role="button"
  //             tabIndex="0"
  //             onClick={() => navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)}
  //             onKeyDown={() =>
  //               navigate(PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL)
  //             }
  //           >
  //             <BsEyeFill />
  //             <h5 className="mb-0 ml-3">View Project</h5>
  //           </div>
  //         ),
  //       },
  //       // {
  //       //   key: "2",
  //       //   label: (
  //       //     <div
  //       //       className="d-flex justify-content-left align-items-center table-dropdown"
  //       //       role="button"
  //       //       tabIndex="0"
  //       //       onClick={() => setEditProjectModal(true)}
  //       //       onKeyDown={() => setEditProjectModal(true)}
  //       //     >
  //       //       <MdModeEdit />
  //       //       <h5 className="mb-0 ml-3">Edit project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       // {
  //       //   key: "3",
  //       //   label: (
  //       //     <div className="d-flex justify-content-left align-items-center table-dropdown">
  //       //       <MdArchive />
  //       //       <h5 className="mb-0 ml-3">Archive Project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       // {
  //       //   key: "4",
  //       //   label: (
  //       //     <div className="d-flex justify-content-left align-items-center table-dropdown">
  //       //       <MdShare />
  //       //       <h5 className="mb-0 ml-3">Share Project</h5>
  //       //     </div>
  //       //   ),
  //       // },
  //       {
  //         key: "5",
  //         label: (
  //           <div className="d-flex justify-content-left align-items-center table-dropdown">
  //             <FaShareSquare />
  //             <h5 className="mb-0 ml-3">Export Project</h5>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const initialColState = [
    {
      id: 1,
      hidden: false,
      title: "Project Reference",
      dataIndex: "projectId",
      render: (text) => (
        <div>
          <CheckCircleFilled className="completed-icon" />
          <span>{text}</span>
        </div>
      ),
    },
    {
      id: 2,
      hidden: false,
      title: "Project Name",
      dataIndex: "projectName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 3,
      hidden: false,
      title: "Project Manager",
      dataIndex: "projectManager",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Client Name",
      dataIndex: "clientName",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 5,
      hidden: false,
      title: "Project Type",
      dataIndex: "projectType",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 6,
      hidden: false,
      title: "Project Status",
      dataIndex: "projectStatus",
      render: (text) => (text !== null ? text : "N/A"),
    },
    {
      id: 7,
      hidden: false,
      title: "Notes",
      dataIndex: "description",
      render: (text) => (
        <Text
          style={{
            width: 200,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text !== null ? text : "N/A"}
        </Text>
      ),
    },
    {
      id: 8,
      hidden: false,
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.projectId
                            )
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.projectId
                            )
                          )
                        }
                      >
                        <BsFillEyeFill />
                        <h5 className="mb-0 ml-3">View project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(PATH.PROJECT_DETAILS.replace(":projectId", path.projectId)); // to avoid es lint error
      }, // click row
    };
  };

  // const [columns, setColumns] = useState(initialColState);

  // const handleReset = () => {
  //   setColumns(initialColState);
  //   // setCheckList([]);
  // };

  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //   } else {
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };

  // const basicDatahideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="projectName"
  //             name="Project Name"
  //             id={2}
  //             // checked={checkList.includes("valDate")}
  //             onChange={handleChange}
  //           >
  //             Project Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="projectManager"
  //             name="Project Manager"
  //             id={3}
  //             // checked={checkList.includes("projectNo")}
  //             onChange={handleChange}
  //           >
  //             Project Manager
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="clientName"
  //             name="Client Name"
  //             id={4}
  //             // checked={checkList.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Client Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="projectType"
  //             name="Project Type"
  //             id={5}
  //             // checked={checkList.includes("nameOfClient1")}
  //             onChange={handleChange}
  //           >
  //             Project Type
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="projectStatus"
  //             name="Project Status"
  //             id={6}
  //             // checked={checkList.includes("nameOfClient2")}
  //             onChange={handleChange}
  //           >
  //             Vacancy
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="notes"
  //             name="description"
  //             id={7}
  //             // checked={checkList.includes("instruction")}
  //             onChange={handleChange}
  //           >
  //             Notes
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={() => handleReset()}>Reset</Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // useEffect(() => {
  //   // sethideTableColumns(basicDatahideTableColumns);
  //   dispatch(getAllCompletedProjects());
  // }, [showCompletedProject]);

  const pageNumbers =
    completeProjectFilter.pagination.pageNo *
      completeProjectFilter.pagination.size -
    completeProjectFilter.pagination.size +
    1;

  const nextPageNumber =
    completeProjectFilter.pagination.pageNo *
      completeProjectFilter.pagination.size >
    getCompletedProjects?.[0]?.totalRecords
      ? getCompletedProjects?.[0]?.totalRecords
      : completeProjectFilter.pagination.pageNo *
        completeProjectFilter.pagination.size;
  return (
    <div>
      <div className="mt-3">
        <DataTable
          columns={initialColState}
          dataSource={getCompletedProjects}
          pagination={{
            current: completeProjectFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: getCompletedProjects?.[0]?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setCompleteProjectFilter({
                ...completeProjectFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          onRow={navigateRow}
          scroll={{
            x: 1150,
            y: "calc(100vh - 322px)",
          }}
          loading={isLoading}
          rowKey={(record) => record}
        />
      </div>
      {getCompletedProjects?.length > 0 && (
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of{" "}
            {getCompletedProjects?.[0]?.totalRecords} entries
          </h5>
        </div>
      )}
    </div>
  );
}
CompleteProjects.propTypes = {
  // setEditProjectModal: PropTypes.node.isRequired,
  // sethideTableColumns: PropTypes.node.isRequired,
  // showCompletedProject: PropTypes.node.isRequired,
};
