import { Button, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

function MarketRentAndTrueMR(props) {
  const { next, prev, handleCancel } = props;
  const [rentValue, setRentValue] = useState({
    square: "",
    month: "",
    year: "",
  });
  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem(
      "marketRent",
      JSON.stringify({ ...values, ...rentValue })
    );

    next();
  };
  const localStorageData = localStorage.getItem("marketRent");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const handleValue = (e) => {
    const squarePerMeter = e.target.value;
    setRentValue({
      square: squarePerMeter,
      month: squarePerMeter * daysInCurrentMonth,
      year: squarePerMeter * daysInCurrentMonth * 12,
    });
  };
  if (FormData !== null) {
    useEffect(() => {
      if (FormData.perSqM) {
        const squarePerMeter = FormData?.perSqM;
        setRentValue({
          square: squarePerMeter,
          month: squarePerMeter * daysInCurrentMonth,
          year: squarePerMeter * daysInCurrentMonth * 12,
        });
      }
    }, []);
  }

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Market Rent & True MR
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Sq M"
              name="perSqM"
              initialValue={FormData?.perSqM}
              rules={[
                {
                  required: true,
                  message: "Please input Per Sq M!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input
                // maxLength={50}
                onChange={handleValue}
                value={rentValue.square}
                placeholder="Per Sq M"
                type="number"
                className="w-100"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Month"
              // name="rent per month"
              initialValue={FormData?.month}
              rules={[
                {
                  required: false,
                  message: "Please input rent",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input
                value={rentValue?.month}
                // type="number"
                disabled
                placeholder="Per Month"
                className="w-100"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Per Year"
              initialValue={FormData?.year}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input
                text="name"
                value={rentValue?.year}
                placeholder="Per Year"
                disabled
                className="w-100"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default MarketRentAndTrueMR;

MarketRentAndTrueMR.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
