/* eslint-disable */
import api from "../../../utils/api";

// Create an account for projects
const createPortfolio = async (data) => {
  const response = await api.post(`/api/Portfolio/save`, data);
  return response.data;
};

// Get All list of projects
const getAllPortfolio = async (data) => {
  const response = await api.get(`/api/Portfolio/getByProjectId`, {
    params: data,
  });
  return response.data.data;
};

// Create an account for projects
const mergeAssetToPortfolio = async (data) => {
  const response = await api.post(`/api/Portfolio/mergeAssetToPortfolio`, data);
  return response.data;
};

const assignAssetToUser = async (data) => {
  const response = await api.post(`/api/Asset/assignAssetsToUser`, data);
  return response.data;
};

// Delete projects
const deletePortfolio = async (data) => {
  const response = await api.delete("/api/Portfolio/delete", { data: data });
  return response.data;
};

const portfolio = {
  createPortfolio,
  getAllPortfolio,
  mergeAssetToPortfolio,
  deletePortfolio,
  assignAssetToUser,
};

export default portfolio;
