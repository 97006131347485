/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Row,
  Input,
  Tabs,
  message,
} from "antd";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import IMAGES from "../../../../../assets/images";
import BasicData from "./component/RentRoll/BasicData";
import CurrentRent from "./component/RentRoll/CurrentRent";
import Duration from "./component/RentRoll/Duration";
import Indexation from "./component/RentRoll/Indexation";
import LeaseTerm from "./component/RentRoll/LeaseTerm";
import MarketRentAndTrueMR from "./component/RentRoll/MarketRentAndTrueMR";
import NonRecoverableCostsReletting from "./component/RentRoll/NonRecoverableCostsReletting";
import NonRecoverableCostsTerm from "./component/RentRoll/NonRecoverableCostsTerm";
import Presettings from "./component/RentRoll/Presettings";
import RelettingCosts from "./component/RentRoll/RelettingCosts";
import RenewalProbability from "./component/RentRoll/RenewalProbability";
import SearchForm from "./component/RentRoll/SearchForm";
import VacancyCosts from "./component/RentRoll/VacancyCosts";
import VoidPeriod from "./component/RentRoll/VoidPeriod";
import CreateUpdateRentRoll from "./CreateUpdateRentRoll";

function RentRoll() {
  const [activeKey, setActiveKey] = useState(1);
  const [current, setCurrent] = useState("mail");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);

  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const { Search } = Input;
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onImageChange = (event) => {
    // const url = URL.createObjectURL(event.target.files[0])
    message.success(`${event.target.files[0].name} file uploaded successfully`);
  };
  const addNewDropdown = (
    <Menu
      className="projects-add-new-drop-down p-0 py-2"
      items={[
        {
          key: "1",
          type: "group",
          label: "Create New",
          children: [
            {
              key: "1-1",
              label: (
                <div
                  className="d-flex align-items-center change-text-color"
                  role="button"
                  tabIndex="0"
                  onClick={() => setAddUpdateNewRentRoll(true)}
                  onKeyDown={() => setAddUpdateNewRentRoll(true)}
                >
                  <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
                  <p className="mb-0 text-style">Rent Roll</p>
                </div>
              ),
            },
            // {
            //   key: "1-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //       onClick={() => setAddUpdateNewRentRoll(true)}
            //       onKeyDown={() => setAddUpdateNewRentRoll(true)}
            //     >
            //       <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
        {
          key: "2",
          type: "group",
          label: "Upload from Excel",
          children: [
            {
              key: "2-1",
              label: (
                <label
                  htmlFor="xls-upload"
                  className="rentroll-upload cursor-pointer"
                >
                  <div className="d-flex align-items-center change-text-color">
                    <RiFileExcel2Line
                      className="mb-0 change-icon-color"
                      size={18}
                    />
                    <p className="mb-0 text-style">Rent Roll</p>
                  </div>
                  <input
                    type="file"
                    id="xls-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onImageChange}
                  />
                </label>
              ),
            },
            // {
            //   key: "2-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //     >
            //       <RiFileExcel2Line className="mb-0 change-icon-color" size={18} />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
      ]}
    />
  );

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleChange = (key) => {
    setActiveKey(key);
  };

  // Hide Table Column Dropdown
  const initialHideTableColumns = (
    <Menu
      items={[
        {
          key: "1",
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        {
          key: "2",
          label: <Checkbox>Column 1</Checkbox>,
        },
        {
          key: "3",
          label: <Checkbox>Column 2</Checkbox>,
        },
        {
          key: "4",
          label: <Checkbox>Column 3</Checkbox>,
        },
        {
          key: "5",
          label: <Checkbox>Column 4</Checkbox>,
        },
        {
          key: "6",
          label: <Checkbox>Column 5</Checkbox>,
        },
        {
          key: "7",
          label: <Checkbox>Column 6</Checkbox>,
        },
        {
          key: "8",
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn">Reset</Button>
            </div>
          ),
        },
      ]}
    />
  );

  const [hideTableColumns, sethideTableColumns] = useState(
    initialHideTableColumns
  );
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <div className="rentRoll">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        // items={items}
      />

      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col xs={5}>
          <div>
            <Dropdown
              overlay={addNewDropdown}
              trigger={["click"]}
              direction="vertical"
            >
              <Button
                type="default"
                className="add-project-btn d-flex justify-content-between align-items-center"
              >
                <h5 className="mb-0 ml-0">Add New</h5>
                <span className="project-btn-span asset-details-add-new-btn">
                  <IoIosArrowDown size={30} />
                </span>
              </Button>
            </Dropdown>
          </div>
        </Col>
        <Col xs={7}>
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              className="mr-2"
              placeholder="Search a Rent roll"
              // onClick={() => setIsModalOpen(true)}
            />
            <span
              onClick={() => setIsModalOpen(true)}
              className="filter-icon-span mr-2 cursor-pointer"
            >
              <FaFilter />
            </span>
            <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span mr-2 cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <div>
        <Tabs defaultActiveKey="1" onChange={handleChange}>
          <Tabs.TabPane tab="Basic Data" key="1">
            {(activeKey === 1 || activeKey === "1") && (
              <BasicData
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Current Rent" key="2">
            {(activeKey === 2 || activeKey === "2") && (
              <CurrentRent
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Lease Term" key="3">
            {(activeKey === 3 || activeKey === "3") && (
              <LeaseTerm
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Duration" key="4">
            {(activeKey === 4 || activeKey === "4") && (
              <Duration
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Renewal Probability" key="5">
            {(activeKey === 5 || activeKey === "5") && (
              <RenewalProbability
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Indexation" key="6">
            {(activeKey === 6 || activeKey === "6") && (
              <Indexation
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Presettings" key="7">
            {(activeKey === 7 || activeKey === "7") && (
              <Presettings
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Market Rent &amp; True MR" key="8">
            {(activeKey === 8 || activeKey === "8") && (
              <MarketRentAndTrueMR
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Non Recoverable Costs - Term" key="9">
            {(activeKey === 9 || activeKey === "9") && (
              <NonRecoverableCostsTerm
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Non Recoverable Costs - Reletting" key="10">
            {(activeKey === 10 || activeKey === "10") && (
              <NonRecoverableCostsReletting
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Void Period" key="11">
            {(activeKey === 11 || activeKey === "11") && (
              <VoidPeriod
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Vacancy Costs" key="12">
            {(activeKey === 12 || activeKey === "12") && (
              <VacancyCosts
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Reletting Costs" key="13">
            {(activeKey === 13 || activeKey === "13") && (
              <RelettingCosts
                sethideTableColumns={sethideTableColumns}
                activeKey={activeKey}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>

      <SearchForm visible={isModalOpen} handleCancel={closeModal} />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </div>
  );
}

export default RentRoll;
