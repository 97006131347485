import { Card, Col, Row } from "antd";
import ReactApexChart from "react-apexcharts"

export default function LeaseAnalysisChart() {

    const data = {

        series: [
            {
                name: 'Secured income',
                data: [500, 600, 1200, 400, 550, 750, 330, 530, 500, 1200],
                type: 'column',
            }, {
                name: 'Potential rent',
                data: [400, 550, 750, 330, 530, 500, 600, 700, 500, 550],
                type: 'column',
            }, {
                name: 'Market rent',
                data: [500, 600, 500, 400, 550, 750, 330, 530, 500, 900],
                type: 'column',
            },
            {
                name: 'Occupancy',
                data: [10, 30, 60, 60, 70, 50, 40, 40, 60, 90],
                type: 'line',
            }
        ],
        options: {
            chart: {
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                },
                height: 350,
                type: 'line',
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#222A35', '#44546A', '#B4C7E7', '#9E9E9E'],
            stroke: {
                show: true,
                width: [2, 2, 2, 2],
            },
            fill: {
                opacity: 1,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            xaxis: {
                categories: ['Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'Y6', 'Y7', 'Y8', 'Y9', 'Y10'],
            },
            title: {
                text: "Over -/Underrent +0.0 %",
                align: 'right',
                offsetY: 35,
            },
            yaxis: [
                {
                    title: {
                        text: "Thousand EUR",
                    },

                },
                {
                    show: false,
                },
                {
                    show: false,
                },
                {
                    show: true,
                    opposite: true,
                    labels: {
                        formatter: (value) => `${value}%`,
                    },
                },
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                show: true,
                position: 'top',
                markers: {
                    radius: 2,
                    width: [25, 25, 25, 25],
                    height: [15, 15, 15, 15]
                },
                offsetX: 0,
                offsetY: -20,
                itemMargin: {
                    horizontal: 35,
                    vertical: 0
                },
            },
            markers: {
                size: 5,
                colors: '#fff',
                strokeColors: '#9E9E9E',
                strokeWidth: 1,
            },
            responsive: [
                {
                    breakpoint: 840,
                    options: {
                        legend: {
                            itemMargin: {
                                horizontal: 10,
                                vertical: 0
                            },
                        },
                    }
                },
                {
                    breakpoint: 645,
                    options: {
                        title: {
                            offsetY: 49,
                        },
                        legend: {
                            horizontalAlign: 'left',
                            itemMargin: {
                                horizontal: 5,
                                vertical: 0
                            },
                        },
                    }
                }
            ]
        },
    };


    // const data = {

    //     series: [
    //         {
    //             name: 'Secured income',
    //             data: [500, 600, 1200, 400, 550, 750, 330, 530, 500, 1200, 700, 400, 550, 750, 330, 500, 600, 700, 400, 550, 750, 1330, 530, 500, 600, 700, 400, 1450, 750, 330,],
    //             type: 'column',
    //         }, {
    //             name: 'Potential rent',
    //             data: [400, 550, 750, 330, 530, 500, 600, 700, 500, 550, 700, 400, 550, 750, 1330, 500, 600, 750, 330, 530, 500, 600, 700, 1400, 550, 1250, 330, 700, 400, 550,],
    //             type: 'column',
    //         }, {
    //             name: 'Market rent',
    //             data: [500, 600, 500, 400, 550, 750, 330, 530, 500, 900, 700, 400, 550, 750, 330, 500, 1400, 700, 400, 550, 1250, 330, 530, 500, 600, 1100, 400, 550, 750, 330,],
    //             type: 'column',
    //         },
    //         {
    //             name: 'Occupancy',
    //             data: [10, 30, 60, 60, 70, 50, 40, 40, 60, 90],
    //             type: 'line',
    //         }
    //     ],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'line',
    //         },
    //         stroke: {
    //             width: [0, 4]
    //         },
    //         title: {
    //             text: 'Traffic Sources'
    //         },
    //         dataLabels: {
    //             enabled: false,
    //             enabledOnSeries: [1]
    //         },
    //         labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    //         xaxis: {
    //             categories: ['Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'Y6', 'Y7', 'Y8', 'Y9', 'Y10', 'Y11', 'Y1', 'Y12', 'Y13', 'Y14', 'Y15', 'Y16', 'Y17', 'Y18', 'Y19', 'Y20', 'Y21', 'Y22', 'Y23', 'Y24', 'Y25', 'Y26', 'Y27', 'Y28', 'Y29',],
    //         },
    //         yaxis: [{
    //             title: {
    //                 text: 'Website Blog',
    //             },

    //         }, {
    //             opposite: true,
    //             title: {
    //                 text: 'Social Media'
    //             }
    //         }]
    //     },


    // };

    return (
        <Card title={<h2 className="mb-0 text-black">Lease Analysis</h2>}>
            <hr className="my-2 mx-3" />
            <div id="chart" className="px-3 pb-3">
                {/* <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} /> */}
                <ReactApexChart options={data.options} series={data.series} type="line" height={350} />
                <Row gutter={24} className='d-flex justify-content-between px-3'>
                    <Col xs={24} md={7} className='bg-light-blue p-2 px-3 rounded-sm d-flex justify-content-between align-items-center mt-2'>
                        <h5 className="m-0">Current rent</h5><p className="m-0">22.5 EUR/sqm</p><p className="m-0">837,402 EUR</p>
                    </Col>
                    <Col xs={24} md={7} className='bg-light-blue p-2 px-3 rounded-sm d-flex justify-content-between align-items-center mt-2'>
                        <h5 className="m-0">Potential Rent</h5><p className="m-0">22.5 EUR/sqm</p><p className="m-0">837,402 EUR</p>
                    </Col>
                    <Col xs={24} md={7} className='bg-light-blue p-2 px-3 rounded-sm d-flex justify-content-between align-items-center mt-2'>
                        <h5 className="m-0">Market Rent</h5><p className="m-0">22.5 EUR/sqm</p><p className="m-0">837,402 EUR</p>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}
