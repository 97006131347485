/* eslint-disable */
import api from "../../../utils/api";

const updateVisibleColumns = async (data) => {
  const response = await api.post(`/api/common/updateVisibleColumns`, data);
  return response.data;
};

const getAllCountry = async (country) => {
  const response = await api.get(`/api/common/getAllCountries`, country);
  return response.data;
};

const getAllState = async (data) => {
  const response = await api.get("/api/common/getAllStates", {
    params: data,
  });
  return response.data.data;
};

const getAllCities = async (data) => {
  const response = await api.get("/api/common/getAllCities", {
    params: data,
  });
  return response.data.data;
};

const getByTypeDropdown = async (data) => {
  const response = await api.get("/api/common/lookup/getByType", {
    params: data,
  });
  return response.data.data;
};

// const uploadBase64 = async (data) => {
//   const response = await api.post(`/api/common/uploadBase64`, data);
//   return response.data;
// };
const uploadBase64 = async (file) => {
  const response = await api.post(`/api/common/uploadBase64`, file);
  // const response = await API.post(`/api/azure/post`, file);

  return response.data;
};

const lookUps = {
  getAllCountry,
  getAllState,
  getAllCities,
  getByTypeDropdown,
  updateVisibleColumns,
  uploadBase64,
};

export default lookUps;
