import { Card, Table } from "antd";

export default function SensitiveAnalysisCard() {

    const data = [
        {
            basicPoints: 'Capital Values',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
        {
            basicPoints: 'NIY (potential)',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
        {
            basicPoints: 'Multiple (potential)',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
        {
            basicPoints: 'Cash on Cash',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
        {
            basicPoints: 'Leveraged IRR',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
        {
            basicPoints: 'Equity Multiple',
            minus10BP: '3.00%',
            plus5BP: '4.00%',
            zeroBP: '5.00%',
            minus5BP: '6.00%',
        },
    ];

    const columns = [
        {
            title: 'Basic Points',
            dataIndex: 'basicPoints',
            align: 'left',
            width: "40%"
        },
        {
            title: '+10BP',
            dataIndex: 'minus10BP',
            align: 'center'
        },
        {
            title: '+5BP',
            dataIndex: 'plus5BP',
            align: 'center'
        },
        {
            title: '0BP',
            dataIndex: 'zeroBP',
            align: 'center',
            render: (text) => ({
                props: {
                    style: { background: "#F2F4F7" }
                },
                children: <div>{text}</div>
            })
        },
        {
            title: '-5BP',
            dataIndex: 'minus5BP',
            align: 'center'
        },
        {
            title: '-10BP',
            dataIndex: 'minus10BP',
            align: 'center'
        },
    ];

    return (
        <Card title={<div className="d-flex align-items-center"><h2 className="mb-0 mr-2 text-black">Sensivity Analysis</h2> <h4 className="mb-0 text-black-50">(Discount & Exit Cap Adjustments)</h4></div> } className="sensivity-analysis-wrapper ">
            <hr className="my-2 mx-3" />
            <div className="px-3 pb-3">
                <Table
                    className="sensivity-analysis-table mt-2 custom-scroll"
                    size="small"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            </div>
        </Card>
    )
}
