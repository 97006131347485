import { Card } from "antd";
import ReactApexChart from "react-apexcharts";

export default function CashFlowChart() {

    const chartData = {
        series: [{
            name: 'Non-Recoverable Cost',
            data: [200, 800, 400, 600, 600, 1200, 900, 1200, 1200, 1800],
            type: 'area',
        }, {
            name: 'Leasing & Capital Costs',
            data: [150, 600, 300, 450, 450, 800, 675, 800, 800, 1500],
            type: 'area',
        }, {
            name: 'Market Rent',
            data: [140, 550, 250, 400, 400, 700, 600, 700, 700, 1400],
            type: 'area',
        }, {
            name: 'Cash flow',
            data: [120, 400, 200, 350, 350, 600, 550, 600, 600, 1200],
            type: 'area',
        },
        {
            name: 'Gross Rental Income',
            data: [400, 900, 600, 800, 800, 1300, 1000, 1400, 1400, 2000],
            type: 'line',
        }
        ],
        options: {
            chart: {
                height: 357,
                type: 'line',
                toolbar: {
                    show: false,
                }
            },
            markers: {
                size: [0, 0, 0, 0, 5],
            },
            colors: ['#FFB458', '#FF5454', '#394686', '#00215B', '#595A5C'],
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'solid',
                opacity: [1, 1, 1, 1, 1],
            },
            stroke: {
                curve: 'straight',
                width: [2, 2, 2, 2, 2],
            },
            legend: {
                horizontalAlign: 'left',
                show: true,
                position: 'top',
                markers: {
                    radius: 1,
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 3
                },
            },
            xaxis: {
                categories: ['Y1', 'Y2', 'Y3', 'Y4', 'Y5', 'Y6', 'Y7', 'Y8', 'Y9', 'Y10',]
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },
    };

    return (
        <Card title={<h2 className="mb-0 text-black">Cash Flow Chart</h2>} className="area-analysis-wrapper">
            <hr className="my-2 mx-3" />
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={357} />
            </div>
        </Card>
    )
}
