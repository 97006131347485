/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Card, Form, Input, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlinePlus } from "react-icons/ai";
import { ImBin2 } from "react-icons/im";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function App() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [groundRentDataSource, setGroundRentDataSource] = useState([
    {
      key: 1,
      no: "1",
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "50%",
    },
    {
      key: 2,
      no: "2",
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "40%",
    },
    {
      key: 3,
      no: "3",
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "10%",
    },
    {
      key: 4,
      no: "4",
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "40%",
    },
    {
      key: 5,
      no: "5",
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "10%",
    },
  ]);
  const [count, setCount] = useState(groundRentDataSource.length + 1);
  const handleDelete = () => {
    selectedRowKeys.forEach((key) => {
      setGroundRentDataSource(current => current.filter((item) => item.key !== key))
    })
  };

  const groundRentColumns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
    },

    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      editable: true,
    },
    {
      title: "EUR/month",
      dataIndex: "eur",
      key: "eur",
      editable: true,
    },
    {
      title: "Start",
      dataIndex: "startMMY",
      key: "startMMY",
      editable: true,
    },
    {
      title: "End",
      dataIndex: "endMMY",
      key: "endMMY",
      editable: true,
    },
    {
      title: "Index Cycle",
      dataIndex: "indexCycle",
      key: "indexCycle",
      editable: true,
    },
    {
      title: "Factor",
      dataIndex: "factor",
      key: "factor",
      editable: true,
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: count,
      no: `${count}`,
      desc: "At praesent ac...",
      eur: "Netus in.",
      startMMY: "08/2022",
      endMMY: "12/2022",
      indexCycle: "Leo velit.",
      factor: "40%",
    };
    setGroundRentDataSource([...groundRentDataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...groundRentDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setGroundRentDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = groundRentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="incomeAndCost site-card-border-less-wrapper">
      <Card bordered={false} className="pt-4">
        <div className="d-flex justify-content-between">
          <div>
            <h3>Other Cash Flow(s)</h3>
          </div>
          <div>
            <ImBin2 className="delete-row-btn cursor-pointer" size={30} onClick={handleDelete} />
            <AiOutlinePlus className="add-row-btn p-1 cursor-pointer mx-1" onClick={handleAdd} size={30} />
          </div>
        </div>
        <div>
          <hr className="mx-2" />
          <Table
            components={components}
            // bordered
            rowClassName={() => "editable-row"}
            columns={columns}
            dataSource={groundRentDataSource}
            pagination={false}
            className="antd-custom-card-table antd-table-striped"
            rowSelection={rowSelection}
          />
        </div>
      </Card>
    </div>


  );
};


EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};



