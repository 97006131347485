// import { CaretDownOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import {
  getAssetFinancing,
  saveAssetFinancing,
} from "../../../../../../../../app/features/calculation/calculationAsset.slice";
import { getByTypeDropdown } from "../../../../../../../../app/features/Lookups/lookup.slice";

function VeFinancing() {
  const dispatch = useDispatch();
  const param = useParams();
  const { financingRate } = useSelector((state) => state.calculation);
  // console.log(financingRate, "financingRate");
  const [financingRateObj, setFinancingRateObj] = useState(null);
  const { financingType } = useSelector((state) => state.lookUps);
  useEffect(() => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetFinancing(finalData));
  }, []);

  const updateNum = (e, key) => {
    setFinancingRateObj(() => ({
      ...financingRateObj,
      [key]: e,
    }));
  };

  useEffect(() => {
    if (financingRate == null) {
      setFinancingRateObj({});
    } else {
      setFinancingRateObj(...financingRate);
    }
  }, [financingRate]);

  useEffect(() => {
    const finalData = {
      type: "financingType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetFinancing(finalData));
  };

  const handleFinancingValue = () => {
    const finalData =
      financingRate !== null
        ? financingRateObj
        : {
            financeId: 0,
            assetId: param.assetDetailId,
            financingTypeId: financingRateObj.financingTypeId
              ? financingRateObj.financingTypeId
              : 0,
            ltv: financingRateObj.ltv ? financingRateObj.ltv : 0,
            loan: financingRateObj.loan ? financingRateObj.loan : 0,
            equity: financingRateObj.equity ? financingRateObj.equity : 0,
            interestRate: financingRateObj.interestRate
              ? financingRateObj.interestRate
              : 0,
            swapRate: financingRateObj.swapRate ? financingRateObj.swapRate : 0,
            amortization: financingRateObj.amortization
              ? financingRateObj.amortization
              : 0,
            finalAnnuityValue: financingRateObj.finalAnnuityValue
              ? financingRateObj.finalAnnuityValue
              : 0,
            prePaymentFee: financingRateObj.prePaymentFee
              ? financingRateObj.prePaymentFee
              : 0,
            allInterestRate_Monthly: financingRateObj.allInterestRate_Monthly
              ? financingRateObj.allInterestRate_Monthly
              : 0,
            amount: financingRateObj.amount ? financingRateObj.amount : 0,
            ltc: financingRateObj.ltc ? financingRateObj.ltc : 0,
            creditPeriod: financingRateObj.creditPeriod
              ? financingRateObj.creditPeriod
              : 0,
            gracePeriod: financingRateObj.gracePeriod
              ? financingRateObj.gracePeriod
              : 0,
            setupFee: financingRateObj.setupFee ? financingRateObj.setupFee : 0,
            createdDate: new Date().toISOString(),
          };
    dispatch(saveAssetFinancing({ finalData, moveToNext, notifyToaster }));
  };

  const secondColumns = [
    {
      title: " ",
      dataIndex: "assetID",
    },
    {
      title: " ",
      dataIndex: "iD",
    },
    {
      title: "",
      dataIndex: "cluster",
    },
    {
      title: " ",
      dataIndex: "analysisData",
    },
  ];
  const secondDataSource = [
    {
      key: "1",
      assetID: "Unleveraged IRR",
      iD: "5.50 %",
      cluster: "WACC",
      analysisData: "5.50 %",
    },
    {
      key: "2",
      assetID: "leveraged IRR",
      iD: "12.50 %",
      cluster: "Equity Multiple",
      analysisData: "3.50 %",
    },
    {
      key: "3",
      assetID: "Cash on Cash (year 1)",
      iD: "3.50 %",
      cluster: "Cash profit (Unleveraged)",
      analysisData: "3.50 %",
    },
    {
      key: "4",
      assetID: "Cash on Cash (average)",
      iD: "5.50 %",
      cluster: "Cash Profit (leverage)",
      analysisData: "5.50 %",
    },
  ];

  return (
    <div className="financingComponent">
      <Card bordered={false}>
        <div>
          <h3 className="text-black">Financing</h3>
          <hr className="border-top-0 border-2" />
        </div>
        <Row gutter={24} className="pt-1">
          <Col xs={24} xl={12}>
            <h4 className="text-black">Type</h4>
            <Select
              placeholder="Financing Type"
              allowClear
              value={financingRateObj?.financingTypeId}
              className="w-100"
              onChange={(e) => updateNum(e, "financingTypeId")}
            >
              {financingType?.map((item) => (
                <Select.Option key={item.lookUpId} value={item.lookUpId}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} xl={12}>
            <h4 className="text-black">Amount</h4>
            <Input
              value={financingRateObj?.amount}
              onChange={(e) => updateNum(parseFloat(e.target.value), "amount")}
              style={{
                border: "1px solid #B7B7B7",
                padding: "12px",
              }}
              type="number"
              placeholder="Amount"
            />
          </Col>
        </Row>
        <Row gutter={18} className="mt-3">
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">LTV %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.ltv || 0}
                setNumValue={(e) => updateNum(e, "ltv")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">LTC %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.ltc || 0}
                setNumValue={(e) => updateNum(e, "ltc")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Interest Rate %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.interestRate || 0}
                setNumValue={(e) => updateNum(e, "interestRate")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Credit Period (y)</h5>

            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.creditPeriod || 0}
                setNumValue={(e) => updateNum(e, "creditPeriod")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Amortization %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.amortization || 0}
                setNumValue={(e) => updateNum(e, "amortization")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Grace Period (m)</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.gracePeriod || 0}
                setNumValue={(e) => updateNum(e, "gracePeriod")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Set-up Fee %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.setupFee || 0}
                setNumValue={(e) => updateNum(e, "setupFee")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Prepayment Fee %</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.prePaymentFee || 0}
                setNumValue={(e) => updateNum(e, "prePaymentFee")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Equity</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.equity || 0}
                setNumValue={(e) => updateNum(e, "equity")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">All-In Interest Rate (monthly)</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.allInterestRate_Monthly || 0}
                setNumValue={(e) => updateNum(e, "allInterestRate_Monthly")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">SWAP Rate</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.swapRate || 0}
                setNumValue={(e) => updateNum(e, "swapRate")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Target Value (Annuity Loan)</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.finalAnnuityValue || 0}
                setNumValue={(e) => updateNum(e, "finalAnnuityValue")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Loan</h5>
            {financingRateObj && (
              <IncDecCounter
                count={financingRateObj?.loan || 0}
                setNumValue={(e) => updateNum(e, "loan")}
                className="m-0"
              />
            )}
          </Col>
        </Row>
        <div className="py-2 mb-3 mt-3 d-flex ml-auto justify-content-end">
          <Button
            onClick={handleFinancingValue}
            className="btn-primary float-right"
          >
            Submit
          </Button>
        </div>
        <Table
          showHeader={false}
          className="second-table-mini"
          columns={secondColumns}
          dataSource={secondDataSource}
          bordered={false}
          pagination={false}
          rowClassName="ant-table-row-light"
        />
      </Card>
    </div>
  );
}

export default VeFinancing;
