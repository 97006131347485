import { Card, Cascader, Checkbox, Col, Input, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VeHoldingPeriodAndExitCalculation() {
  const options = [
    {
      value: "demo",
      label: "Potential",
    },
    {
      value: "demo2",
      label: "Jiangsu",
    },
  ];

  return (
    <div className="holdingPeriod">
      <Card bordered={false}>
        <div>
        <h3 className="mb-0 text-black">
                Holding Period & Exit Calculation
              </h3>
          <hr className="border-top-0 border-2 mt-0" />
        </div>
        <Row gutter={24}>
          <Col xs={8}>
            <h4 className="text-black">Year</h4>
            <IncDecCounter />
          </Col>
          <Col xs={8}>
            <h4 className="text-black">Base Rent</h4>
            <Cascader options={options} placeholder="Potential Rent" />
          </Col>
          <Col xs={8}>
            <h4 className="text-black">Non Rec. Costs</h4>
            <div className="d-flex align-items-center">
              <div>
                <Input
                  style={{
                    width: 50,
                    border: "1px solid #B7B7B7",
                    padding: "12px",
                  }}
                />
              </div>
              <div className="d-flex align-items-center ml-2">
                <Checkbox>INDIVIDUAL</Checkbox>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VeHoldingPeriodAndExitCalculation;
