/* eslint-disable react/jsx-filename-extension */

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./assets/css/app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { AuthProvider } from "react-oidc-context";
import environments from "./utils/environments";

const root = ReactDOM.createRoot(document.getElementById("root"));

const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;
const full = `${protocol}//${domain}${port? ":"+port : ""}`

const oidcConfig = {
    authority: environments.authUrl+"/realms/"+environments.authRealm,
    client_id: environments.authClient,
    redirect_uri: full,
    onSigninCallback: (_user) => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }
}

root.render(
    <AuthProvider {...oidcConfig}>
        <React.StrictMode>
        <Provider store={store}>
          <App />
          <ToastContainer style={{ zIndex: 10000000000000 }} />
        </Provider>
        </React.StrictMode>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
