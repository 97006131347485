/* eslint-disable*/
import {
  Button,
  Checkbox,
  // Col,
  // Divider,
  Menu,
  // Row,
  Form,
  Input,
  // InputNumber,
  Select,
  notification,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { MdDelete, MdModeEdit } from "react-icons/md";
import PropTypes from "prop-types";
// import UserList from "../CreateUpdateAssets/UserList";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
// import MergeToPortfolio from "../CreateUpdateAssets/MergeToPortfolio";
import PATH from "../../../../../../utils/path";
// import DeleteUserModal from "../../../../../../components/Layout/DeleteModel/DeleteUserModal";
// import CreateUpdateAssets from "../../CreateUpdateAssets";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  // deleteAssetDetail,
  getRatingList,
  updatePropertyRating,
} from "../../../../../../app/features/projectDetails/projectDetail.slice";
import {
  getByTypeDropdown,
  updateVisibleColumns,
} from "../../../../../../app/features/Lookups/lookup.slice";

export default function PropertyRating(props) {
  const { activeKey, sethideTableColumns, searchAsset, closeModal, user } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const assignedUserId =  param.userId;
  const [editingKey, setEditingKey] = useState("");
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  // const [addMergeUserModal, setAddMergeUserModal] = useState(false);
  // const [deleteUserModal, setDeleteUserModal] = useState(false);
  // const [createUpdateAsset, setCreateUpdateNewAsset] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const { getRating, isLoading, projectDetailFilters } = useSelector(
    (state) => state.projectDetails
  );
  const { ratingType } = useSelector((state) => state.lookUps);
  const [projectDetailFilter, setProjectDetailFilter] = useState({
    Search: "",
    AssetId: "",
    CountryId: "",
    StateId: "",
    CityId: "",
    Street: "",
    RiskClassId: "",
    UpsidePotentialId: "",
    CapexRequirementId: "",
    ExpectedLiquidityId: "",
    ManagementRequirementId: "",
    OwnershipId: "",
    InspectionTypeId: "",
    Selection: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size -
    projectDetailFilter.pagination.size +
    1;

  const nextPageNumber =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size >
    getRating?.totalRecords
      ? getRating?.totalRecords
      : projectDetailFilter.pagination.pageNo *
        projectDetailFilter.pagination.size;

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };
  // const closeAddUpdateUserModal = () => {
  //   setAddUpdateUserModal(false);
  // };
  // const closeAddMergeUserModal = () => {
  //   setAddMergeUserModal(false);
  // };
  // const closeDeleteUserModal = () => {
  //   setDeleteUserModal(false);
  // };

  // const CreateUpdateAssetcloseModal = () => {
  //   setCreateUpdateNewAsset(false);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };
  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "dropdown" ? (
        <Select placeholder="Select" allowClear>
          {ratingType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
          {/* <Option value="1">Poor</Option>
      <Option value="2">Below Average</Option>
      <Option value="3">Average</Option>
      <Option value="4">Good</Option>
      <Option value="5">Very Good</Option> */}
        </Select>
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: false,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  const isEditing = (record) => record.assetId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.assetId);
  };

  useEffect(() => {
    const finalData = {
      type: "ratingType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const cancel = () => {
    setEditingKey("");
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");

    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    dispatch(getRatingList(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getRatingList(finalData));
  };
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const finalData = {
        assetId: record.assetId,
        ...row,
      };
      dispatch(updatePropertyRating({ finalData, moveToNext, notifyToaster }));

      // const newData = [...dataSource];
      // const index = newData.findIndex((item) => key === item.key);
      // if (index > -1) {
      //   const item = newData[index];
      //   newData.splice(index, 1, {
      //     ...item,
      //     ...row,
      //   });
      //   setDataSource(newData);
      //   setEditingKey("");
      // } else {
      //   newData.push(row);
      //   setDataSource(newData);
      //   setEditingKey("");
      // }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  // const moveToNextDelete = () => {
  //   closeDeleteUserModal();
  //   const filters = {
  //     Search: "",
  //     AssetId: "",
  //     CountryId: "",
  //     StateId: "",
  //     CityId: "",
  //     Street: "",
  //     RiskClassId: "",
  //     UpsidePotentialId: "",
  //     CapexRequirementId: "",
  //     ExpectedLiquidityId: "",
  //     ManagementRequirementId: "",
  //     OwnershipId: "",
  //     InspectionTypeId: "",
  //     Selection: "",
  //     pagination: {
  //       pageNo: 1,
  //       size: 10,
  //     },
  //   };
  //   setProjectDetailFilter(filters);
  //   setSelectedRowKeys([]);
  //   setEditingKey("");
  //   const finalData = {
  //     projectId: +ProjectId,
  //     pageNo: projectDetailFilter?.pagination?.pageNo,
  //     size: projectDetailFilter?.pagination.size,
  //   };
  //   dispatch(getRatingList(finalData));
  // };
  // const deleteProjectDetail = () => {
  //   // let finalData = [];
  //   // finalData = selectedRowKeys.map((item) => item.assetId);
  //   const finalData = selectedRowKeys;
  //   dispatch(deleteAssetDetail({ finalData, moveToNextDelete, notifyToaster }));
  // };

  const initialColState = [
    {
      id: 0,
      title: "Asset ID",
      dataIndex: "assetId",
      hidden: false,
      width: "15%",
    },
    {
      id: 1,
      title: "Location (1 to 5)",
      dataIndex: "locationRating",
      hidden: toggle.includes("locationRating"),
      editable: true,
      render: (text, row) =>
        (text &&
          ratingType?.find((item) => item?.lookUpId === row?.locationRating)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "locationRating",
        title: "Location (1 to 5)",
        editing: isEditing(record),
      }),
    },
    {
      id: 2,
      title: "Situation (1 to 5)",
      dataIndex: "situationRating",
      hidden: toggle.includes("situationRating"),
      editable: true,
      render: (text, row) =>
        (text &&
          ratingType?.find((item) => item?.lookUpId === row?.situationRating)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "situationRating",
        title: "Situation (1 to 5)",
        editing: isEditing(record),
      }),
      // hidden: false,
    },
    {
      id: 3,
      title: "Building (1 to 5)",
      dataIndex: "buildingRating",
      hidden: toggle.includes("buildingRating"),
      editable: true,
      render: (text, row) =>
        (text &&
          ratingType?.find((item) => item?.lookUpId === row?.buildingRating)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "buildingRating",
        title: "Building (1 to 5)",
        editing: isEditing(record),
      }),
    },
    {
      id: 3,
      title: "Tenancy (1 to 5)",
      dataIndex: "tenancyRating",
      hidden: toggle.includes("tenancyRating"),
      editable: true,
      render: (text, row) =>
        (text &&
          ratingType?.find((item) => item?.lookUpId === row?.tenancyRating)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "tenancyRating",
        title: "Tenancy (1 to 5)",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      width: "10%",
      hidden: false,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span
            className="d-flex align-items-center justify-content-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
                <ImCross size={15} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const changePath = (record) => {
    if (PATH.PROJECT_DETAILS.replace(":projectId", +ProjectId) === location.pathname) {
      // return navigate(PATH.ASSET_DETAILS.replace(":id", +ProjectId));
      return navigate(
        `/projects/${ProjectId}/project-detail/${record.assetId}/asset-detail`
      );
    } else {
      return navigate(`/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.assetId}/asset-detail`);
    }
  };
  const navigateRow = (record) => {
    // const path = record; // to avoid es list error
    return {
      onClick: () => {
        editingKey === "" && changePath(record); // to avoid es lint error
      }, // click row
    };
  };

  const propertyRatingDatahideTableColumns = [
    {
      key: 1,
      type: "group",
    },
    {
      key: 2,
      value: "locationRating",
      name: "Location (1 to 5)",
      isChecked: false,
    },
    {
      key: 3,
      value: "situationRating",
      name: "Situation (1 to 5)",
      isChecked: false,
    },
    {
      key: 4,
      value: "buildingRating",
      name: "Building (1 to 5)",
      isChecked: false,
    },
    {
      key: 5,
      value: "tenancyRating",
      name: "Tenancy (1 to 5)",
      isChecked: false,
    },

    {
      key: 6,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(
    propertyRatingDatahideTableColumns
  );

  useEffect(() => {
    const finalData = {
      projectId: +ProjectId,
      assignedToUserId: assignedUserId !== null ?  assignedUserId : null,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
      assetId: projectDetailFilters?.assetId
        ? projectDetailFilters.assetId
        : searchAsset?.assetId || "",
      countryId: projectDetailFilters?.countryId
        ? projectDetailFilters.countryId
        : searchAsset?.countryId || "",
      stateId: projectDetailFilters?.stateId
        ? projectDetailFilters.stateId
        : searchAsset?.stateId || "",
      cityId: projectDetailFilters?.cityId
        ? projectDetailFilters.cityId
        : searchAsset?.cityId || "",
      street: projectDetailFilters?.street
        ? projectDetailFilters.street
        : searchAsset?.street || "",
      riskClassId: projectDetailFilters?.riskClassId
        ? projectDetailFilters.riskClassId
        : searchAsset?.riskClassId || "",
      upsidePotentialId: projectDetailFilters?.upsidePotentialId
        ? projectDetailFilters.upsidePotentialId
        : searchAsset?.upsidePotentialId || "",
      capexRequirementId: projectDetailFilters?.capexRequirementId
        ? projectDetailFilters.capexRequirementId
        : searchAsset?.capexRequirementId || "",
      expectedLiquidityId: projectDetailFilters?.expectedLiquidityId
        ? projectDetailFilters.expectedLiquidityId
        : searchAsset?.expectedLiquidityId || "",
      managementRequirementId: projectDetailFilters?.managementRequirementId
        ? projectDetailFilters.managementRequirementId
        : searchAsset?.managementRequirementId || "",
      ownershipId: projectDetailFilters?.ownershipId
        ? projectDetailFilters.ownershipId
        : searchAsset?.ownershipId || "",
      inspectionTypeId: projectDetailFilters?.inspectionTypeId
        ? projectDetailFilters.inspectionTypeId
        : searchAsset?.inspectionTypeId || "",
      selection: projectDetailFilters?.selection
        ? projectDetailFilters.selection
        : searchAsset?.selection || "",
    };
    dispatch(getRatingList(finalData));
  }, [dispatch, projectDetailFilter, searchAsset]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    getRating?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [getRating]);

  useEffect(() => {
    if (getRating) {
      const a = getRating.visibleColumns?.map((item) => item.moduleId);
      setModuleIdValue(a?.pop());

      const b = getRating.visibleColumns?.map((item) => item.moduleTabId);
      setModuleTabIdValue(b?.pop());
    }
  }, [getRating]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 6) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 6) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  return (
    <div>
      <div className="basic-data">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            columns={initialColState.filter((item) => item.hidden === false)}
            dataSource={getRating?.rating ? getRating?.rating : ""}
            pagination={{
              current: projectDetailFilter?.pagination?.pageNo || 1,
              pageSize: 10,
              total: getRating?.totalRecords,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                setProjectDetailFilter({
                  ...projectDetailFilter,
                  pagination: {
                    pageNo: page,
                    size: pageSize,
                  },
                });
              },
            }}
            className="antd-table-striped"
            // rowSelection={rowSelection}
            loading={isLoading}
            rowKey={(record) => record.assetId}
            onRow={navigateRow}
            scroll={{
              x: "800px",
              y: "calc(100vh - 405px)",
            }}
          />
        </Form>
      </div>
      {getRating?.rating?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {getRating?.totalRecords} entries
            </h5>
          </div>
          {/* <Row gutter={24}>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />{" "}
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button> */}

                  {/* {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setCreateUpdateNewAsset(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdModeEdit className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                    </div>
                  </Button>
                </>
              )} */}
                  {/* <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddMergeUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <BsFileEarmarkExcelFill className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Merge to Portfolio</h5>
                    </div>
                  </Button>
                  {user?.roleId === 3 || user?.roleId === 4 || location.pathname.startsWith("user-management") ? ("") : (
                    <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddUpdateUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <BsFileEarmarkExcelFill className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Assign Task</h5>
                    </div>
                  </Button>
                  </>
                  )}
                </div> */}

                {/* <Button className="btn-cancel">
              <h5 className="mb-0">Cancel</h5>
            </Button> */}
              {/* </div>
            </Col>
          </Row> */}
        </>
      )}
      {/* {addUpdateUserModal && (
        <UserList
          visible={addUpdateUserModal}
          handleCancel={closeAddUpdateUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {addMergeUserModal && (
        <MergeToPortfolio
          visible={addMergeUserModal}
          handleCancel={closeAddMergeUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      /> */}
      {/* <CreateUpdateAssets
        visible={createUpdateAsset}
        handleCancel={CreateUpdateAssetcloseModal}
      /> */}
    </div>
  );
}
PropertyRating.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
  searchAsset: PropTypes.node.isRequired,
  closeModal: PropTypes.node.isRequired,
};
// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };
