import { Card, Col, Row } from "antd";
import ReactApexChart from "react-apexcharts";

export default function FinancialKeyFigures() {

    const chartData = {

        series: [60],
        options: {
            chart: {
                type: 'radialBar',
            },
            colors: ['#00215B', '#F2F4F7'],
            legend: {
                show: false,
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        show: true,
                        name: {
                            show: false,
                        },
                        value: {
                            show: true,
                            fontSize: '18px',
                            fontWeight: 400,
                            color: '#00215B',
                            offsetY: 5,
                            formatter: (val) => `${val}%`
                        }
                    },
                    hollow: {
                        size: '40%',
                    }
                },
            },
            dataLabels: {
                enabled: false,
                textAnchor: 'middle',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                }
            }]
        },
    };

    return (
        <Card title={<h2 className="mb-0 text-black">Financial key Figures</h2>} className="financial-wrapper">
            <hr className="my-2 mx-3" />
            <div className="p-3">
                <Row className="p-2" gutter={16}>
                    <Col xs={24} sm={10} className="d-flex">
                        <h3 className="m-0">LTV</h3>
                        <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={175} />
                    </Col>
                    <Col xs={24} sm={14} >
                        <div className="d-flex justify-content-between"><h4>Loan Amount</h4> <h4>450,000,000 € </h4></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Loan Type</p> <p className="m-0">Bullet Loan</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">intrest  rate</p> <p className="m-0">1.50%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Amortization</p> <p className="m-0">1.00%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">WACC</p> <p className="m-0">3.65%</p></div>
                    </Col>
                </Row>
                <Row className="p-2" gutter={16}>
                    <Col xs={24} sm={10} className="d-flex">
                        <h3>LTC</h3>
                        <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={175} />
                    </Col>
                    <Col xs={24} sm={14}>
                        <div className="d-flex justify-content-between"><p className="m-0">Cash on cash (year1)</p> <p className="m-0">3.50%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Cash on cash (Average)</p> <p className="m-0">1.50%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">IRR</p> <p className="m-0">1.00%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Leveraged IRR</p> <p className="m-0">3.65%</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Equity Multiple</p> <p className="m-0">2.15 X</p></div>
                        <div className="d-flex justify-content-between"><p className="m-0">Cash Profit</p> <p className="m-0">4,500 M</p></div>
                    </Col>
                </Row>
            </div>
        </Card>
    )
}
