import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../../../../../app/features/Lookups/lookup.slice";

function Indexation(props) {
  const { Option } = Select;
  const { next, prev, handleCancel } = props;
  const [constructionDate, setConstructionDate] = useState("");
  const dispatch = useDispatch();
  const { IndexationType, IndexType } = useSelector((state) => state.lookUps);

  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("indexation", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("indexation");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    dispatch(
      getByTypeDropdown({
        type: "IndexationType",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getByTypeDropdown({
        type: "IndexType",
      })
    );
  }, []);

  const onChange = (date, dateString) => {
    setConstructionDate(dateString);
  };
  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Indexation</h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Type"
              name="type"
              initialValue={FormData?.type}
              rules={[
                {
                  required: true,
                  message: "Please input type",
                },
              ]}
            >
              <Select placeholder="Selection" allowClear>
                {IndexationType?.map((item) => (
                  <Option key={item.lookUpId} value={item.lookUpId}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Index"
              name="index"
              initialValue={FormData?.index}
              rules={[
                {
                  required: true,
                  message: "Please input index",
                },
              ]}
            >
              <Select placeholder="Selection" allowClear>
                {IndexType?.map((item) => (
                  <Select.Option key={item.lookUpId} value={item.lookUpId}>
                    {item.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="First"
              name="firstDate"
              format="MM/DD/YYYY"
              initialValue={
                FormData?.firstDate ? moment(FormData?.firstDate) : ""
              }
            >
              <DatePicker
                placeholder="DD.MM.YYYY"
                className="w-100"
                onChange={onChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Last"
              name="lastDate"
              format="MM/DD/YYYY"
              initialValue={
                FormData?.lastDate ? moment(FormData?.lastDate) : ""
              }
            >
              <DatePicker
                placeholder="DD.MM.YYYY"
                className="w-100"
                disabled={!constructionDate}
                disabledDate={(current) => {
                  const disabledDate = `${constructionDate}-01-01`;
                  return (
                    current && current < moment(disabledDate, "YYYY-MM-DD")
                  );
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Next"
              name="nextDate"
              format="MM/DD/YYYY"
              initialValue={
                FormData?.nextDate ? moment(FormData?.nextDate) : ""
              }
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Cycle"
              name="cycle"
              initialValue={FormData?.cycle}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Months" className="w-100" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Hurdle"
              name="hurdle"
              initialValue={FormData?.hurdle}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="PTS. / %" className="w-100" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Factor"
              name="factor"
              initialValue={FormData?.factor}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="%" className="w-100" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Indexation;

Indexation.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
