import { Button, Card, Checkbox, Col, Row, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import {
  getAssetAcquisitionCostInitial,
  saveAssetAcquisitionCostInitial,
} from "../../../../../../../../app/features/calculation/calculationAsset.slice";

function VeAcquisitionCosts() {
  const dispatch = useDispatch();

  const param = useParams();

  const { getAssetAcquisitionCostData } = useSelector(
    (state) => state.calculation
  );

  const [acquisitionCostObj, setAcquisitionCostObj] = useState(null);

  useEffect(() => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetAcquisitionCostInitial(finalData));
  }, []);

  useEffect(() => {
    if (getAssetAcquisitionCostData == null) {
      setAcquisitionCostObj(null);
    } else {
      setAcquisitionCostObj(getAssetAcquisitionCostData);
    }
  }, [getAssetAcquisitionCostData]);

  const updateNum = (e, key) => {
    setAcquisitionCostObj(() => ({
      ...acquisitionCostObj,
      [key]: e,
    }));
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetAcquisitionCostInitial(finalData));
  };

  const handleAcquisitionCostInitial = () => {
    const finalData = {
      assetId: param.assetDetailId,
      transferTax: acquisitionCostObj.transferTax
        ? acquisitionCostObj.transferTax
        : 0,
      agentCosts: acquisitionCostObj.agentCosts
        ? acquisitionCostObj.agentCosts
        : 0,
      notaryCosts: acquisitionCostObj.notaryCosts
        ? acquisitionCostObj.notaryCosts
        : 0,
      isAdjustAutomatically: acquisitionCostObj.isAdjustAutomatically,
    };
    dispatch(
      saveAssetAcquisitionCostInitial({ finalData, moveToNext, notifyToaster })
    );
  };

  return (
    <div>
      <Card bordered={false}>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3 className="text-black m-0">Acquisition Costs</h3>
          <Checkbox
            checked={acquisitionCostObj?.isAdjustAutomatically}
            onChange={(e) =>
              updateNum(e.target.checked, "isAdjustAutomatically")
            }
            style={{ color: "#6F7173" }}
          >
            ADJUST AUTOMATICALLY
          </Checkbox>
        </div>
        <hr className="mt-0 border-top-0 border-2" />
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Transfer Tax</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostObj && (
              <IncDecCounter
                count={acquisitionCostObj?.transferTax || 0}
                setNumValue={(e) => updateNum(e, "transferTax")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">1,404,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Agent Costs</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostObj && (
              <IncDecCounter
                count={acquisitionCostObj?.agentCosts || 0}
                setNumValue={(e) => updateNum(e, "agentCosts")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">234,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Notary Costs</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostObj && (
              <IncDecCounter
                count={acquisitionCostObj?.notaryCosts || 0}
                setNumValue={(e) => updateNum(e, "notaryCosts")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">117,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 mb-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black">7.50 %</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black">1,755,000 €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-blue">Gross Asset Value</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2  justify-content-center"
          >
            <h4 className="mb-0 text-blue">5,600 €/sqm</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">25,155,000 €</h4>
          </Col>
        </Row>
        <div className="py-2 mt-3 d-flex ml-auto justify-content-end">
          <Button
            onClick={handleAcquisitionCostInitial}
            className="btn-primary float-right"
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default VeAcquisitionCosts;
