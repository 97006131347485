/* eslint-disable*/
import { Button, Col, Form, Input, Row, Select } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../../../../../app/features/Lookups/lookup.slice";

function GeneralInfo(props) {
  const { next, handleCancel } = props;
  const dispatch = useDispatch();

  const { RentRollStatusType } = useSelector(
    (state) => state.lookUps
  );

  useEffect(() => {
    const finalData = {
      type: "RentRollStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("generalInfo", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("generalInfo");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        General Info
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          {/* <Col xs={8}>
            <Form.Item
              label="No"
              name="no"
              rules={[
                {
                  required: true,
                  message: 'Please input your number!',
                },
              ]}>
              <Input placeholder="1" />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={12}>
            <Form.Item
              label="PID"
              name="pID"
              rules={[
                {
                  required: false,
                  message: "Please input product Id!",
                },
              ]}
            >
              <Input disabled placeholder="PID" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Status Type"
              name="statusType"
              initialValue={FormData?.statusType}
              rules={[{ required: true, message: "Please input Status Type!" }]}
            >
              <Select placeholder="Select" allowClear>
              {RentRollStatusType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default GeneralInfo;

GeneralInfo.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
