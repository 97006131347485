import { useState } from "react";
import {
  Button,
  // Checkbox,
  Col,
  // Dropdown,
  // Menu,
  Row,
  Switch,
  Input,
} from "antd";
import { TbTableExport } from "react-icons/tb";
import { FiSearch } from "react-icons/fi";
// import IMAGES from "../../../../../assets/images";
import SearchForm from "./component/CashFlow/SearchForm";
// import DataTable from "../../../../../components/Theme/DataTable";
import CashflowDataTable from "./component/CashflowDataTable";

function CashFlows() {
  // Hide Table Column Dropdown
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTanents, setShowTanents] = useState(false);
  const [active, setActive] = useState('monthly');

  const { Search } = Input;
  // const hideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "2",
  //         label: <Checkbox>Column 1</Checkbox>,
  //       },
  //       {
  //         key: "3",
  //         label: <Checkbox>Column 2</Checkbox>,
  //       },
  //       {
  //         key: "4",
  //         label: <Checkbox>Column 3</Checkbox>,
  //       },
  //       {
  //         key: "5",
  //         label: <Checkbox>Column 4</Checkbox>,
  //       },
  //       {
  //         key: "6",
  //         label: <Checkbox>Column 5</Checkbox>,
  //       },
  //       {
  //         key: "7",
  //         label: <Checkbox>Column 6</Checkbox>,
  //       },
  //       {
  //         key: "8",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn">Reset</Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="cashFlowsComponent">
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col xs={5}>
          <div className="cashflows-first-btns">
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-monthly-btn"
                className={`cashflows-monthly-btn ${active === 'monthly' ? 'active' : ''}`}
                onClick={() => setActive('monthly')}
              >
                Monthly
              </Button>
            </span>
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-yearly-btn ml-1"
                className={`cashflows-monthly-btn ml-1 ${active === 'yearly' ? 'active' : ''}`}
                onClick={() => setActive('yearly')}
              >
                Yearly
              </Button>
            </span>
            <span>
              <Switch className="ml-3 my-auto" onChange={() => setShowTanents(!showTanents)} />
            </span>
            <span className="ml-2 my-auto show-tenants">Show Tenants</span>
          </div>
        </Col>
        <Col>
          <div className="cashFlows d-flex searchbar-project justify-content-end">
            <Col>
              <div className="d-flex searchbar-project">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                <Search
                  placeholder="Search a Cashflow"
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            </Col>
            <span className="filter-icon-span cashflows-filter-icon">
              <TbTableExport size={23} className="my-2 mx-2" />
              {/* <TbAdjustmentsHorizontal size={23} className="my-2" /> */}
            </span>

            {/* <Dropdown overlay={hideTableColumns} trigger={["click"]}>
              <span className="filter-icon-span cashflows-filter-icon">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <CashflowDataTable showTanents={showTanents} active={active} />
        </Col>
      </Row>
      <SearchForm visible={isModalOpen} handleCancel={closeModal} />
    </div>
  );
}

export default CashFlows;
