/* eslint-disable */
import { Modal, Col, Row, Input, Button, Select, Form } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllProjectManager } from "../../../../app/features/Projects/project.slice";
import { getByTypeDropdown } from "../../../../app/features/Lookups/lookup.slice";

function SearchForm(props) {
  const {
    visible,
    handleCancel,
    setIsModalOpen,
    form,
    setSearchProjectStatusIdValue,
    setSearchValue,
    setSearchProjectTypeIdValue,
    setSearchProjectManagerIdValue,
  } = props;
  const { Search } = Input;
  const dispatch = useDispatch();
  const { projectType, ProjectStatusType } = useSelector(
    (state) => state.lookUps
  );
  const { projectManagers } = useSelector((state) => state.projects);

  const onFinish = (data) => {
    setSearchValue(data.search);
    setSearchProjectStatusIdValue(data.projectStatusType);
    setSearchProjectTypeIdValue(data.projectType);
    setSearchProjectManagerIdValue(data.projectManager);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const finalData = {
      type: "projectType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "ProjectStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
    dispatch(getAllProjectManager());
  }, [dispatch]);

  return (
    <Modal
      className="search-modals project"
      open={visible}
      handleCancel={handleCancel}
      // width={600}
      style={{
        top: 80,
        // left: 115,
      }}
      footer={false}
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        className="add-new-org-class"
        form={form}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Search className="mr-2" placeholder="Search Project" />
            </Form.Item>
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
          </Col>
        </Row>
        <Row className="row d-flex search-form-filter-row" gutter={16}>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <Form.Item
              name="projectManager"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                placeholder="Project Manager"
                allowClear
                className="w-100"
              >
                {projectManagers?.map((item) => {
                  return (
                    <Select.Option key={item.userId} value={item.userId}>
                      {item.projectManager}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={8} className="mb-2">
            <Form.Item
              name="projectType"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select placeholder="Project type" allowClear className="w-100">
                {projectType?.map((item) => {
                  return (
                    <Select.Option key={item.lookUpId} value={item.lookUpId}>
                      {item.text}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={8} className="mb-2">
            <Form.Item
              name="projectStatusType"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select placeholder="Project Status" allowClear className="w-100">
                {ProjectStatusType?.map((item) => {
                  return (
                    <Select.Option key={item.lookUpId} value={item.lookUpId}>
                      {item.text}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            className="align-items-center d-flex justify-content-end mt-2"
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
              // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  onSearch: PropTypes.node.isRequired,
  setSearchProjectManagerIdValue: PropTypes.node.isRequired,
  setSearchProjectTypeIdValue: PropTypes.node.isRequired,
  setSearchValue: PropTypes.node.isRequired,
  setSearchProjectStatusIdValue: PropTypes.node.isRequired,
  setIsModalOpen: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
};
