// /* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import portfolio from "./portfolio.service";

const initialState = {
  allPortfolio: [],
  mergeAssets: null,
  assignAssets: null,
  createPortfolio: null,
  deletePortfolio: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createPortfolio = createAsyncThunk(
  "portfolio/createPortfolio",
  async ({ finalData, moveToNext2, notifyToaster }, thunkAPI) => {
    try {
      const response = await portfolio.createPortfolio(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext2) {
          moveToNext2();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPortfolio = createAsyncThunk(
  "portfolio/getAllPortfolio",
  async (finalData, thunkAPI) => {
    try {
      return await portfolio.getAllPortfolio(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const mergeAssetToPortfolio = createAsyncThunk(
  "portfolio/mergeAssetToPortfolio",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await portfolio.mergeAssetToPortfolio(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assignAssetToUser = createAsyncThunk(
  "portfolio/assignAssetToUser",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await portfolio.assignAssetToUser(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "portfolio/deletePortfolio",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response = await portfolio.deletePortfolio(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPortfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createPortfolio = action.payload;
      })
      .addCase(createPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allPortfolio = action.payload;
      })
      .addCase(getAllPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(mergeAssetToPortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mergeAssetToPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.mergeAssets = action.payload;
      })
      .addCase(mergeAssetToPortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(assignAssetToUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignAssetToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignAssets = action.payload;
      })
      .addCase(assignAssetToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deletePortfolio.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deletePortfolio = action.payload;
      })
      .addCase(deletePortfolio.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createPortfolioSlice.actions;

export default createPortfolioSlice.reducer;
