/* eslint-disable*/
import { Button, Checkbox, Col, Menu, notification, Row } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../../utils/path";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPortfolio,
  deletePortfolio,
} from "../../../../../../app/features/Portfolio/portfolio.slice";
import DeleteUserModal from "../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import { MdDelete } from "react-icons/md";
import { getBasicDataList } from "../../../../../../app/features/projectDetails/projectDetail.slice";

export default function PortfolioDetails({
  sethideTableColumns,
  showPortfolio,
}) {
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [projectDetailFilter, setProjectDetailFilter] = useState({
    Search: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  const { allPortfolio, isLoading } = useSelector((state) => state.portfolio);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
    setSelectedRowKeys([]);
  };
  const initialColState = [
    {
      id: 1,
      title: "PID",
      onCell: (data) => ({
        className: data.type === 1 ? "red-border" : "blue-border",
      }),
      dataIndex: "portfolioId",
    },
    {
      id: 2,
      title: "Portfolio Name",
      dataIndex: "name",
    },
    {
      id: 3,
      title: "Total Area",
      dataIndex: "totalArea",
    },
    {
      id: 4,
      title: "Total Income",
      dataIndex: "totalIncome",
    },
    {
      id: 5,
      title: "Total Value",
      dataIndex: "totalValue",
    },
    {
      id: 6,
      title: "WALT",
      dataIndex: "walt",
    },
    {
      id: 7,
      title: "Vacancy",
      dataIndex: "vacancy",
    },
    {
      id: 8,
      title: "NIY",
      dataIndex: "niyMultiple",
    },
    {
      id: 9,
      title: "Multiple",
      dataIndex: "multiple",
    },
  ];

  const navigate = useNavigate();
  const changePath = (record) => {
    if (
      PATH.PROJECT_DETAILS.replace(":projectId", +ProjectId) ===
      location.pathname
    ) {
      // return navigate(PATH.PORTFOLIO_DETAILS);
      return navigate(
        `/projects/${ProjectId}/project-detail/${record.portfolioId}/portfolio-detail`
      );
    } else {
      return navigate(
        `/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.portfolioId}/asset-detail`
      );
    }
  };
  const navigateRow = (record) => {
    return {
      onClick: () => {
        changePath(record); // to avoid es lint error
      }, // click row
    };
  };

  const [columns, setColumns] = useState(initialColState);

  const handleReset = () => {
    setColumns(initialColState);
    // setCheckList([]);
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setColumns((current) =>
        current.filter((val) => val.dataIndex !== e.target.value)
      );
    } else {
      setColumns((current) =>
        [
          ...current,
          { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
        ].sort((a, b) => a.id - b.id)
      );
    }
  };

  const basicDatahideTableColumns = (
    <Menu
      items={[
        {
          key: 1,
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        {
          key: 10,
          label: (
            <Checkbox
              value="portfolio"
              name="Portfolio Name"
              id={2}
              // checked={checkList.includes("valDate")}
              onChange={handleChange}
            >
              Portfolio Name
            </Checkbox>
          ),
        },
        {
          key: 2,
          label: (
            <Checkbox
              value="totalArea"
              name="Total Area"
              id={2}
              // checked={checkList.includes("valDate")}
              onChange={handleChange}
            >
              Total Area
            </Checkbox>
          ),
        },
        {
          key: 3,
          label: (
            <Checkbox
              value="totalIncome"
              name="Total Income"
              id={3}
              // checked={checkList.includes("projectNo")}
              onChange={handleChange}
            >
              Total Income
            </Checkbox>
          ),
        },
        {
          key: 4,
          label: (
            <Checkbox
              value="totalValue"
              name="Total Value"
              id={4}
              // checked={checkList.includes("projectName")}
              onChange={handleChange}
            >
              Total Value
            </Checkbox>
          ),
        },
        {
          key: 5,
          label: (
            <Checkbox
              value="walt"
              name="WALT"
              id={5}
              // checked={checkList.includes("nameOfClient1")}
              onChange={handleChange}
            >
              WALT
            </Checkbox>
          ),
        },
        {
          key: 6,
          label: (
            <Checkbox
              value="vacancy"
              name="Vacancy"
              id={6}
              // checked={checkList.includes("nameOfClient2")}
              onChange={handleChange}
            >
              Vacancy
            </Checkbox>
          ),
        },
        {
          key: 7,
          label: (
            <Checkbox
              value="niyMultiple"
              name="NIY"
              id={7}
              // checked={checkList.includes("instruction")}
              onChange={handleChange}
            >
              NIY
            </Checkbox>
          ),
        },
        {
          key: 9,
          label: (
            <Checkbox
              value="multiple"
              name="Multiple"
              id={7}
              // checked={checkList.includes("instruction")}
              onChange={handleChange}
            >
              multiple
            </Checkbox>
          ),
        },
        {
          key: 8,
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button
                className="reset-dropdown-btn"
                onClick={() => handleReset()}
              >
                Reset
              </Button>
            </div>
          ),
        },
      ]}
    />
  );
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setProjectDetailFilter(filters);
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
    };
    dispatch(getAllPortfolio(finalData));
    const finalDataAsset = {
      projectId: +ProjectId,
    };
    dispatch(getBasicDataList(finalDataAsset));
  };
  const deletePortfolioFunct = () => {
    const finalData = selectedRowKeys;
    dispatch(deletePortfolio({ finalData, moveToNextDelete, notifyToaster }));
  };

  useEffect(() => {
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
    };
    dispatch(getAllPortfolio(finalData));
  }, [dispatch, projectDetailFilter]);

  useEffect(() => {
    sethideTableColumns(basicDatahideTableColumns);
  }, [showPortfolio]);

  return (
    <div className="porfolio-detail">
      <div className="basic-data">
        <DataTable
          columns={columns}
          dataSource={allPortfolio}
          pagination={{
            current: projectDetailFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: allPortfolio?.[0]?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setProjectDetailFilter({
                ...projectDetailFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          onRow={navigateRow}
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.portfolioId}
          scroll={{
            x: "900px",
            y: "calc(100vh - 371px)",
          }}
        />
      </div>
      {allPortfolio?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing 1 to 9 of {allPortfolio?.[0]?.totalRecords} entries
            </h5>
          </div>

          <Row gutter={24}>
            <Col
              xs={24}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Portfolio"
        onSubmit={deletePortfolioFunct}
        isLoaded={isLoading}
      />
    </div>
  );
}

PortfolioDetails.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  showPortfolio: PropTypes.node.isRequired,
};
