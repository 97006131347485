/* eslint-disable */
import api from "../../../utils/api";

// Create an account for projects
const createRentRollPost = async (data) => {
  const response = await api.post(`/api/AssetRentRoll/save`, data);
  return response.data;
};

// Get All list of projects
const getAllRentRollListById = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get BasicDataByAssetId list of projects
const getBasicDataByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getBasicDataByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getLeaseTermByAssetId list of projects
const getLeaseTermByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getLeaseTermByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getDurationByAssetId list of projects
const getDurationByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getDurationByAssetId", {
    params: data,
  });
  return response.data.data;
};
// Get getMarketRentByAssetId list of projects
const getMarketRentByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getMarketRentByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getRenewalProbabiltyByAssetId list of projects
const getRenewalProbabiltyByAssetId = async (data) => {
  const response = await api.get(
    "/api/AssetRentRoll/getRenewalProbabiltyByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

// Get getPreSettingByAssetId list of projects
const getPreSettingByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getPreSettingByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getCurrentRentByAssetId list of projects
const getCurrentRentByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getCurrentRentByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getNonRecoverableCostsTerm list of projects
const getNonRecoverableCostsTerm = async (data) => {
  const response = await api.get(
    "/api/AssetRentRoll/getNonRecoverableCostsTermByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

// Get getNonRecoverableCostsRelatting list of projects
const getNonRecoverableCostsRelatting = async (data) => {
  const response = await api.get(
    "/api/AssetRentRoll/getNonRecoverableCostsRelattingByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

// Get getVoidPeriodByAssetId list of projects
const getVoidPeriodByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getVoidPeriodByAssetId", {
    params: data,
  });
  return response.data.data;
};

// Get getVacancyCostsByAssetId list of projects
const getVacancyCostsByAssetId = async (data) => {
  const response = await api.get(
    "/api/AssetRentRoll/getVacancyCostsByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

// Get getRelettingCostsByAssetId list of projects
const getRelettingCostsByAssetId = async (data) => {
  const response = await api.get(
    "/api/AssetRentRoll/getRelettingCostsByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

// Get getRelettingCostsByAssetId list of projects
const getIndexationByAssetId = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getIndexationByAssetId", {
    params: data,
  });
  return response.data.data;
};

const getAllTenants = async (data) => {
  const response = await api.get("/api/AssetRentRoll/getAllTenants", {
    params: data,
  });
  return response.data.data;
};

// Create an account for projects
const updateBasicData = async (data) => {
  const response = await api.post(`/api/AssetRentRoll/updateBasicData`, data);
  return response.data;
};

// Create an account for projects
const updateCurrentRent = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollCurrentRent`,
    data
  );
  return response.data;
};
// Create an account for projects
const updateIndexation = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollIndexation`,
    data
  );
  return response.data;
};
// Create an account for projects
const updateDuration = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollDuration`,
    data
  );
  return response.data;
};
// Create an account for projects
const updateLeaseTerm = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollLeaseTerm`,
    data
  );
  return response.data;
};
// Create an account for projects
const updateMarketRent = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollMarketRent`,
    data
  );
  return response.data;
};

// Create an account for projects
const updateNonRecoverableCostsRelatting = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollNonRecoverableCostsRelatting`,
    data
  );
  return response.data;
};

// Create an account for projects
const updateNonRecoverableCostsTerm = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollNonRecoverableCostsTerm`,
    data
  );
  return response.data;
};

const updatePreSetting = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollPreSetting`,
    data
  );
  return response.data;
};

const updateRelettingCost = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollRelettingCost`,
    data
  );
  return response.data;
};
const updateRenewalProbability = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollRenewalProbabilty`,
    data
  );
  return response.data;
};
const updateVacancyCosts = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollVacancyCosts`,
    data
  );
  return response.data;
};
const updateVoidPeriod = async (data) => {
  const response = await api.post(
    `/api/AssetRentRoll/updateRentRollVoidPeriod`,
    data
  );
  return response.data;
};
// Delete projects
const deleteRentRoll = async (data) => {
  const response = await api.delete("/api/AssetRentRoll/delete", {
    data: data,
  });
  return response.data;
};

const rentRoll = {
  createRentRollPost,
  getAllRentRollListById,
  getBasicDataByAssetId,
  getLeaseTermByAssetId,
  getDurationByAssetId,
  getMarketRentByAssetId,
  getRenewalProbabiltyByAssetId,
  getPreSettingByAssetId,
  getCurrentRentByAssetId,
  getNonRecoverableCostsTerm,
  getNonRecoverableCostsRelatting,
  getVoidPeriodByAssetId,
  getVacancyCostsByAssetId,
  getRelettingCostsByAssetId,
  getIndexationByAssetId,
  deleteRentRoll,
  updateBasicData,
  updateCurrentRent,
  updateIndexation,
  updateDuration,
  updateLeaseTerm,
  updateMarketRent,
  updateNonRecoverableCostsRelatting,
  updateNonRecoverableCostsTerm,
  updatePreSetting,
  updateRelettingCost,
  updateRenewalProbability,
  updateVacancyCosts,
  updateVoidPeriod,
  getAllTenants,
};

export default rentRoll;
