import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calculation from "./calculationAsset.service";

const initialState = {
  inflationRate: [],
  updateInflationRate: null,
  ervGrowthRate: [],
  updateErvGrowthRate: null,
  financingRate: [],
  getAssetAcquisitionCostData: null,
  getAssetAcquisitionCostExitData: null,
  updateFinancingRate: null,
  updateAcquisitionCostInitial: null,
  updateAcquisitionCostExit: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const getAssetInflation = createAsyncThunk(
  "calculation/getAssetInflation",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await calculation.getAssetInflation(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveAssetInflation = createAsyncThunk(
  "calculation/saveAssetInflation",
  async ({ record, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await calculation.saveAssetInflation(record);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetFinancing = createAsyncThunk(
  "calculation/getAssetFinancing",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await calculation.getAssetFinancing(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveAssetFinancing = createAsyncThunk(
  "calculation/saveAssetFinancing",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await calculation.saveAssetFinancing(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetAcquisitionCostExit = createAsyncThunk(
  "calculation/getAssetAcquisitionCostExit",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await calculation.getAssetAcquisitionCostExit(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveAssetAcquisitionCostExit = createAsyncThunk(
  "calculation/saveAssetAcquisitionCostExit",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await calculation.saveAssetAcquisitionCostExit(
        finalData
      );
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetAcquisitionCostInitial = createAsyncThunk(
  "calculation/getAssetAcquisitionCostInitial",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await calculation.getAssetAcquisitionCostInitial(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveAssetAcquisitionCostInitial = createAsyncThunk(
  "calculation/saveAssetAcquisitionCostInitial",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await calculation.saveAssetAcquisitionCostInitial(
        finalData
      );
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetErvGrowth = createAsyncThunk(
  "calculation/getAssetErvGrowth",
  async (finalData, thunkAPI, notifyToaster) => {
    try {
      return await calculation.getAssetErvGrowth(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveAssetErvGrowth = createAsyncThunk(
  "calculation/saveAssetErvGrowth",
  async ({ record, moveToNext2, notifyToaster }, thunkAPI) => {
    try {
      const response = await calculation.saveAssetErvGrowth(record);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext2) {
          moveToNext2();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const calculationSlice = createSlice({
  name: "calculation",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetInflation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetInflation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inflationRate = action.payload;
      })
      .addCase(getAssetInflation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveAssetInflation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAssetInflation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateInflationRate = action.payload;
      })
      .addCase(saveAssetInflation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetFinancing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetFinancing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.financingRate = action.payload;
      })
      .addCase(getAssetFinancing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveAssetFinancing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAssetFinancing.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateFinancingRate = action.payload;
      })
      .addCase(saveAssetFinancing.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetErvGrowth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetErvGrowth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ervGrowthRate = action.payload;
      })
      .addCase(getAssetErvGrowth.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveAssetErvGrowth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAssetErvGrowth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateErvGrowthRate = action.payload;
      })
      .addCase(saveAssetErvGrowth.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetAcquisitionCostInitial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetAcquisitionCostInitial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAssetAcquisitionCostData = action.payload;
      })
      .addCase(getAssetAcquisitionCostInitial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveAssetAcquisitionCostInitial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAssetAcquisitionCostInitial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAcquisitionCostInitial = action.payload;
      })
      .addCase(saveAssetAcquisitionCostInitial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetAcquisitionCostExit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetAcquisitionCostExit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAssetAcquisitionCostExitData = action.payload;
      })
      .addCase(getAssetAcquisitionCostExit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(saveAssetAcquisitionCostExit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAssetAcquisitionCostExit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updateAcquisitionCostExit = action.payload;
      })
      .addCase(saveAssetAcquisitionCostExit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = calculationSlice.actions;

export default calculationSlice.reducer;
