import { Col, Row } from "antd";
import GroundRentsOtherPeriodicCosts from "./component/IncomeAndCost/GroundRentsOtherPeriodicCosts";
import OtherCashFlows from "./component/IncomeAndCost/OtherCashFlows";
import OneTimeExpensesRevenue from "./component/IncomeAndCost/OneTimeExpensesRevenue";


function IncomeAndCost() {

    return (
        <Row gutter={24} className="incomecost-wrapper">
            <Col xs={24}>
                <GroundRentsOtherPeriodicCosts />
            </Col>
            <Col xs={24}>
                <OtherCashFlows />
            </Col>
            <Col xs={24}>
                <OneTimeExpensesRevenue />
            </Col>
        </Row>
    );
}

export default IncomeAndCost;
