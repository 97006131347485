import { Card, Col, Row, Table } from "antd";
import { BsDot } from "react-icons/bs";
import PropTypes from "prop-types";

function VeOfficeBuilding(props) {
  const secondColumns = [
    {
      title: "Yield Profile @Sale",
      dataIndex: "yieldProfile",
      key: "yieldProfile",
      align: "center",
    },
    {
      title: "NIY",
      dataIndex: "nIY",
      key: "nIY",
      align: "center",
    },
    {
      title: "GIY",
      dataIndex: "gIY",
      key: "gIY",
      align: "center",
    },
    {
      title: "Multiple",
      dataIndex: "multiple",
      key: "multiple",
      align: "center",
    },
  ];

  const secondDataSource = [
    {
      yieldProfile: "Current Rent",
      nIY: "5.00%",
      gIY: "5.00%",
      multiple: "22.0",
    },
    {
      yieldProfile: "Potential Rent",
      nIY: "5.00%",
      gIY: "5.00%",
      multiple: "22.0",
    },
    {
      yieldProfile: "Market Rent",
      nIY: "5.00%",
      gIY: "5.00%",
      multiple: "22.0",
    },
  ];

  const { striped } = props;

  return (
    <div>
      <Card bordered={false}>
        <h3 className="text-blue">Office/Retail Building</h3>
        <Row
          gutter={5}
          className="d-flex align-items-center my-2"
          style={{
            display: "inline-flex",
            "font-size": "16px",
            color: "#969696",
          }}
        >
          <Col>Goethestr.27</Col>
          <Col>
            <BsDot className="mr-1" />
            60313 Frankfurt
          </Col>
          <Col>
            <BsDot className="mr-1" />
            Germany
          </Col>
        </Row>
        <div className="mt-3">
          <Table
            rowClassName={(record, index) =>
              striped !== false && index % 2 === 0
                ? "ant-table-row-light"
                : "ant-table-row-level"
            }
            columns={secondColumns}
            dataSource={secondDataSource}
            pagination={false}
            className="calculation-table"
            scroll={{
              x: 370,
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default VeOfficeBuilding;

VeOfficeBuilding.propTypes = {
  striped: PropTypes.node.isRequired,
};
