import { Col, Row, Tabs } from "antd";
import VeAcquisitionCosts from "./component/Calculation/ValuationExpert/VeAcquisitionCosts";
import VeAcquisitionCostsExit from "./component/Calculation/ValuationExpert/VeAcquisitionCostsExit";
import VeAssetValue from "./component/Calculation/ValuationExpert/VeAssetValue";
import VeAssumptionsParameter from "./component/Calculation/ValuationExpert/VeAssumptionsParameter";
import VeExitValue from "./component/Calculation/ValuationExpert/VeExitValue";
import VeFinancing from "./component/Calculation/ValuationExpert/VeFinancing";
import VeFirstCalculationTable from "./component/Calculation/ValuationExpert/VeFirstCalculationTable";
import VeHoldingPeriodAndExitCalculation from "./component/Calculation/ValuationExpert/VeHoldingPeriodAndExitCalculation";
import VeInflationAndERVGrowth from "./component/Calculation/ValuationExpert/VeInflationAndERVGrowth";
import VeOfficeBuilding from "./component/Calculation/ValuationExpert/VeOfficeBuilding";
import VhlAreaAndIncome from "./component/Calculation/ValuationHighLevel/VhlAreaAndIncome";
import VhlAssetValue from "./component/Calculation/ValuationHighLevel/VhlAssetValue";
import VhlCostsAndVoidPeriods from "./component/Calculation/ValuationHighLevel/VhlCostsAndVoidPeriods";
import VhlExitValue from "./component/Calculation/ValuationHighLevel/VhlExitValue";
import VhlFinancing from "./component/Calculation/ValuationHighLevel/VhlFinancing";
import VhlFirstCalculationTable from "./component/Calculation/ValuationHighLevel/VhlFirstCalculationTable";
import VhlHoldingPeriodAndExitCalculation from "./component/Calculation/ValuationHighLevel/VhlHoldingPeriodAndExitCalculation";
import VhlInflationAndERVGrowth from "./component/Calculation/ValuationHighLevel/VhlInflationAndERVGrowth";
import VhlOfficeBuilding from "./component/Calculation/ValuationHighLevel/VhlOfficeBuilding";

function Calculation() {
  return (
    <div className="asset-details-calculation">
      {" "}
      <Tabs type="card" defaultActiveKey="1">
        <Tabs.TabPane className="mr-2" tab="Valuation Expert" key="1" >
          <div className="calculationComponent">
            <Row
              gutter={24}
              className="d-flex justify-content-between align-items-center button-search"
            >
              {/* <Col xs={24}>
                <div className="d-flex">
                  <span>
                    <Button key="submit" type="default" className="btn-primary">
                      Valuation Expert
                    </Button>
                  </span>
                  <span>
                    <Button
                      key="submit"
                      type="default"
                      className="btn-sure ml-1"
                    >
                      Valuation High Level
                    </Button>
                  </span>
                </div>
              </Col> */}
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VeFirstCalculationTable />
              </Col>
              <Col xs={12}>
                <VeOfficeBuilding />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VeAssetValue />
              </Col>
              <Col xs={12}>
                <VeExitValue />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VeAcquisitionCosts />
              </Col>
              <Col xs={12}>
                <VeAcquisitionCostsExit />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VeInflationAndERVGrowth />
                <VeHoldingPeriodAndExitCalculation />
              </Col>
              <Col xs={12}>
                <VeFinancing />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <VeAssumptionsParameter />
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Valuation High Level " key="2">
          <div className="calculationComponent">
            <Row gutter={24}>
              <Col xs={12}>
                <VhlFirstCalculationTable />
              </Col>
              <Col xs={12}>
                <VhlOfficeBuilding />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VhlAssetValue />
              </Col>
              <Col xs={12}>
                <VhlExitValue />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <VhlAreaAndIncome />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <VhlCostsAndVoidPeriods />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <VhlInflationAndERVGrowth />
                <VhlHoldingPeriodAndExitCalculation />
              </Col>
              <Col xs={12}>
                <VhlFinancing />
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default Calculation;
