import { Button, Col, DatePicker, Form, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";

function LeaseTerm(props) {
  const { next, prev, handleCancel } = props;
  const [constructionDate, setConstructionDate] = useState("");
  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("leaseTerm", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("leaseTerm");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;

  const onChange = (date, dateString) => {
    setConstructionDate(dateString);
  };
  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Lease Term</h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Start"
              name="startdate"
              format="MM/DD/YYYY"
              initialValue={
                FormData?.startdate ? moment(FormData?.startdate) : ""
              }
              rules={[
                {
                  required: true,
                  message: "Please input start date!",
                },
              ]}
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100" onChange={onChange} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="End"
              name="end"
              format="MM/DD/YYYY"
              initialValue={FormData?.end ? moment(FormData?.end) : ""}
              rules={[
                {
                  required: true,
                  message: "Please input end date!",
                },
              ]}
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100"  disabled={!constructionDate}
                disabledDate={(current) => {
                  const disabledDate = `${constructionDate}-01-01`
                  return (
                    current && current < moment(disabledDate, "YYYY-MM-DD")
                  );
                }}/>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Option1"
              name="option1"
              format="MM/DD/YYYY"
              initialValue={FormData?.option1 ? moment(FormData?.option1) : ""}
              rules={[
                {
                  required: true,
                  message: "Please input option 1 date!",
                },
              ]}
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Option2"
              name="option2"
              format="MM/DD/YYYY"
              initialValue={FormData?.option2 ? moment(FormData?.option2) : ""}
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Option3"
              name="option3"
              format="MM/DD/YYYY"
              initialValue={FormData?.option3 ? moment(FormData?.option3) : ""}
            >
              <DatePicker placeholder="DD.MM.YYYY" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LeaseTerm;
LeaseTerm.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
