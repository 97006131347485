/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import assetsDetails from "./projectDetail.service";

const initialState = {
  assetDetail: null,
  assetDetails: null,
  createAsset: null,
  deleteAssets: null,
  projectDetailById: null,
  getBasicData: null,
  getPropertyAddress: null,
  getPropertyAttributes: null,
  getRating: null,
  getInvestmentStrategy: null,
  getAssetSite: null,
  getInspection: null,
  getESG: null,
  getOther: null,
  basicData: null,
  propertyAddress: null,
  propertyAttributes: null,
  propertyRating: null,
  investmentStrategy: null,
  site: null,
  updateInspection: null,
  esg: null,
  other: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
  projectDetailFilters: null,
};

export const createAssetDetail = createAsyncThunk(
  "assetsDetails/createAssetDetail",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.createAssetDetail(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateBasicData = createAsyncThunk(
  "assetsDetails/updateBasicData",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateBasicData(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePropertyAddress = createAsyncThunk(
  "assetsDetails/updatePropertyAddress",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updatePropertyAddress(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePropertyAttributes = createAsyncThunk(
  "assetsDetails/updatePropertyAttributes",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updatePropertyAttributes(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      response.isEdit = finalData.projectId !== 0 ? true : false;
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePropertyRating = createAsyncThunk(
  "assetsDetails/updatePropertyRating",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updatePropertyRating(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateInvestmentStrategy = createAsyncThunk(
  "assetsDetails/updateInvestmentStrategy",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateInvestmentStrategy(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSite = createAsyncThunk(
  "assetsDetails/updateSite",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateSite(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateInspection = createAsyncThunk(
  "assetsDetails/updateInspection",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateInspection(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateEsg = createAsyncThunk(
  "assetsDetails/updateEsg",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateEsg(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateOther = createAsyncThunk(
  "assetsDetails/updateOther",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.updateOther(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllAssetDetailList = createAsyncThunk(
  "assetsDetails/getAllAssetDetailList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getAllAssetDetailList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getBasicDataList = createAsyncThunk(
  "assetsDetails/getBasicDataList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getBasicDataList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPropertyAddressList = createAsyncThunk(
  "assetsDetails/getPropertyAddressList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getPropertyAddressList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPropertyAttributesList = createAsyncThunk(
  "assetsDetails/getPropertyAttributesList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getPropertyAttributesList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRatingList = createAsyncThunk(
  "assetsDetails/getRatingList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getRatingList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInvestmentStrategyList = createAsyncThunk(
  "assetsDetails/getInvestmentStrategyList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getInvestmentStrategyList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAssetSiteList = createAsyncThunk(
  "assetsDetails/getAssetSiteList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getAssetSiteList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInspectionList = createAsyncThunk(
  "assetsDetails/getInspectionList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getInspectionList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getESGList = createAsyncThunk(
  "assetsDetails/getESGList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getESGList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOtherList = createAsyncThunk(
  "assetsDetails/getOtherList",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getOtherList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleAssetDetail = createAsyncThunk(
  "assetsDetails/getSingleAssetDetail",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getSingleAssetDetail(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProjectDetailById = createAsyncThunk(
  "assetsDetails/getProjectDetailById",
  async (finalData, thunkAPI) => {
    try {
      return await assetsDetails.getProjectDetailById(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteAssetDetail = createAsyncThunk(
  "assetsDetails/deleteAssetDetail",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response = await assetsDetails.deleteAssetDetail(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProjectFilters = createAsyncThunk(
  "assetsDetails/updateProjectFilters",
  async ({ key, value }, thunkAPI) => {
    return { key, value };
  }
);

export const resetProjectFilters = createAsyncThunk(
  "assetsDetails/resetProjectFilters",
  async (thunkAPI) => {
    return {};
  }
);

export const createProjectSlice = createSlice({
  name: "projectDetail",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAssetDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAssetDetail.fulfilled, (state, action) => {
        // const createAsset = [...state.assetDetails];
        // createAsset.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.createAsset = action.payload;
      })
      .addCase(createAssetDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllAssetDetailList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAssetDetailList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assetDetails = action.payload;
      })
      .addCase(getAllAssetDetailList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteAssetDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAssetDetail.fulfilled, (state, action) => {
        // const deleteMultipleAssets = [...state.assetDetails];
        // deleteMultipleAssets = state.assetDetails.filter(
        //   (item) => item.assetId !== action.payload
        // );
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteAssets = action.payload;
      })
      .addCase(deleteAssetDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateBasicData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBasicData.fulfilled, (state, action) => {
        // const basicData = [...state.assetDetails];
        // basicData.unshift(action.payload);

        state.isLoading = false;
        state.isSuccess = true;
        state.basicData = action.payload;
      })
      .addCase(updateBasicData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePropertyAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePropertyAddress.fulfilled, (state, action) => {
        // const propertyAddress = [...state.assetDetails];
        // // propertyAddress.unshift(action.payload.data);
        // if (action.payload.isEdit) {
        //   for (let i = 0; i < propertyAddress.length; i++) {
        //     if (action.payload.data) {
        //       propertyAddress[i] = action.payload.data;
        //       break;
        //     }
        //   }
        // }
        state.isLoading = false;
        state.isSuccess = true;
        state.propertyAddress = action.payload;
      })
      .addCase(updatePropertyAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePropertyAttributes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePropertyAttributes.fulfilled, (state, action) => {
        // const propertyAttributes = [...state.assetDetails];
        // propertyAttributes.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.propertyAttributes = action.payload;
      })
      .addCase(updatePropertyAttributes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePropertyRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePropertyRating.fulfilled, (state, action) => {
        // const propertyRating = [...state.assetDetails];
        // propertyRating.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.propertyRating = action.payload;
      })
      .addCase(updatePropertyRating.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateInvestmentStrategy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvestmentStrategy.fulfilled, (state, action) => {
        // const investmentStrategy = [...state.assetDetails];
        // investmentStrategy.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.investmentStrategy = action.payload;
      })
      .addCase(updateInvestmentStrategy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateSite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        // const site = [...state.assetDetails];
        // site.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.site = action.payload;
      })
      .addCase(updateSite.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateInspection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInspection.fulfilled, (state, action) => {
        // const inspection = [...state.assetDetails];
        // inspection.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.updateInspection = action.payload;
      })
      .addCase(updateInspection.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateEsg.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEsg.fulfilled, (state, action) => {
        // const esg = [...state.assetDetails];
        // esg.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.esg = action.payload;
      })
      .addCase(updateEsg.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getProjectDetailById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjectDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectDetailById = action.payload;
      })
      .addCase(getProjectDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getBasicDataList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBasicDataList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getBasicData = action.payload;
      })
      .addCase(getBasicDataList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPropertyAddressList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropertyAddressList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPropertyAddress = action.payload;
      })
      .addCase(getPropertyAddressList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getPropertyAttributesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropertyAttributesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getPropertyAttributes = action.payload;
      })
      .addCase(getPropertyAttributesList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getRatingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRatingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getRating = action.payload;
      })
      .addCase(getRatingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getInvestmentStrategyList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvestmentStrategyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getInvestmentStrategy = action.payload;
      })
      .addCase(getInvestmentStrategyList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAssetSiteList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetSiteList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getAssetSite = action.payload;
      })
      .addCase(getAssetSiteList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getInspectionList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInspectionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getInspection = action.payload;
      })
      .addCase(getInspectionList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getESGList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getESGList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getESG = action.payload;
      })
      .addCase(getESGList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getOtherList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOtherList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getOther = action.payload;
      })
      .addCase(getOtherList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateOther.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOther.fulfilled, (state, action) => {
        // const other = [...state.assetDetails];
        // other.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.other = action.payload;
      })
      .addCase(updateOther.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateProjectFilters.fulfilled, (state, action) => {
        const updatedProjectDetailFilters = {
          ...state.projectDetailFilters,
          [action.payload.key]: action.payload.value,
        };
        state.projectDetailFilters = updatedProjectDetailFilters;
      })

      .addCase(resetProjectFilters.fulfilled, (state, action) => {
        state.projectDetailFilters = {};
      });
  },
});

export const { clearData } = createProjectSlice.actions;

export default createProjectSlice.reducer;
