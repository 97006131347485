/* eslint-disable no-debugger */

import { Modal, Col, Row, Input, Button, DatePicker, Form } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";
// import { useDispatch } from "react-redux";
// import { getAllUserList } from "../../../../app/features/userManagement/user.slice";

function SearchForm(props) {
  const {
    visible,
    handleCancel,
    setLocationValue,
    setEmailValue,
    setCreatedDateValue,
    setSearchValue,
    setIsModalOpen,
    form,
  } = props;
  const { Search } = Input;
  // const dispatch = useDispatch();
  const onFinish = (data) => {
    setSearchValue(data.search);
    setLocationValue(data.location);
    setEmailValue(data.email);
    setCreatedDateValue(data.createdDate);
    setIsModalOpen(false);
  };
  return (
    <Modal
      className="search-modals user"
      open={visible}
      handleCancel={handleCancel}
      width={550}
      style={{
        top: 80,
      }}
      footer={false}
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        className="add-new-org-class"
        form={form}
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Please input your User Name",
                },
              ]}
            >
              <Search className="mr-2" placeholder="Search User" />
            </Form.Item>
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
          </Col>
        </Row>
        <br />
        <Row className="row d-flex search-form-filter-row" gutter={10}>
          <Col xs={24} md={12} lg={8} className="mb-2">
            <Form.Item
              name="createdDate"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                placeholder="Created Date"
                className="w-100 rounded-sm"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} className="mb-2">
            <Form.Item
              name="email"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="Email" type="email" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} className="mb-2">
            <Form.Item
              name="location"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input placeholder="Location" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col
            xs={24}
            className="align-items-center d-flex justify-content-end mt-2"
          >
            <Button className="btn-cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="default"
              htmlType="submit"
              className="btn-primary"
              // disabled={isLoaded}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  setCreatedDateValue: PropTypes.node.isRequired,
  setEmailValue: PropTypes.node.isRequired,
  setLocationValue: PropTypes.node.isRequired,
  setSearchValue: PropTypes.node.isRequired,
  setIsModalOpen: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
};
