/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rentRoll from "./rentRoll.service.js";

const initialState = {
  rentRollList: null,
  rentRollListBasic: [],
  rentRollListCurrent: [],
  rentRollListDuration: [],
  rentRollListLeaseTerm: [],
  rentRollListMarketRent: [],
  rentRollListCostRelating: [],
  rentRollListCostTerm: [],
  rentRollListPreSetting: [],
  rentRollListRelettingCosts: [],
  rentRollListRenewal: [],
  rentRollListVacancy: [],
  rentRollListVoid: [],
  rentRollListIndexation: [],
  allTenants:[],
  createRentRollPost: null,
  deleteRentRoll: null,
  RentRollById: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createRentRollPost = createAsyncThunk(
  "rentRoll/createRentRollPost",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.createRentRollPost(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateBasicData = createAsyncThunk(
  "rentRoll/updateBasicData",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateBasicData(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateCurrentRent = createAsyncThunk(
  "rentRoll/updateCurrentRent",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateCurrentRent(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateIndexation = createAsyncThunk(
  "rentRoll/updateIndexation",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateIndexation(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateDuration = createAsyncThunk(
  "rentRoll/updateDuration",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateDuration(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateLeaseTerm = createAsyncThunk(
  "rentRoll/updateLeaseTerm",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateLeaseTerm(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateMarketRent = createAsyncThunk(
  "rentRoll/updateMarketRent",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateMarketRent(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateNonRecoverableCostsRelatting = createAsyncThunk(
  "rentRoll/updateNonRecoverableCostsRelatting",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateNonRecoverableCostsRelatting(
        finalData
      );
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateNonRecoverableCostsTerm = createAsyncThunk(
  "rentRoll/updateNonRecoverableCostsTerm",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateNonRecoverableCostsTerm(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatePreSetting = createAsyncThunk(
  "rentRoll/updatePreSetting",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updatePreSetting(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateRelettingCost = createAsyncThunk(
  "rentRoll/updateRelettingCost",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateRelettingCost(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateRenewalProbability = createAsyncThunk(
  "rentRoll/updateRenewalProbability",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateRenewalProbability(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateVacancyCosts = createAsyncThunk(
  "rentRoll/updateVacancyCosts",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateVacancyCosts(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateVoidPeriod = createAsyncThunk(
  "rentRoll/updateVoidPeriod",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.updateVoidPeriod(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      response.isEdit = finalData.projectId !== 0 ? true : false;
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllRentRollListById = createAsyncThunk(
  "rentRoll/getAllRentRollListById",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getAllRentRollListById(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBasicDataByAssetId = createAsyncThunk(
  "rentRoll/getBasicDataByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getBasicDataByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getLeaseTermByAssetId = createAsyncThunk(
  "rentRoll/getLeaseTermByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getLeaseTermByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDurationByAssetId = createAsyncThunk(
  "rentRoll/getDurationByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getDurationByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMarketRentByAssetId = createAsyncThunk(
  "rentRoll/getMarketRentByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getMarketRentByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRenewalProbabiltyByAssetId = createAsyncThunk(
  "rentRoll/getRenewalProbabiltyByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getRenewalProbabiltyByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPreSettingByAssetId = createAsyncThunk(
  "rentRoll/getPreSettingByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getPreSettingByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCurrentRentByAssetId = createAsyncThunk(
  "rentRoll/getCurrentRentByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getCurrentRentByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNonRecoverableCostsTerm = createAsyncThunk(
  "rentRoll/getNonRecoverableCostsTerm",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getNonRecoverableCostsTerm(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNonRecoverableCostsRelatting = createAsyncThunk(
  "rentRoll/getNonRecoverableCostsRelatting",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getNonRecoverableCostsRelatting(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVoidPeriodByAssetId = createAsyncThunk(
  "rentRoll/getVoidPeriodByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getVoidPeriodByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVacancyCostsByAssetId = createAsyncThunk(
  "rentRoll/getVacancyCostsByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getVacancyCostsByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRelettingCostsByAssetId = createAsyncThunk(
  "rentRoll/getRelettingCostsByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getRelettingCostsByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getIndexationByAssetId = createAsyncThunk(
  "rentRoll/getIndexationByAssetId",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getIndexationByAssetId(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllTenants = createAsyncThunk(
  "rentRoll/getAllTenants",
  async (finalData, thunkAPI) => {
    try {
      return await rentRoll.getAllTenants(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteRentRoll = createAsyncThunk(
  "rentRoll/deleteRentRoll",
  async ({ finalData, moveToNextDelete, notifyToaster }, thunkAPI) => {
    try {
      const response = await rentRoll.deleteRentRoll(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDelete) {
          moveToNextDelete();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createRentRollSlice = createSlice({
  name: "RentRoll",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRentRollPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRentRollPost.fulfilled, (state, action) => {
        // const createRentRollPost = [...state.rentRollList];
        // createRentRollPost.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.createRentRollPost = action.payload;
      })
      .addCase(createRentRollPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllRentRollListById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRentRollListById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = action.payload;
      })
      .addCase(getAllRentRollListById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getBasicDataByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBasicDataByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListBasic = action.payload;
      })
      .addCase(getBasicDataByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getLeaseTermByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeaseTermByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListLeaseTerm = action.payload;
      })
      .addCase(getLeaseTermByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getDurationByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDurationByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListDuration = action.payload;
      })
      .addCase(getDurationByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getMarketRentByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMarketRentByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListMarketRent = action.payload;
      })
      .addCase(getMarketRentByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getRenewalProbabiltyByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRenewalProbabiltyByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListRenewal = action.payload;
      })
      .addCase(getRenewalProbabiltyByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getPreSettingByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPreSettingByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListPreSetting = action.payload;
      })
      .addCase(getPreSettingByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getCurrentRentByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentRentByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListCurrent = action.payload;
      })
      .addCase(getCurrentRentByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getNonRecoverableCostsTerm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNonRecoverableCostsTerm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListCostTerm = action.payload;
      })
      .addCase(getNonRecoverableCostsTerm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getNonRecoverableCostsRelatting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNonRecoverableCostsRelatting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListCostRelating = action.payload;
      })
      .addCase(getNonRecoverableCostsRelatting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getVoidPeriodByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVoidPeriodByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListVoid = action.payload;
      })
      .addCase(getVoidPeriodByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getVacancyCostsByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVacancyCostsByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListVacancy = action.payload;
      })
      .addCase(getVacancyCostsByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getRelettingCostsByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRelettingCostsByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListRelettingCosts = action.payload;
      })
      .addCase(getRelettingCostsByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(getIndexationByAssetId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndexationByAssetId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollListIndexation = action.payload;
      })
      .addCase(getIndexationByAssetId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllTenants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTenants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allTenants = action.payload;
      })
      .addCase(getAllTenants.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteRentRoll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRentRoll.fulfilled, (state, action) => {
        // const deleteMultipleAssets = [...state.rentRollList];
        // deleteMultipleAssets = state.rentRollList.filter(
        //   (item) => item.assetId !== action.payload
        // );
        state.isLoading = false;
        state.isSuccess = true;
        state.deleteRentRoll = action.payload;
      })
      .addCase(deleteRentRoll.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateBasicData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBasicData.fulfilled, (state, action) => {
        const basicData = [...state.rentRollList];
        basicData.unshift(action.payload);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = basicData;
      })
      .addCase(updateBasicData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateCurrentRent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCurrentRent.fulfilled, (state, action) => {
        const currentRent = [...state.rentRollList];
        currentRent.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = currentRent;
      })
      .addCase(updateCurrentRent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateIndexation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndexation.fulfilled, (state, action) => {
        const indexation = [...state.rentRollList];
        indexation.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = indexation;
      })
      .addCase(updateIndexation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateDuration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDuration.fulfilled, (state, action) => {
        const duration = [...state.rentRollList];
        duration.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = duration;
      })
      .addCase(updateDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateLeaseTerm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLeaseTerm.fulfilled, (state, action) => {
        const leaseTerm = [...state.rentRollList];
        leaseTerm.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = leaseTerm;
      })
      .addCase(updateLeaseTerm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateMarketRent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMarketRent.fulfilled, (state, action) => {
        const marketRent = [...state.rentRollList];
        marketRent.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = marketRent;
      })
      .addCase(updateMarketRent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateNonRecoverableCostsRelatting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateNonRecoverableCostsRelatting.fulfilled,
        (state, action) => {
          const costsRelatting = [...state.rentRollList];
          costsRelatting.unshift(action.payload.data);

          state.isLoading = false;
          state.isSuccess = true;
          state.rentRollList = costsRelatting;
        }
      )
      .addCase(updateNonRecoverableCostsRelatting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateNonRecoverableCostsTerm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNonRecoverableCostsTerm.fulfilled, (state, action) => {
        const costsTerm = [...state.rentRollList];
        costsTerm.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = costsTerm;
      })
      .addCase(updateNonRecoverableCostsTerm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updatePreSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePreSetting.fulfilled, (state, action) => {
        const preSetting = [...state.rentRollList];
        preSetting.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = preSetting;
      })
      .addCase(updatePreSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateRelettingCost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRelettingCost.fulfilled, (state, action) => {
        const relettingCost = [...state.rentRollList];
        relettingCost.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = relettingCost;
      })
      .addCase(updateRelettingCost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateRenewalProbability.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRenewalProbability.fulfilled, (state, action) => {
        const renewalProbability = [...state.rentRollList];
        renewalProbability.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = renewalProbability;
      })
      .addCase(updateRenewalProbability.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateVacancyCosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVacancyCosts.fulfilled, (state, action) => {
        const vacancyCosts = [...state.rentRollList];
        vacancyCosts.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = vacancyCosts;
      })
      .addCase(updateVacancyCosts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(updateVoidPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVoidPeriod.fulfilled, (state, action) => {
        const voidPeriod = [...state.rentRollList];
        voidPeriod.unshift(action.payload.data);

        state.isLoading = false;
        state.isSuccess = true;
        state.rentRollList = voidPeriod;
      })
      .addCase(updateVoidPeriod.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createRentRollSlice.actions;

export default createRentRollSlice.reducer;
