/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import { Button, Col, Form, Input, notification, Row } from "antd";
// import { data } from "jquery";
import moment from "moment";
import PropTypes from "prop-types";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createRentRollPost,
  getBasicDataByAssetId,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { Loader1 } from "../../../../../../../components/Layout/Loader/FullScreenLoader";

function RelettingCosts(props) {
  const { prev, handleCancel, setCurrent } = props;
  const {isLoading} = useSelector((state) => state.rentRoll);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userParams = useParams();
  // const onFinish = (values) => {
  //   console.log('Success:', values);
  //   handleCancel();
  // };
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const getFromLocalStorage = () => {
    const keys = [
      "basicForm",
      "currentRent",
      "generalInfo",
      "indexation",
      "leaseTerm",
      "marketRent",
      "nonRecoverable",
      "nonRecoverableCostTerm",
      "presetting",
      "renewalProbability",
      "vacanctCosts",
      "voidPeriod",
    ];
    const dataArray = {};
    keys.map((item) => {
      const basicFormData = localStorage.getItem(item);
      const formData = basicFormData ? JSON.parse(basicFormData) : null;
      dataArray[item] = formData;
      return formData;
    });

    return dataArray;
  };
  const moveToNext = () => {
    const keys = [
      "basicForm",
      "currentRent",
      "generalInfo",
      "indexation",
      "leaseTerm",
      "marketRent",
      "nonRecoverable",
      "nonRecoverableCostTerm",
      "presetting",
      "renewalProbability",
      "vacanctCosts",
      "voidPeriod",
    ];
    keys.map((item) => localStorage.removeItem(item));
    handleCancel();
    setCurrent(0);
    form.resetFields();
    const finalData = {
      assetId: +userParams.assetDetailId,
    };
    dispatch(getBasicDataByAssetId(finalData));
  };

  const localData = getFromLocalStorage();

  const onFinish = (data) => {
    const finalData = {
      rentRollId: 0,
      assetId: parseInt(userParams.assetDetailId),
      statusTypeId: parseInt(localData.generalInfo.statusType),
      tenantName: localData.basicForm.tenantName,
      description1: localData.basicForm.description1,
      description2: localData.basicForm.description2,
      location: localData.basicForm.locationFloor,
      useGroupTypeId: localData.basicForm.useGroup,
      useTypeId: localData.basicForm.use,
      area: parseInt(localData.basicForm.areaUnit),

      rentRoll_CurrentRent: {
        rentRollId: 0,
        perSqMeter: parseInt(localData.currentRent.perSqM),
        perMonth: localData.currentRent.month,
        perYear: localData.currentRent.year,
        // vat: localData.currentRent.vat,
        vat: localData.currentRent.vat === "True" ? true : false,
      },
      rentRoll_LeaseTerm: {
        rentRollId: 0,
        startDate: moment(localData.leaseTerm.startdate).format("YYYY-MM-DD"),
        endDate: moment(localData.leaseTerm.end).format("YYYY-MM-DD"),
        option1: moment(localData.leaseTerm.option1).format("YYYY-MM-DD"),
        option2: localData.leaseTerm.option2
          ? moment(localData.leaseTerm.option2).format("YYYY-MM-DD")
          : null,
        option3: localData.leaseTerm.option3
          ? moment(localData.leaseTerm.option3).format("YYYY-MM-DD")
          : null,
      },
      rentRoll_RenewalProbabilty: {
        rentRollId: 0,
        initialRenewalProbability:
          localData.renewalProbability.initialRenewalProbability !== "" && localData.renewalProbability.initialRenewalProbability || null,
        continuingRenewalProbability: 
          localData.renewalProbability.continuingRenewalProbability !== "" && localData.renewalProbability.continuingRenewalProbability || null,
      },
      rentRoll_Indexation: {
        rentRollId: 0,
        indexationTypeId: localData.indexation.type,
        indexTypeId: parseInt(localData.indexation.index),
        lastDate: moment(localData.leaseTerm.lastDate).format("YYYY-MM-DD"),
        firstDate: moment(localData.leaseTerm.firstDate).format("YYYY-MM-DD"),
        nextDate: moment(localData.leaseTerm.nextDate).format("YYYY-MM-DD"),
        cycle: localData.indexation.cycle,
        hurdle: localData.indexation.hurdle,
        factor: localData.indexation.factor,
      },
      rentRoll_PreSetting: {
        rentRollId: 0,
        cluster: localData.presetting.cluster,
        subCluster: localData.presetting.subCluster,
      },
      rentRoll_MarketRent: {
        rentRollId: 0,
        perSqMeter: parseInt(localData.marketRent.perSqM),
        perMonth: localData.marketRent.month,
        perYear: localData.marketRent.year,
      },
      rentRoll_NonRecoverableCostsTerm: {
        rentRollId: 0,
        maintenance: localData.nonRecoverableCostTerm.maintenance,
        perMonth: localData.nonRecoverableCostTerm.management,
        otherCosts: localData.nonRecoverableCostTerm.otherCosts,
      },
      rentRoll_NonRecoverableCostsRelatting: {
        rentRollId: 0,
        maintenance: localData.nonRecoverable.maintenance,
        perMonth: localData.nonRecoverable.management,
        otherCosts: localData.nonRecoverable.otherCosts,
      },
      rentRoll_VoidPeriod: {
        rentRollId: 0,
        initial: localData.voidPeriod.initial,
        continuing: localData.voidPeriod.continuing,
      },
      rentRoll_VacancyCost: {
        rentRollId: 0,
        vacancyCosts: localData.vacanctCosts.VacancyCosts,
      },
      rentRoll_RelettingCost: {
        rentRollId: 0,
        initialTIs: +data.initial,
        continuingTIs: +data.continue,
        agentCosts: +data.agentCosta,
      },
    };
    dispatch(createRentRollPost({ finalData, moveToNext, notifyToaster }));
  };

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">
        Reletting Costs
      </h3>
      <Form
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Initial TI's"
              name="initial"
              rules={[
                {
                  required: true,
                  message: "Please input Initial",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Eur / Sq M" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Cont. TI's"
              name="continue"
              rules={[
                {
                  required: true,
                  message: "Please input Cont.",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Eur / Sq M" type="number" className="w-100" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Agent Costs"
              name="agentCosta"
              rules={[
                {
                  required: true,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Months" type="number" className="w-100" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className={`btn-primary ${isLoading && "disabled"}`}  
                  htmlType="submit"
                  disabled={isLoading}
                >
                  <div className="d-flex justify-content-center align-items-center">
                  Done
                  {isLoading && <Loader1 />}
                </div>
                 
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default RelettingCosts;

RelettingCosts.propTypes = {
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
  setCurrent: PropTypes.node.isRequired,
};
