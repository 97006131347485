import { Tabs } from "antd";
import IMAGES from "../../../../../assets/images";
import Calculation from "./Calculation";
import CalculationSetting from "./CalculationSetting";
import CashFlows from "./CashFlow";
import Dashboard from "./Dashboard";
import IncomeAndCost from "./IncomeAndCost";
import RentRoll from "./RentRoll";


export default function Index() {
  return (
    <div className="assetDetails">
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_DASHBOARD_ICON} alt="" />
          <p className="mb-0 ml-2">Portfolio Dashboard</p></div>} key="1">
          <Dashboard />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_CALCULATION_ICON} alt="" />
          <p className="mb-0 ml-2">Calculation</p></div>} key="2">
          <Calculation />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_RENT_ROLL_ICON} alt="" />
          <p className="mb-0 ml-2">Rent Roll</p></div>} key="3">
          <RentRoll />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_INCOME_COST_ICON} alt="" />
          <p className="mb-0 ml-2">Other Income &amp; Cost</p></div>} key="4">
          <IncomeAndCost />
        </Tabs.TabPane>
        
        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_CASH_FLOW_ICON} alt="" />
          <p className="mb-0 ml-2">Cashflows</p></div>} key="5">
          <CashFlows />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<div className="d-flex justify-content-center align-items-center">
          <img src={IMAGES.ASSET_CALCULATION_SETTING_ICON} alt="" />
          <p className="mb-0 ml-2">Settings</p></div>} key="6">
          <CalculationSetting />
        </Tabs.TabPane>
      </Tabs>
    </div>
  </div>
  );
}
