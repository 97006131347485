/* eslint-disable react/jsx-props-no-spreading */
import { Card, Input, Form, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};




function VeInflationAndERVGrowth(props) {
  const [inflationDataSource, setInflationDataSource] = useState([
    {
      twentyOne: "6.50",
      twentyTwo: "6.50",
      twentyThree: "6.50",
      twentyFour: "6.50",
      twentyFive: "6.50",
      ff: "6.50",
    },
  ]);

  const inflationColumns = [
    {
      title: "2021",
      dataIndex: "twentyOne",
      key: "twentyOne",
      align: "center",
      editable: true,
    },
    {
      title: "2022",
      dataIndex: "twentyTwo",
      key: "twentyTwo",
      align: "center",
      editable: true,
    },
    {
      title: "2023",
      dataIndex: "twentyThree",
      key: "twentyThree",
      align: "center",
      editable: true,
    },
    {
      title: "2024",
      dataIndex: "twentyFour",
      key: "twentyFour",
      align: "center",
      editable: true,
    },
    {
      title: "2025",
      dataIndex: "twentyFive",
      key: "twentyFive",
      align: "center",
      editable: true,
    },
    {
      title: "Ff.>>",
      dataIndex: "ff",
      key: "ff",
      align: "center",
      editable: true,
    },
  ];




  const { striped } = props;

  const handleSave = (row) => {
    const newData = [...inflationDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setInflationDataSource(newData);
  };


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };


  const columns = inflationColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });



  return (
    <div className="erv-table">
      <Card bordered={false}>
        <div>
        <h3 className="text-black">Inflation & ERV Growth</h3>
          <hr className="border-top-0 border-2 mt-0" />
        </div>
        <div>
          <Table
            components={components}
            rowClassName={(record, index) =>
              striped !== false && index % 2 === 0
                ? "ant-table-row-light"
                : "ant-table-row-level"
            }
            columns={columns}
            dataSource={inflationDataSource}
            bordered
            pagination={false}
            className="calculation-table"
          />
        </div>
      </Card>
    </div>
  );
}

export default VeInflationAndERVGrowth;
VeInflationAndERVGrowth.propTypes = {
  striped: PropTypes.node.isRequired,
};

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};

