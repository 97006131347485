/* eslint-disable radix */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable object-shorthand */
import { Col, Form, Input, Row, Select, Button, notification } from "antd";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  getAllCities,
  getAllCountry,
  getAllState,
} from "../../../../../../app/features/Lookups/lookup.slice";
import {
  createAssetDetail,
  getBasicDataList,
} from "../../../../../../app/features/projectDetails/projectDetail.slice";
import { Loader1 } from "../../../../../../components/Layout/Loader/FullScreenLoader";

export default function Address(props) {
  const [currentLocation, setCurrentLocation] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const { isLoading } = useSelector((state) => state.projectDetails);
  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  useEffect(() => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentLocation(pos);
      }
    );
  }, []);

  function HandleClick(data) {
    const lat = data.latLng.lat();
    const lng = data.latLng.lng();
    const pos = { lat, lng };
    setCurrentLocation(pos);
  }

  const handleSelect = (data) => {
    const mapLocationData = data.label;
    setMapLocation(mapLocationData);
    geocodeByAddress(data.label)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setCurrentLocation(latLng);
      });
  };
  const { prev, handleCancel, setCurrent } = props;
  const { Option } = Select;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userParams = useParams();

  const [countryIdData, setCountryIdData] = useState();
  const [stateId, setStateId] = useState();
  const { country, states, cities } = useSelector((state) => state.lookUps);

  useEffect(() => {
    dispatch(getAllCountry());
  }, [dispatch]);

  const getStates = (e) => {
    form.setFieldValue("federalState", 0);
    form.setFieldValue("city", 0);
    const finalData = {
      countryId: e,
    };
    dispatch(getAllState(finalData));
  };
  const getCities = (e) => {
    form.setFieldValue("city", 0);

    const finalData = {
      stateId: e,
    };
    dispatch(getAllCities(finalData));
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    form.resetFields();
    localStorage.removeItem("form-1");
    setCurrent(0);
    handleCancel();
    const finalData = {
      projectId: +userParams.projectId,
      pageNo: 1,
      size: 10,
    };
    dispatch(getBasicDataList(finalData));
  };

  const localStorageData = localStorage.getItem("form-1");
  const formData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinish = (data) => {
    const finalData = {
      basicData: {
        assetId: 0,
        projectId: parseInt(userParams.projectId, 10),
        assetName: formData.assetName,
        assetTypeId: formData.assetType,
        yearsOfConstruction: moment(formData.YearsofConstruction).format(
          "YYYY"
        ),
        yearsOfRefurbishment: formData.yearsofRefurbishment
          ? moment(formData.yearsofRefurbishment).format("YYYY")
          : "",
        analysisDate: formData.analysisDate,
      },
      assetAddress: {
        assetId: 0,
        countryId: data.country,
        stateId: data.federalState,
        cityId: data.city,
        zipCode: data.zipCode,
        street: data.street,
        houseNumber: data.no,
        mapAddress: mapLocation,
        longitude: currentLocation?.lng
          ? (currentLocation?.lng).toString()
          : "",
        latitude: currentLocation?.lng ? (currentLocation?.lat).toString() : "",
      },
    };
    dispatch(createAssetDetail({ finalData, moveToNext, notifyToaster }));
  };

  return (
    <div className="pt-3 stepper-address">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Address</h3>
      <Form
        name="basic"
        className="user-modal"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col xs={8}>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                { required: true, message: "Please input your Country!" },
              ]}
            >
              <Select
                placeholder="Select"
                allowClear
                value={countryIdData}
                onChange={(e) => {
                  setCountryIdData(e);
                  getStates(e);
                  getCities();

                  // getStates(e.target.value);
                }}
              >
                {country?.map((item) => (
                  <Option key={item.countryId} value={item.countryId}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label="Federal State"
              name="federalState"
              rules={[
                { required: true, message: "Please input your Federal State!" },
              ]}
            >
              <Select
                placeholder="Select"
                allowClear
                value={stateId}
                onChange={(e) => {
                  setStateId(e);
                  getCities(e);
                }}
              >
                {states?.map((item) => (
                  <Option key={item.stateId} value={item.stateId}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please input your City!" }]}
            >
              <Select placeholder="Select" allowClear>
                {cities?.map((item) => (
                  <Option key={item.cityId} value={item.cityId}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              name="zipCode"
              label="Zip Code"
              rules={[
                { required: true, message: "Please input your Zip Code!" },
                {
                  max: 5,
                  message: "Zip Code can't be greater than 5 Characters",
                },
              ]}
            >
              <Input placeholder="Enter zip code" type="number" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              label="Street"
              name="street"
              rules={[
                { required: true, message: "Please input your street!" },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Enter street" />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Form.Item
              name="no"
              label="No."
              rules={[
                { required: false },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="House No" type="text" />
            </Form.Item>
          </Col>
        </Row>
        <hr className="w-100" />
        <Row gutter={24}>
          <Col xs={8}>
            <Row gutter={24}>
              <Col xs={24}>
                <GooglePlacesAutocomplete
                  // apiKey={GOOGLE_MAP_KEY}
                  className="form-control"
                  selectProps={{
                    Address,
                    onChange: handleSelect,
                    placeholder: "Add address",
                  }}
                />
              </Col>
              <Col xs={24}>
                <Form.Item label="Longitude" rules={[{ required: false }]}>
                  <Input
                    type="text"
                    value={currentLocation && currentLocation?.lng}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Latitude" rules={[{ required: false }]}>
                  <Input value={currentLocation.lat} type="text" disabled />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={16}>
            <div className="mapouter mt-3">
              <div className="gmap_canvas">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={currentLocation}
                  zoom={
                    currentLocation.lat && currentLocation.lng !== 0 ? 18 : 2
                  }
                  onClick={(e) => HandleClick(e)}
                  yesIWantToUseGoogleMapApiInternals
                >
                  {currentLocation.lat && currentLocation.lng !== 0 ? (
                    <Marker
                      position={currentLocation}
                      title="Current Location"
                    />
                  ) : null}
                </GoogleMap>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    ".mapouter{position:relative;text-align:right;width:100%;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:300px;}.gmap_iframe {width:100%!important;height:300px!important;}",
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>

                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                  disabled={isLoading}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    Done
                    {isLoading && <Loader1 />}
                  </div>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
Address.propTypes = {
  prev: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  setCurrent: PropTypes.node.isRequired,
};
