import { CaretDownFilled } from "@ant-design/icons";
import { Card, Cascader, Checkbox, Col, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VhlCostsAndVoidPeriods() {
  const options = [
    {
      value: "demo",
      label: "Net initial yield (Potential)",
    },
    {
      value: "demo2",
      label: "Jiangsu",
    },
  ];

  return (
    <div className="assumptionsParameter">
      <Card bordered={false}>
        <div>
          <h3 className="text-black">Costs & Void Periods</h3>

          <hr className="border-top-0 border-2 mt-0" />
        </div>
        <Row gutter={24} className="align-items-center mb-2">
          <Col xs={6} className="d-flex align-items-center pt-5">
            <h4 className="text-black">Non Recoverable Costs (ongoing)</h4>
          </Col>
          <Col xs={9} className="d-flex">
            <div>
              <h4 className="d-flex justify-content-center text-black">
                Initial/Term
              </h4>
              <div className="d-flex align-items-center">
                <div>
                  <Cascader
                    options={options}
                    placeholder="High"
                    suffixIcon={<CaretDownFilled />}
                  />
                </div>
                <div className="d-flex align-items-center ml-2">
                  <div>
                    <IncDecCounter />
                  </div>
                  <div>
                    <h4 className="mb-0 ml-2 text-black">%</h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={9} className="d-flex align-items-center">
            <div>
              <div className="d-flex justify-conetnt-start align-items-baseline">
                <Checkbox style={{ color: "#6F7173" }}>SYNC</Checkbox>
                <h4 className="pl-5 text-black">Continuing Market</h4>
              </div>
              <div className="d-flex align-items-center ">
                <div>
                  <Cascader
                    options={options}
                    placeholder="High"
                    suffixIcon={<CaretDownFilled />}
                  />
                </div>
                <div className="d-flex align-items-center ml-2">
                  <div>
                    <IncDecCounter />
                  </div>
                  <div>
                    <h4 className="mb-0 ml-2 text-black">%</h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={24} className="align-items-center mb-2">
          <Col xs={6} className="d-flex align-items-center pt-1">
            <h4 className="text-black">Reletting Costs After Lease Expiry</h4>
          </Col>
          <Col xs={9} className="d-flex">
            <div className="col-xs-4 d-flex align-items-center">
              <div>
                <Cascader
                  options={options}
                  placeholder="Medium"
                  suffixIcon={<CaretDownFilled />}
                />
              </div>
              <div className="d-flex align-items-center ml-2">
                <div>
                  <IncDecCounter />
                </div>
                <div>
                  <h4 className="mb-0 ml-2 text-black">EUR/sqm</h4>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={9} className="d-flex">
            <div className="col-xs-4 d-flex align-items-center">
              <div>
                <Cascader
                  options={options}
                  placeholder="Medium"
                  suffixIcon={<CaretDownFilled />}
                />
              </div>
              <div className="d-flex align-items-center ml-2">
                <div>
                  <IncDecCounter />
                </div>
                <div>
                  <h4 className="mb-0 ml-2 text-black">EUR/sqm</h4>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={24} className="align-items-center mb-2">
          <Col xs={6} className="d-flex align-items-center pt-1">
            <h4 className="text-black">Void Periods After Lease Expiry</h4>
          </Col>
          <Col xs={9} className="d-flex">
            <div className=" col-xs-4 d-flex align-items-center">
              <div>
                <Cascader
                  options={options}
                  placeholder="Short"
                  suffixIcon={<CaretDownFilled />}
                />
              </div>
              <div className="d-flex align-items-center ml-2">
                <div>
                  <IncDecCounter />
                </div>
                <div>
                  <h4 className="mb-0 ml-2 text-black">Months</h4>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={9} className="d-flex">
            <div className=" col-xs-4 d-flex align-items-center">
              <div>
                <Cascader
                  options={options}
                  placeholder="Short"
                  suffixIcon={<CaretDownFilled />}
                />
              </div>
              <div className="d-flex align-items-center ml-2">
                <div>
                  <IncDecCounter />
                </div>
                <div>
                  <h4 className="mb-0 ml-2 text-black">Months</h4>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VhlCostsAndVoidPeriods;
