/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-named-as-default-member */
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Tabs,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getBasicDataList } from "../../../../app/features/projectDetails/projectDetail.slice";
import IMAGES from "../../../../assets/images";
import DetailDrawer from "./components/DetailDrawer";
import PortfolioDetails from "./components/PortfolioDetails";
import BasicData from "./components/ProjectDetail/BasicData";
import Esg from "./components/ProjectDetail/Esg";
import Inspection from "./components/ProjectDetail/Inspection";
import InvestmentStrategy from "./components/ProjectDetail/InvestmentStrategy";
import Other from "./components/ProjectDetail/Other";
import PropertyAddress from "./components/ProjectDetail/PropertyAddress";
import PropertyAttributes from "./components/ProjectDetail/PropertyAttributes";
import PropertyRating from "./components/ProjectDetail/PropertyRating";
import Site from "./components/ProjectDetail/Site";
import SearchForm from "./components/SearchForm";
import CreateUpdateAssets from "./CreateUpdateAssets";

export default function Index() {
  const dispatch = useDispatch();
  const param = useParams();
  const location = useLocation();
  const ProjectId = param.projectId;
  const [showDrawer, setShowDrawer] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createUpdateAsset, setCreateUpdateNewAsset] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [searchAsset, setSearchAsset] = useState();
  const [searchValue, setSearchValue] = useState();

  const { Search } = Input;
  const getBasicData = useSelector((state) => state.projectDetails);
  const { user } = useSelector((state) => state.auth);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const CreateUpdateAssetcloseModal = () => {
    setCreateUpdateNewAsset(false);
    localStorage.removeItem("form-1");
  };

  const onSearch = () => {
    const finalValue = {
      search: searchValue,
    };
    setSearchAsset(finalValue);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const [active, setActive] = useState(true);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [showAssets, setShowAssets] = useState(true);

  const onAssetClick = () => {
    setShowAssets(true);
    setShowPortfolio(false);
    setActive(false);
    setActiveKey(1);
  };
  const onPortfolioClick = () => {
    setShowPortfolio(true);
    setShowAssets(false);
    setActive(false);
  };

  const initialHideTableColumns = (
    <Menu
      items={[
        {
          key: "1",
          type: "group",
          label: (
            <div>
              <h4 style={{ color: "#000" }}>Hide table columns</h4>
            </div>
          ),
        },
        {
          key: "2",
          label: <Checkbox>Project ID</Checkbox>,
        },
        {
          key: "3",
          label: <Checkbox>Project Name</Checkbox>,
        },
        {
          key: "4",
          label: <Checkbox>Client Name</Checkbox>,
        },
        {
          key: "5",
          label: <Checkbox>Project Country</Checkbox>,
        },
        {
          key: "6",
          label: <Checkbox>Project State</Checkbox>,
        },
        {
          key: "7",
          label: <Checkbox>Project Address</Checkbox>,
        },
        {
          key: "8",
          label: (
            <div>
              <hr style={{ border: "1px solid #F2F3F5" }} />
              <Button className="reset-dropdown-btn">Reset</Button>
            </div>
          ),
        },
      ]}
    />
  );

  const onImageChange = (event) => {
    // const url = URL.createObjectURL(event.target.files[0])
    message.success(`${event.target.files[0].name} file uploaded successfully`);
  };

  const [hideTableColumns, sethideTableColumns] = useState(
    initialHideTableColumns
  );
  const addNewDropdown = (
    <Menu
      className="projects-add-new-drop-down p-0 py-2"
      items={[
        {
          key: "1",
          type: "group",
          label: "Create New",
          children: [
            {
              key: "1-1",
              label: (
                <div
                  className="d-flex align-items-center change-text-color"
                  role="button"
                  tabIndex="0"
                  onClick={() => setCreateUpdateNewAsset(true)}
                  onKeyDown={() => setCreateUpdateNewAsset(true)}
                >
                  <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
                  <p className="mb-0 text-style">Asset</p>
                </div>
              ),
            },
            // {
            //   key: "1-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //       onClick={() => setCreateUpdateNewAsset(true)}
            //       onKeyDown={() => setCreateUpdateNewAsset(true)}
            //     >
            //       <AiOutlinePlus className="mb-0 change-icon-color" size={18} />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
        {
          key: "2",
          type: "group",
          label: "Upload from Excel",
          children: [
            {
              key: "2-1",
              label: (
                <label
                  htmlFor="xls-upload"
                  className="rentroll-upload cursor-pointer"
                >
                  <div className="d-flex align-items-center change-text-color">
                    <RiFileExcel2Line
                      className="mb-0 change-icon-color"
                      size={18}
                    />
                    <p className="mb-0 text-style">Asset</p>
                  </div>
                  <input
                    type="file"
                    id="xls-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onImageChange}
                  />
                </label>
              ),
            },
            // {
            //   key: "2-2",
            //   label: (
            //     <div
            //       className="d-flex align-items-center change-text-color"
            //       role="button"
            //       tabIndex="0"
            //     >
            //       <RiFileExcel2Line
            //         className="mb-0 change-icon-color"
            //         size={18}
            //       />
            //       <p className="mb-0 text-style">Portfolio</p>
            //     </div>
            //   ),
            // },
          ],
        },
      ]}
    />
  );

  const handleChange = (key) => {
    setActiveKey(key);
  };
  const [open, setOpen] = useState(false);

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  useEffect(() => {
    const finalData = {
      projectId: +ProjectId,
    };
    dispatch(getBasicDataList(finalData));
  }, [dispatch]);

  return (
    <div className="project-details main-project-details">
      <Row gutter={16}>
        <Col
          xs={24}
          md={showDrawer ? 16 : 24}
          lg={showDrawer ? 17 : 24}
          xl={showDrawer ? 19 : 24}
        >
          <Row
            gutter={24}
            className="d-flex justify-content-between align-items-center button-search"
          >
            <Col xl={12} lg={7} md={24} sm={24} xs={24}>
              <div className="d-flex justify-content-left align-items-center">
                {user?.roleId === 1 ||
                  user?.roleId === 3 ||
                  location.pathname.startsWith(`projects/${param.projectId}/project-detail`) ? (
                    <div>
                      <Dropdown
                        overlay={addNewDropdown}
                        trigger={["click"]}
                        direction="vertical"
                      >
                        <Button
                          type="default"
                          className="add-project-btn d-flex justify-content-between align-items-center"
                        >
                          <h5 className="mb-0 ml-0">Add New</h5>
                          <span className="project-btn-span asset-details add-new-btn">
                            <IoIosArrowDown size={30} />
                          </span>
                        </Button>
                      </Dropdown>
                    </div>
                  ) : ""}

                <div className="table-footer-btn d-flex align-items-center ml-2 mt-0">
                  <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center asset-btn"
                      onClick={onAssetClick}
                    >
                      <div
                        className="active-action"
                        style={{
                          backgroundColor: active === true ? "#f2f3f5" : " ",
                        }}
                      >
                        <div className="active-action d-flex align-items-center">
                          <BsFillCircleFill size={10} fill="#2978A0" />
                          <h5 className="mb-0 ml-2">Assets</h5>
                        </div>
                      </div>
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                      onClick={onPortfolioClick}
                    >
                      <div className="active-action d-flex align-items-center">
                        <BsFillCircleFill size={10} fill="#DB5461" />
                        <h5 className="mb-0 ml-2">Portfolio&apos;s</h5>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={7} lg={20} md={24} className="w-100  mt-2 mt-sm-2 mt-xl-0">
              <div className="d-flex searchbar-project">
                <span className="searchbar-icon">
                  <FiSearch size={20} />
                </span>
                <Search
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSearch();
                    }
                  }}
                  onChange={handleSearch}
                  className="mr-2"
                  placeholder="Search"
                />
                <span
                  onClick={() => setIsModalOpen(true)}
                  className="filter-icon-span mr-2 cursor-pointer"
                >
                  <FaFilter />
                </span>
                <Dropdown
                  overlay={hideTableColumns}
                  trigger={["click"]}
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                  <span className="filter-icon-span mr-2 cursor-pointer">
                    <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
                  </span>
                </Dropdown>
                <Tooltip
                  title="show project detail"
                  color="#00215B"
                  key="#00215B"
                >
                  <span
                    className={`drawer-close-btn cursor-pointer ${
                      showDrawer ? "d-none" : ""
                    }`}
                    role="button"
                    tabIndex="0"
                  >
                    <MdOutlineKeyboardArrowLeft
                      size={25}
                      fill="#fff"
                      onClick={() => setShowDrawer(!showDrawer)}
                    />
                  </span>
                </Tooltip>
              </div>
            </Col>
          </Row>
          {showAssets && (
            <Col xs={24} className="rentRoll pl-0">
              <Tabs defaultActiveKey="1" onChange={handleChange}>
                <Tabs.TabPane tab="Basic Data" key="1">
                  {(activeKey === 1 || activeKey === "1") && (
                    <BasicData
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Property Address" key="2">
                  {(activeKey === 2 || activeKey === "2") && (
                    <PropertyAddress
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Property Attributes" key="3">
                  {(activeKey === 3 || activeKey === "3") && (
                    <PropertyAttributes
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Property Rating" key="4">
                  {(activeKey === 4 || activeKey === "4") && (
                    <PropertyRating
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Investment Strategy" key="5">
                  {(activeKey === 5 || activeKey === "5") && (
                    <InvestmentStrategy
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Site" key="6">
                  {(activeKey === 6 || activeKey === "6") && (
                    <Site
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Inspection" key="7">
                  {(activeKey === 7 || activeKey === "7") && (
                    <Inspection
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="ESG" key="8">
                  {(activeKey === 8 || activeKey === "8") && (
                    <Esg
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Other" key="9">
                  {(activeKey === 9 || activeKey === "9") && (
                    <Other
                      sethideTableColumns={sethideTableColumns}
                      activeKey={activeKey}
                      searchAsset={searchAsset}
                      closeModal={closeModal}
                      user={user}
                    />
                  )}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          )}
          {showPortfolio && (
            <Col xs={24} className="rentRoll">
              <PortfolioDetails
                showPortfolio={showPortfolio}
                sethideTableColumns={sethideTableColumns}
                createUpdateAsset={createUpdateAsset}
                setCreateUpdateNewAsset={setCreateUpdateNewAsset}
                onAssetClick={onAssetClick}
              />
            </Col>
          )}
        </Col>
        <Col
          xs={24}
          md={showDrawer ? 8 : 0}
          lg={showDrawer ? 7 : 0}
          xl={showDrawer ? 5 : 0}
          className="bg-white p-3 rounded-sm drawer-height mt-3 mt-md-0"
        >
          <DetailDrawer
            setShowDrawer={setShowDrawer}
            showDrawer={showDrawer}
            onAssetClick={onAssetClick}
            onPortfolioClick={onPortfolioClick}
            getBasicData={getBasicData}
            // visible={openProjectDetailDrawer}
            // open={openProjectDetailDrawer}
            // onClose={closeProjectDetailDrawer}
          />
        </Col>
      </Row>
      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setSearchAsset={setSearchAsset}
      />
      <CreateUpdateAssets
        visible={createUpdateAsset}
        handleCancel={CreateUpdateAssetcloseModal}
      />
    </div>
  );
}
