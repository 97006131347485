/* eslint-disable*/
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Menu,
  Row,
  Form,
  Input,
  Select,
  notification,
  DatePicker,
} from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteUserModal from "../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
import PATH from "../../../../../../utils/path";
// import CreateUpdateAssets from "../../CreateUpdateAssets";
import MergeToPortfolio from "../CreateUpdateAssets/MergeToPortfolio";
import UserList from "../CreateUpdateAssets/UserList";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssetDetail,
  getBasicDataList,
  updateBasicData,
} from "../../../../../../app/features/projectDetails/projectDetail.slice";
import {
  getByTypeDropdown,
  updateVisibleColumns,
} from "../../../../../../app/features/Lookups/lookup.slice";

export default function BasicData({
  sethideTableColumns,
  activeKey,
  searchAsset,
  closeModal,
  user,
}) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const param = useParams();
  const ProjectId = param.projectId;
  const assignedUserId = param.userId;
  const [editingKey, setEditingKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [addMergeUserModal, setAddMergeUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  // const [createUpdateAsset, setCreateUpdateNewAsset] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [disableButton, setdisableButton] = useState(true);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const { getBasicData, isLoading, projectDetailFilters } = useSelector(
    (state) => state.projectDetails
  );
  const { assetType } = useSelector((state) => state.lookUps);
  const [projectDetailFilter, setProjectDetailFilter] = useState({
    Search: "",
    AssetId: "",
    CountryId: "",
    StateId: "",
    CityId: "",
    Street: "",
    RiskClassId: "",
    UpsidePotentialId: "",
    CapexRequirementId: "",
    ExpectedLiquidityId: "",
    ManagementRequirementId: "",
    OwnershipId: "",
    InspectionTypeId: "",
    Selection: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size -
    projectDetailFilter.pagination.size +
    1;

  const nextPageNumber =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size >
    getBasicData?.totalAssets
      ? getBasicData?.totalAssets
      : projectDetailFilter.pagination.pageNo *
        projectDetailFilter.pagination.size;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };
  const closeAddMergeUserModal = () => {
    setAddMergeUserModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [dates, setDates] = useState({
    analysisDate: null,
  });
  const dateChange = (value, field) => {
    setDates({
      ...dates,
      [field]: value?._d ? moment(value?._d).format("YYYY-MM-DD") : null,
    });
  };

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "input" ? (
        <Input type={inputType} />
      ) : inputType === "dropdown" ? (
        <Select placeholder="Select" allowClear>
          {assetType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                    {
                      max: 10,
                      message: "The max lenght of 10 Characters is reached!",
                    },
                  ]
                : inputType === "dropdown"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "text"
                ? [
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                  ]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <DatePicker
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => dateChange(e, dataIndex)}
            className="w-100 rounded-sm"
            defaultValue={
              dates?.[dataIndex] && moment(dates?.[dataIndex], "YYYY-MM-DD")
            }
            dateFormat="YYYY-MM-DD"
            allowClear={false}
          />
        ) : (
          children
        )}
      </td>
    );
  }

  const isEditing = (record) => record.assetId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.assetId);
    setDates({
      analysisDate: record.analysisDate,
    });
  };

  const cancel = () => {
    setEditingKey("");
  };
  useEffect(() => {
    const finalData = {
      type: "assetType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    dispatch(getBasicDataList(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getBasicDataList(finalData));
  };

  const save = async (record) => {
    const row = await form.validateFields();
    const finalData = {
      assetId: record.assetId,
      projectId: +ProjectId,

      assetName: row.assetName,
      assetTypeId: row.assetTypeId,
      analysisDate: dates.analysisDate,
      customIdNo1: +row.customIdNo1 !== 0 ? +row.customIdNo1 : null,
      // customIdNo2: +row.customIdNo2 !== 0 ? +row.customIdNo2 : null,
    };
    dispatch(updateBasicData({ finalData, moveToNext, notifyToaster }));
  };
  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      AssetId: "",
      CountryId: "",
      StateId: "",
      CityId: "",
      Street: "",
      RiskClassId: "",
      UpsidePotentialId: "",
      CapexRequirementId: "",
      ExpectedLiquidityId: "",
      ManagementRequirementId: "",
      OwnershipId: "",
      InspectionTypeId: "",
      Selection: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setProjectDetailFilter(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
    };
    dispatch(getBasicDataList(finalData));
  };
  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteAssetDetail({ finalData, moveToNextDelete, notifyToaster }));
  };

  const basicColumns = [
    {
      id: 1,
      title: "Asset ID",
      dataIndex: "assetId",
      hidden: false,
      // onCell: (record) => ({
      //       record,
      //       inputType: "number",
      //       dataIndex: "assetId",
      //       title: "Asset ID",
      //       editing: isEditing(record),
      //     }),
    },
    {
      id: 2,
      title: "Custom ID #1",
      dataIndex: "customIdNo1",
      hidden: toggle?.includes("customIdNo1"),
      editable: true,
      render: (text) => (text !== null && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "customIdNo1",
        title: "Custom ID #1",
        editing: isEditing(record),
      }),
    },
    // {
    //   id: 3,
    //   title: "Custom ID #2",
    //   dataIndex: "customIdNo2",
    //   hidden: toggle.includes("customIdNo2"),
    //   editable: true,
    //   render: (text) => (text !== null && text) || "N/A",
    //   onCell: (record) => ({
    //     record,
    //     inputType: "number",
    //     dataIndex: "customIdNo2",
    //     title: "Custom ID #2",
    //     editing: isEditing(record),
    //   }),
    // },
    {
      id: 4,
      title: "Asset Type",
      dataIndex: "assetTypeId",
      hidden: toggle.includes("assetTypeId"),
      editable: true,
      render: (text, row) =>
        (text &&
          assetType?.find((item) => item?.lookUpId === row?.assetTypeId)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "assetTypeId",
        title: "Asset Type",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Asset Name",
      dataIndex: "assetName",
      hidden: toggle.includes("assetName"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "assetName",
        title: "Asset Name",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "Analysis Date",
      dataIndex: "analysisDate",
      hidden: toggle.includes("analysisDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => {
        return {
          record,
          inputType: "date",
          dataIndex: "analysisDate",
          title: "analysisDate",
          editing: isEditing(record),
        };
      },
    },
    {
      id: 7,
      title: "Action",
      dataIndex: "Action",
      width: "10%",
      hidden: false,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span
            className="d-flex align-items-center justify-content-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <ImCross size={15} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const changePath = (record) => {
    if (
      PATH.PROJECT_DETAILS.replace(":projectId", +ProjectId) ===
      location.pathname
    ) {
      return navigate(
        `/projects/${ProjectId}/project-detail/${record.assetId}/asset-detail`
      );
    } else {
      return navigate(
        `/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.assetId}/asset-detail`
      );
    }
  };
  const navigateRow = (record) => {
    // const path = record; // to avoid es list error
    return {
      onClick: () => {
        editingKey === "" && changePath(record); // to avoid es lint error
      }, // click row
    };
  };

  const basicDatahideTableColumns = [
    {
      key: 1,
      value: "group",
    },
    {
      key: 2,
      value: "customIdNo1",
      name: "Custom ID #1 ",
      isChecked: false,
    },
    {
      key: 3,
      value: "customIdNo2",
      name: "Custom ID #2 ",
      isChecked: false,
    },
    {
      key: 4,
      value: "assetTypeId",
      name: "Asset Type",
      isChecked: false,
    },
    {
      key: 5,
      value: "assetName",
      name: "Asset Name",
      isChecked: false,
    },
    {
      key: 6,
      value: "analysisDate",
      name: "Analysis Date",
      isChecked: false,
    },
    {
      key: 7,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(basicDatahideTableColumns);
  useEffect(() => {
    const finalData = {
      projectId: +ProjectId,
      assignedToUserId: assignedUserId !== null ? assignedUserId : null,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
      assetId: projectDetailFilters?.assetId
        ? projectDetailFilters.assetId
        : searchAsset?.assetId || "",
      countryId: projectDetailFilters?.countryId
        ? projectDetailFilters.countryId
        : searchAsset?.countryId || "",
      stateId: projectDetailFilters?.stateId
        ? projectDetailFilters.stateId
        : searchAsset?.stateId || "",
      cityId: projectDetailFilters?.cityId
        ? projectDetailFilters.cityId
        : searchAsset?.cityId || "",
      street: projectDetailFilters?.street
        ? projectDetailFilters.street
        : searchAsset?.street || "",
      riskClassId: projectDetailFilters?.riskClassId
        ? projectDetailFilters.riskClassId
        : searchAsset?.riskClassId || "",
      upsidePotentialId: projectDetailFilters?.upsidePotentialId
        ? projectDetailFilters.upsidePotentialId
        : searchAsset?.upsidePotentialId || "",
      capexRequirementId: projectDetailFilters?.capexRequirementId
        ? projectDetailFilters.capexRequirementId
        : searchAsset?.capexRequirementId || "",
      expectedLiquidityId: projectDetailFilters?.expectedLiquidityId
        ? projectDetailFilters.expectedLiquidityId
        : searchAsset?.expectedLiquidityId || "",
      managementRequirementId: projectDetailFilters?.managementRequirementId
        ? projectDetailFilters.managementRequirementId
        : searchAsset?.managementRequirementId || "",
      ownershipId: projectDetailFilters?.ownershipId
        ? projectDetailFilters.ownershipId
        : searchAsset?.ownershipId || "",
      inspectionTypeId: projectDetailFilters?.inspectionTypeId
        ? projectDetailFilters.inspectionTypeId
        : searchAsset?.inspectionTypeId || "",
      selection: projectDetailFilters?.selection
        ? projectDetailFilters.selection
        : searchAsset?.selection || "",
    };
    dispatch(getBasicDataList(finalData));
  }, [dispatch, projectDetailFilter, searchAsset]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    getBasicData?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [getBasicData]);
  useEffect(() => {
    if (getBasicData) {
      const a = getBasicData.visibleColumns?.map((item) => item.moduleId);
      setModuleIdValue(a?.pop());

      const b = getBasicData.visibleColumns?.map((item) => item.moduleTabId);
      setModuleTabIdValue(b?.pop());
    }
  }, [getBasicData]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 7) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 7) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  const formData = getBasicData?.basicData
    ? getBasicData?.basicData.map((item) => {
        const { analysisDate } = item;
        return {
          ...item,
          analysisDate: analysisDate?.split("T")[0],
        };
      })
    : [];

  return (
    <div>
      <div className="basic-data">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            columns={basicColumns.filter((item) => item.hidden === false)}
            dataSource={formData}
            pagination={{
              current: projectDetailFilter?.pagination?.pageNo || 1,
              pageSize: 10,
              total: getBasicData?.totalAssets,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                setProjectDetailFilter({
                  ...projectDetailFilter,
                  pagination: {
                    pageNo: page,
                    size: pageSize,
                  },
                });
              },
            }}
            className="antd-table-striped"
            rowSelection={rowSelection}
            loading={isLoading}
            rowKey={(record) => record.assetId}
            onRow={navigateRow}
            scroll={{
              x: "800px",
              y: "calc(100vh - 406px)",
            }}
          />
        </Form>
      </div>
      {getBasicData?.basicData?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of
              {getBasicData?.totalAssets} entries
            </h5>
          </div>
          <Row gutter={24} className="ml-0">
            <Col
              xs={24}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button>
                  {user?.roleId !== 4 && (
                    <>
                      <Divider type="vertical" />
                      <Button
                        className="btn-action d-flex justify-content-center align-items-center"
                        onClick={() => setAddMergeUserModal(true)}
                        disabled={
                          selectedRowKeys?.length === 0 ? "true" : false
                        }
                      >
                        <div className="active-action d-flex align-items-center">
                          <BsFileEarmarkExcelFill className="mt-1" />
                          <h5 className="mb-0 ml-1 mt-1 ">
                            Merge to portfolio
                          </h5>
                        </div>
                      </Button>
                      {user?.roleId === 3 ||
                      user?.roleId === 4 ||
                      location.pathname.startsWith(
                        `user-management/${param.userId}/user-details/${param.projectId}/project-detail`
                      ) ? (
                        ""
                      ) : (
                        <>
                          <Divider type="vertical" />
                          <Button
                            className="btn-action d-flex justify-content-center align-items-center"
                            onClick={() => setAddUpdateUserModal(true)}
                            disabled={
                              selectedRowKeys?.length === 0 ? "true" : false
                            }
                          >
                            <div className="active-action d-flex align-items-center">
                              <BsFileEarmarkExcelFill className="mt-1" />
                              <h5 className="mb-0 ml-1 mt-1 ">Assign Asset</h5>
                            </div>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {addUpdateUserModal && (
        <UserList
          visible={addUpdateUserModal}
          handleCancel={closeAddUpdateUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {addMergeUserModal && (
        <MergeToPortfolio
          visible={addMergeUserModal}
          handleCancel={closeAddMergeUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      />
      {/* <CreateUpdateAssets
        visible={createUpdateAsset}
        handleCancel={CreateUpdateAssetcloseModal}
      /> */}
    </div>
  );
}

BasicData.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
  searchAsset: PropTypes.node.isRequired,
  closeModal: PropTypes.node.isRequired,
};
