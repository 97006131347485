/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projects from "./project.service";

const initialState = {
  project: null,
  projects: null,
  createProject: null,
  assignProjects: null,
  projectManagers: [],
  archiveProject: null,
  getArchivedProjects: null,
  completeProject: null,
  getCompletedProjects: null,
  createProjectSuccess: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  statusMessage: "",
};

export const createProject = createAsyncThunk(
  "projects/createProject",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.createProject(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      response.isEdit = finalData.projectId !== 0 ? true : false;
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllProjectList = createAsyncThunk(
  "projects/getAllProjectList",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllProjectList(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSingleProject = createAsyncThunk(
  "projects/getSingleProject",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getSingleProject(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllProjectManager = createAsyncThunk(
  "projects/getAllProjectManager",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllProjectManager(finalData);
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async ({ finalData, moveToNextDeleteProject, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.deleteProject(finalData);
      if (response.succeeded) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextDeleteProject) {
          moveToNextDeleteProject();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const archiveProject = createAsyncThunk(
  "projects/archiveProject",
  async ({ finalData, moveToNextArchived, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.archiveProject(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextArchived) {
          moveToNextArchived();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllArchivedProjects = createAsyncThunk(
  "projects/getAllArchivedProjects",
  async (finalData, thunkAPI) => {
    try {
      return await projects.getAllArchivedProjects(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const completeProject = createAsyncThunk(
  "projects/completeProject",
  async ({ finalData, moveToNextArchived, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.completeProject(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNextArchived) {
          moveToNextArchived();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCompletedProjects = createAsyncThunk(
  "projects/getAllCompletedProjects",
  async (finalData, thunkAPI) => {
    debugger
    try {
      return await projects.getAllCompletedProjects(finalData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assignProjectsToUser = createAsyncThunk(
  "projects/assignProjectsToUser",
  async ({ finalData, moveToNext, notifyToaster }, thunkAPI) => {
    try {
      const response = await projects.assignProjectsToUser(finalData);
      if (response.succeeded === true) {
        if (notifyToaster) {
          notifyToaster(response.message, "success");
        }
        if (moveToNext) {
          moveToNext();
        }
      } else {
        notifyToaster(response.message, "error");
      }
      return response;
    } catch (error) {
      notifyToaster(error.message, "error");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.statusMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        const updateProjectList = [...state.projects];
        if (action.payload.isEdit) {
          for (let i = 0; i < updateProjectList.length; i++) {
            if (
              updateProjectList[i].projectId === action.payload.data.projectId
            ) {
              updateProjectList[i] = action.payload.data;
              break;
            }
          }
        } else {
          updateProjectList.unshift(action.payload.data);
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = updateProjectList;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllProjectList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getAllProjectList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getSingleProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.project = action.payload;
      })
      .addCase(getSingleProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const deleteMultipleProjects = state.projects.filter(
          (item) => item.projectId !== action.payload
        );
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = deleteMultipleProjects;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllProjectManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectManager.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projectManagers = action.payload;
      })
      .addCase(getAllProjectManager.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(archiveProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.archiveProject = action.payload;
      })
      .addCase(archiveProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllArchivedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllArchivedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getArchivedProjects = action.payload;
      })
      .addCase(getAllArchivedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(completeProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.completeProject = action.payload;
      })
      .addCase(completeProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })
      .addCase(getAllCompletedProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCompletedProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.getCompletedProjects = action.payload;
      })
      .addCase(getAllCompletedProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      })

      .addCase(assignProjectsToUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignProjectsToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignProjects = action.payload;
      })
      .addCase(assignProjectsToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.statusMessage = action.payload;
      });
  },
});

export const { clearData } = createProjectSlice.actions;

export default createProjectSlice.reducer;
