/* eslint-disable */
import api from "../../../utils/api";

// Create an account for projects
const createAssetDetail = async (projectDetail) => {
  const response = await api.post(`/api/Asset/save`, projectDetail);
  return response.data;
};

// Get All list of projects
const getAllAssetDetailList = async (data) => {
  const response = await api.get(`/api/Asset/getAll`, {
    params: data,
  });
  return response.data.data;
};

// Get All list of projects
const getBasicDataList = async (data) => {
  const response = await api.get(`/api/Asset/getBasicDataByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getPropertyAddressList = async (data) => {
  const response = await api.get(`/api/Asset/getPropertyAddressByProjectId`, {
    params: data,
  });
  return response.data.data;
};
const getPropertyAttributesList = async (data) => {
  const response = await api.get(`/api/Asset/getPropertyAttributeByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getRatingList = async (data) => {
  const response = await api.get(`/api/Asset/getRatingByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getInvestmentStrategyList = async (data) => {
  const response = await api.get(`/api/Asset/getInvestmentStrategyByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getAssetSiteList = async (data) => {
  const response = await api.get(`/api/Asset/getAssetSiteByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getInspectionList = async (data) => {
  const response = await api.get(`/api/Asset/getInspectionByProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getESGList = async (data) => {
  const response = await api.get(`/api/Asset/getESGProjectId`, {
    params: data,
  });
  return response.data.data;
};

const getOtherList = async (data) => {
  const response = await api.get(`/api/Asset/getOtherByProjectId`, {
    params: data,
  });
  return response.data.data;
};

// Get Single projects
const getSingleAssetDetail = async (data) => {
  const response = await api.get("/api/Asset/get", {
    params: data,
  });
  return response.data.data;
};

// Create an account for projects
const updateBasicData = async (basicData) => {
  const response = await api.post(`/api/Asset/updateBasicData`, basicData);
  return response.data;
};

// Create an account for projects
const updatePropertyAddress = async (propertyAddress) => {
  const response = await api.post(
    `/api/Asset/updateAssetAddress`,
    propertyAddress
  );
  return response.data;
};

// Create an account for projects
const updatePropertyAttributes = async (propertyAttributes) => {
  const response = await api.post(
    `/api/Asset/updatePropertyAttribute`,
    propertyAttributes
  );
  return response.data;
};

// Create an account for projects
const updatePropertyRating = async (propertyRating) => {
  const response = await api.post(
    `/api/Asset/updateAssetRating`,
    propertyRating
  );
  return response.data;
};
// Create an account for projects
const updateInvestmentStrategy = async (investmentStrategy) => {
  const response = await api.post(
    `/api/Asset/updateAssestInvestmentStrategy`,
    investmentStrategy
  );
  return response.data;
};
// Create an account for projects
const updateSite = async (siteDetail) => {
  const response = await api.post(`/api/Asset/updateAssestSite`, siteDetail);
  return response.data;
};
// Create an account for projects
const updateInspection = async (inspectionDetail) => {
  const response = await api.post(
    `/api/Asset/updateAssestInspection`,
    inspectionDetail
  );
  return response.data;
};
// Create an account for projects
const updateEsg = async (esgDetail) => {
  const response = await api.post(`/api/Asset/updateAssestESG`, esgDetail);
  return response.data;
};

// Create an account for projects
const updateOther = async (data) => {
  const response = await api.post(`/api/Asset/updateAssetOtherData`, data);
  return response.data;
};

// Delete projects
const deleteAssetDetail = async (data) => {
  const response = await api.delete("/api/Asset/delete", { data: data });
  return response.data;
};
const getProjectDetailById = async (data) => {
  const response = await api.get("/api/Project/getProjectDetailById", {
    params: data,
  });
  return response.data.data;
};
const assetsDetails = {
  createAssetDetail,
  getAllAssetDetailList,
  getSingleAssetDetail,
  deleteAssetDetail,
  updateBasicData,
  updatePropertyAddress,
  updatePropertyAttributes,
  updatePropertyRating,
  updateInvestmentStrategy,
  updateSite,
  updateInspection,
  updateEsg,
  getProjectDetailById,
  updateOther,
  getESGList,
  getInspectionList,
  getAssetSiteList,
  getInvestmentStrategyList,
  getRatingList,
  getPropertyAddressList,
  getBasicDataList,
  getPropertyAttributesList,
  getOtherList,
};

export default assetsDetails;
