import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Switch } from "antd";
import CashflowDataTable from "./component/CashflowDataTable";
import { getCashFlowMonthly } from "../../../../../app/features/cashflow/cashFlow.slice";

function CashFlows() {
  const dispatch = useDispatch();
  const param = useParams();
  const [showTanents, setShowTanents] = useState(false);
  const [active, setActive] = useState("monthly");
  const { cashFlowMonthly, isLoading } = useSelector((state) => state.cashflow);

  useEffect(() => {
    const finalData = {
      assetId: param.assetDetailId,
    };
    dispatch(getCashFlowMonthly(finalData));
  }, [dispatch]);
  return (
    <div className="cashFlowsComponent">
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col flex="none" className="pr-0">
          <div className="cashflows-first-btns">
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-monthly-btn"
                className={`cashflows-monthly-btn ${
                  active === "monthly" ? "active" : ""
                }`}
                onClick={() => setActive("monthly")}
              >
                Monthly
              </Button>
            </span>
            <span>
              <Button
                key="submit"
                type="default"
                // className="cashflows-yearly-btn ml-1"
                className={`cashflows-monthly-btn ml-1 ${
                  active === "yearly" ? "active" : ""
                }`}
                onClick={() => setActive("yearly")}
              >
                Yearly
              </Button>
            </span>
            <span>
              <Switch
                className="ml-3 my-auto"
                onChange={() => setShowTanents(!showTanents)}
              />
            </span>
            <span className="ml-2 my-auto show-tenants">Show Tenants</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <CashflowDataTable
            showTanents={showTanents}
            active={active}
            cashFlowMonthly={cashFlowMonthly}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CashFlows;
