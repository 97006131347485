/* eslint-disable import/no-cycle */
import { Col, Modal, Row, Steps } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import BasicData from "./component/CreateUpdateRentRoll/BasicData";
import CurrentRent from "./component/CreateUpdateRentRoll/CurrentRent";
import GeneralInfo from "./component/CreateUpdateRentRoll/GeneralInfo";
import Indexation from "./component/CreateUpdateRentRoll/Indexation";
import LeaseTerm from "./component/CreateUpdateRentRoll/LeaseTerm";
import MarketRentAndTrueMR from "./component/CreateUpdateRentRoll/MarketRentAndTrueMR";
import NonRecoverableCostsRelatting from "./component/CreateUpdateRentRoll/NonRecoverableCostsRelatting";
import NonRecoverableCostsTerm from "./component/CreateUpdateRentRoll/NonRecoverableCostsTerm";
import Presetting from "./component/CreateUpdateRentRoll/Presetting";
import RelettingCosts from "./component/CreateUpdateRentRoll/RelettingCosts";
import RenewalProbability from "./component/CreateUpdateRentRoll/RenewalProbability";
import VacancyCosts from "./component/CreateUpdateRentRoll/VacancyCosts";
import VoidPeriod from "./component/CreateUpdateRentRoll/VoidPeriod";

export default function CreateUpdateRentRoll(props) {
  const { visible, handleCancel } = props;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const { Step } = Steps;
  const steps = [
    {
      title: "General Info",
      content: "General Info",
    },
    {
      title: "Basic Data",
      content: "Basic Data",
    },
    {
      title: "Current Rent",
      content: "Current Rent",
    },
    {
      title: "Lease Term",
      content: "Lease Term",
    },
    {
      title: "Renewal Probability",
      content: "Renewal Probability",
    },
    {
      title: "Indexation",
      content: "Indexation",
    },
    {
      title: "Presetting",
      content: "Presetting",
    },
    {
      title: "Market Rent & True MR",
      content: "Market Rent & True MR",
    },
    {
      title: "Non Recoverable Costs Term",
      content: "Non Recoverable Costs Term",
    },
    {
      title: "Non Recoverable Costs Relatting",
      content: "Non Recoverable Costs Relatting",
    },
    {
      title: "Void Period",
      content: "Void Period",
    },
    {
      title: "Vacancy Cost",
      content: "Vacancy Cost",
    },
    {
      title: "Reletting Costs",
      content: "Reletting Costs",
    },
  ];
  return (
    <Modal
      visible={visible}
      handleCancel={handleCancel}
      width={800}
      className="create-new-asset-modal"
      style={{
        top: 40,
        // left: 115,
      }}
      footer={[<div className="create-asset-footer" />]}
    >
      <div className="asset-details-stepper">
        <Row gutter={24}>
          <Col xs={10} sm={8} lg={8}>
            <div className="stepper-sidebar">
              <h2 className="text-white mb-0 pt-4 pb-4">Add New Rent Roll</h2>
              <Steps
                current={current}
                direction="vertical"
                progressDot
                className="custom-scroll users-card-scroll pl-2"
              >
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
          </Col>
          <Col xs={14} sm={16} lg={16}>
            <div className="stepper-content">
              <div className="steps-content">
                {steps[current].content === "General Info" && (
                  <GeneralInfo
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Basic Data" && (
                  <BasicData
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Current Rent" && (
                  <CurrentRent
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Lease Term" && (
                  <LeaseTerm
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Renewal Probability" && (
                  <RenewalProbability
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Indexation" && (
                  <Indexation
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Presetting" && (
                  <Presetting
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Market Rent & True MR" && (
                  <MarketRentAndTrueMR
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Non Recoverable Costs Term" && (
                  <NonRecoverableCostsTerm
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content ===
                  "Non Recoverable Costs Relatting" && (
                  <NonRecoverableCostsRelatting
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Void Period" && (
                  <VoidPeriod
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Vacancy Cost" && (
                  <VacancyCosts
                    next={next}
                    prev={prev}
                    handleCancel={handleCancel}
                  />
                )}
                {steps[current].content === "Reletting Costs" && (
                  <RelettingCosts
                    next={next}
                    prev={prev}
                    setCurrent={setCurrent}
                    handleCancel={handleCancel}
                  />
                )}
              </div>

              {/* <div className="d-flex justify-content-between align-items-center mt-5">
                <div>
                  {current > 0 && (
                    <Button
                      style={{
                        margin: "0 8px",
                      }}
                      className="btn-primary"
                      onClick={() => prev()}
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    className="btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  {current < steps.length - 1 && (
                    <Button onClick={() => next()} className="btn-primary">
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button className="btn-primary">Done</Button>
                  )}
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
CreateUpdateRentRoll.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
