import { Card, Checkbox, Col, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VeAcquisitionCostsExit() {
  return (
    <div>
      <Card bordered={false}>
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <h3 className=" text-black">
                Acquistion Costs @Exit
              </h3>
            </div>
            <div>
              <Checkbox style={{ color: "#6F7173" }}>
                SYNC WITH DATE OF ANALYSIS
              </Checkbox>
            </div>
          </div>
          <hr className="mt-0 border-top-0 border-2" />
        </div>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Transfer Tax</h4>
          </Col>
          <Col xs={8}>
            <IncDecCounter />
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">1,404,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Agent Costs</h4>
          </Col>
          <Col xs={8}>
            <IncDecCounter />
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">234,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Notary Costs</h4>
          </Col>
          <Col xs={8}>
            <IncDecCounter />
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">117,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black-50">7.50 %</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">1,755,000 €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-blue">Gross Exit Value</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2  justify-content-center"
          >
            <h4 className="mb-0 text-blue">5,600 €/sqm</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">25,155,000 €</h4>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VeAcquisitionCostsExit;
