import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import PropTypes from "prop-types";
import { Loader1 } from "../../../components/Layout/Loader/FullScreenLoader.jsx";
import {
  createProject,
  getAllProjectManager,
} from "../../../app/features/Projects/project.slice.js";
import { getByTypeDropdown } from "../../../app/features/Lookups/lookup.slice.js";

export default function CreateUpdate(props) {
  const {
    visible,
    handleCancel,
    selectedRowKeys,
    isLoaded,
    setSelectedRowKeys,
    flag,
    setFlag,
    assetDetailUpdate,
    user,
  } = props;
  const { projectType, ProjectStatusType } = useSelector(
    (state) => state.lookUps
  );
  const { projectManagers } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    handleCancel();
    form.resetFields();
    setSelectedRowKeys([]);
    setFlag(false);
    assetDetailUpdate();
  };
  const onFinish = (data) => {
    const finalData = {
      projectId: flag ? selectedRowKeys.projectId : 0,
      projectName: data.projectName,
      projectManagerId: data.projectManager,
      clientName: data.clientName,
      projectTypeId: data.projectType,
      projectStatusId: data.projectStatus,
      description: data.projectDescription,
    };
    dispatch(createProject({ finalData, moveToNext, notifyToaster }));
  };
  useEffect(() => {
    if (selectedRowKeys) {
      form.setFieldsValue({
        projectReference: flag ? selectedRowKeys?.projectId : "",
        projectName: flag ? selectedRowKeys?.projectName : "",
        projectManager: flag ? selectedRowKeys?.projectManagerId : null,
        clientName: flag ? selectedRowKeys?.clientName : "",
        projectType: flag ? selectedRowKeys?.projectTypeId : null,
        projectStatus: flag ? selectedRowKeys?.projectStatusId : null,
        projectDescription: flag ? selectedRowKeys?.description : "",
      });
    }
  }, [selectedRowKeys, flag]);

  const handleCancelModal = () => {
    handleCancel();
    form.resetFields();
    setSelectedRowKeys([]);
    setFlag(false);
  };

  useEffect(() => {
    const finalData = {
      type: "projectType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "ProjectStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
    dispatch(getAllProjectManager());
  }, [dispatch]);

  return (
    <div className="addNewProjectComponent">
      <Modal
        centered
        width={800}
        className="addNewProjectComponent"
        open={visible}
        handleCancel={handleCancelModal}
        footer={false}
      >
        <div className="add-user-management-modal">
          <h3 className="mb-0 modal-heading">
            {flag ? "Update Project" : "Create New Project"}
          </h3>
          <Form
            name="basic"
            className="user-modal"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            form={form}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Project Reference"
                  name="projectReference"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Project Reference!",
                    },
                  ]}
                >
                  <Input placeholder="Enter project Reference" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Project Name"
                  name="projectName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Project Name!",
                    },
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                    // {
                    //   pattern: "^([^0-9]*)$",
                    //   message: "Project Name does not accept numbers"
                    //  }
                  ]}
                >
                  <Input placeholder="Enter project name" disabled={isLoaded} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Project Manager"
                  name="projectManager"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Project Manager!",
                    },
                  ]}
                >
                  <Select placeholder="Select" allowClear disabled={isLoaded || user?.roleId === 3}>
                    {projectManagers?.map((item) => (
                      <Select.Option key={item.userId} value={item.userId}>
                        {item.projectManager}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  label="Client Name"
                  name="clientName"
                  rules={[
                    {
                      message: "Please enter your Client Name!",
                    },
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                    // {
                    //   pattern: "^([^0-9]*)$",
                    //   message: "Client Name does not accept numbers"
                    //  }
                  ]}
                >
                  <Input placeholder="Enter client name" disabled={isLoaded} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item name="projectType" label="Project Type">
                  <Select placeholder="Select" allowClear disabled={isLoaded}>
                    {projectType?.map((item) => (
                      <Select.Option key={item.lookUpId} value={item.lookUpId}>
                        {item.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="projectStatus"
                  label="Project Status"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Project Status!",
                    },
                  ]}
                  disabled={isLoaded}
                >
                  <Select placeholder="Select" allowClear disabled={isLoaded}>
                    {ProjectStatusType?.map((item) => (
                      <Select.Option key={item.lookUpId} value={item.lookUpId}>
                        {item.text}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="projectDescription"
                  label="Project Description/Notes"
                  rules={[
                    {
                      max: 250,
                      message: "The max lenght of 250 Characters is reached!",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter project notes"
                    className="custom-scroll"
                    disabled={isLoaded}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                xs={24}
                className="align-items-center d-flex justify-content-end mt-2"
              >
                <Button className="btn-cancel" onClick={handleCancelModal}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="default"
                  htmlType="submit"
                  className="btn-primary"
                  disabled={isLoaded}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    {flag ? (
                      <div className="mr-2">Update</div>
                    ) : (
                      <div className="mr-2">Save</div>
                    )}
                    {isLoaded && <Loader1 />}
                  </div>
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
CreateUpdate.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  selectedRowKeys: PropTypes.node.isRequired,
  isLoaded: PropTypes.node.isRequired,
  setSelectedRowKeys: PropTypes.node.isRequired,
  setFlag: PropTypes.node.isRequired,
  flag: PropTypes.node.isRequired,
  assetDetailUpdate: PropTypes.node.isRequired,
  user: PropTypes.node.isRequired,
};
