import { Button, Card, Checkbox, Col, Row, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import {
  getAssetAcquisitionCostExit,
  saveAssetAcquisitionCostExit,
} from "../../../../../../../../app/features/calculation/calculationAsset.slice";

function VeAcquisitionCostsExit() {
  const dispatch = useDispatch();

  const param = useParams();

  const { getAssetAcquisitionCostExitData } = useSelector(
    (state) => state.calculation
  );

  const [acquisitionCostExitObj, setAcquisitionCostExitObj] = useState(null);

  useEffect(() => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetAcquisitionCostExit(finalData));
  }, []);

  useEffect(() => {
    if (getAssetAcquisitionCostExitData == null) {
      setAcquisitionCostExitObj(null);
    } else {
      setAcquisitionCostExitObj(getAssetAcquisitionCostExitData);
    }
  }, [getAssetAcquisitionCostExitData]);

  const updateNum = (e, key) => {
    setAcquisitionCostExitObj(() => ({
      ...acquisitionCostExitObj,
      [key]: e,
    }));
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(getAssetAcquisitionCostExit(finalData));
  };

  const handleAcquisitionCostExit = () => {
    const finalData = {
      assetId: param.assetDetailId,
      transferTax: acquisitionCostExitObj.transferTax
        ? acquisitionCostExitObj.transferTax
        : 0,
      agentCosts: acquisitionCostExitObj.agentCosts
        ? acquisitionCostExitObj.agentCosts
        : 0,
      notaryCosts: acquisitionCostExitObj.notaryCosts
        ? acquisitionCostExitObj.notaryCosts
        : 0,
      isAdjustAutomatically: acquisitionCostExitObj.isAdjustAutomatically,
    };
    dispatch(
      saveAssetAcquisitionCostExit({ finalData, moveToNext, notifyToaster })
    );
  };

  return (
    <div>
      <Card bordered={false}>
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <h3 className=" text-black">Acquistion Costs @Exit</h3>
            </div>
            <div>
              <Checkbox
                checked={acquisitionCostExitObj?.isAdjustAutomatically}
                onChange={(e) =>
                  updateNum(e.target.checked, "isAdjustAutomatically")
                }
                style={{ color: "#6F7173" }}
              >
                SYNC WITH DATE OF ANALYSIS
              </Checkbox>
            </div>
          </div>
          <hr className="mt-0 border-top-0 border-2" />
        </div>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Transfer Tax</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostExitObj && (
              <IncDecCounter
                count={acquisitionCostExitObj?.transferTax || 0}
                setNumValue={(e) => updateNum(e, "transferTax")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">1,404,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Agent Costs</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostExitObj && (
              <IncDecCounter
                count={acquisitionCostExitObj?.agentCosts || 0}
                setNumValue={(e) => updateNum(e, "agentCosts")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">234,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black-50">Notary Costs</h4>
          </Col>
          <Col xs={8}>
            {acquisitionCostExitObj && (
              <IncDecCounter
                count={acquisitionCostExitObj?.notaryCosts || 0}
                setNumValue={(e) => updateNum(e, "notaryCosts")}
                className="m-0"
              />
            )}
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black-50">117,000 €</h4>
          </Col>
        </Row>
        <Row gutter={24} className="pt-2 mb-2 d-flex justify-content-between">
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-black">Total acuisition Costs</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-center"
          >
            <h4 className="mb-0 text-black">7.50 %</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-black">1,755,000 €</h4>
          </Col>
        </Row>
        <Row
          gutter={24}
          className="d-flex justify-content-between"
          style={{ "background-color": "#F2F4F7", borderRadius: "5px" }}
        >
          <Col xs={8} className="align-items-center my-2">
            <h4 className="mb-0 text-blue">Gross Exit Value</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2  justify-content-center"
          >
            <h4 className="mb-0 text-blue">5,600 €/sqm</h4>
          </Col>
          <Col
            xs={8}
            className="d-flex align-items-center my-2 justify-content-end"
          >
            <h4 className="mb-0 text-blue">25,155,000 €</h4>
          </Col>
        </Row>
        <div className="py-2 mt-3 d-flex ml-auto justify-content-end">
          <Button
            onClick={handleAcquisitionCostExit}
            className="btn-primary float-right"
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default VeAcquisitionCostsExit;
