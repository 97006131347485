import {
  Button,
  Card,
  Cascader,
  Col,
  Input,
  InputNumber,
  Row,
  Slider,
} from "antd";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CaretDownFilled } from "@ant-design/icons";
import { useState } from "react";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

const options = [
  {
    value: "demo",
    label: "Net initial yield (Potential)",
  },
  {
    value: "demo2",
    label: "Jiangsu",
  },
];

function IntegerStep() {
  const [num, setNum] = useState(0);

  const incNum = () => {
    if (num >= 0) {
      setNum(Number(num) + 1);
    }
  };

  const decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };

  const onChange = (newValue) => {
    setNum(newValue);
  };

  return (
    <div className="slider-wrapper">
      <div className="d-flex align-items-center area-income-counter-field justify-content-between p-0">
        <Button
          icon={<IoIosArrowBack />}
          onClick={decNum}
          className="dec-num-btn"
        />
        <InputNumber min={1} max={80} value={num} onChange={onChange} />
        Sqm
        <Button
          icon={<IoIosArrowForward />}
          onClick={incNum}
          className="inc-num-btn"
        />
      </div>
      <Slider
        className="m-0"
        min={1}
        max={80}
        onChange={onChange}
        value={typeof num === "number" ? num : 0}
      />
    </div>
  );
}

function VhlAreaAndIncome() {
  return (
    <div className="areaAndIncome">
      <Card bordered={false}>
        <h3 className="text-black">Area & Income</h3>
        <hr className="mt-0 border-top-0 border-2" />
        <Row
          className="pt-2 d-flex align-items-center justify-content-between"
          gutter={10}
        >
          <Col className="mt-3">
            <h4 className="text-black">Total lettable Area</h4>
            <IntegerStep />
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Current Rent</h4>
            <div className="d-flex align-items-center">
              <Cascader
                options={options}
                placeholder="EUR / Sqm"
                suffixIcon={<CaretDownFilled />}
              />
              <div className="pl-2">
                <Input
                  style={{
                    width: 70,
                    height: 48,
                    border: "1px solid #B7B7B7",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">EUR / sqm</h4>
            <div className="d-flex align-items-center justify-content-end">
              <p className="mb-0 pl-2 borderless-field">18.00</p>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">EUR p.a.</h4>
            <div className="d-flex align-items-center justify-content-end">
              <p className="mb-0 pl-2 borderless-field">1,157,328</p>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Walt Y</h4>
            <div>
              <IncDecCounter />
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Current Rent Indexation</h4>
            <div className="d-flex align-items-center">
              <div>
                <Cascader
                  options={options}
                  placeholder="Yearly 100%"
                  style={{ width: "230px" }}
                  suffixIcon={<CaretDownFilled />}
                />
              </div>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Occupancy</h4>
            <IntegerStep />
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Market Rent</h4>
            <div className="d-flex align-items-center">
              <Cascader
                options={options}
                placeholder="EUR / Sqm"
                suffixIcon={<CaretDownFilled />}
              />
              <div className="pl-2">
                <Input
                  style={{
                    width: 70,
                    height: 48,
                    border: "1px solid #B7B7B7",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">EUR / sqm</h4>
            <div className="d-flex align-items-center justify-content-end">
              <p className="mb-0 pl-2 borderless-field">18.00</p>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">EUR p.a.</h4>
            <div className="d-flex align-items-center justify-content-end">
              <p className="mb-0 pl-2 borderless-field">1,157,328</p>
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Market lease (Y)</h4>
            <div>
              <IncDecCounter />
            </div>
          </Col>
          <Col className="mt-3">
            <h4 className="text-black">Renewal Probability</h4>
            <div className="d-flex align-items-center">
              <div>
                <IncDecCounter />
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VhlAreaAndIncome;
