/* eslint-disable */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Button,
  Input,
  Dropdown,
  Menu,
  Checkbox,
  Row,
  Col,
  Divider,
  // Tooltip,
  Typography,
  notification,
  Form,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FaFilter, FaRegCopy, FaShareSquare } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
// import { CheckCircleFilled } from "@ant-design/icons";
import {
  BsFileEarmarkExcelFill,
  BsFillEyeFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { MdArchive, MdDelete, MdModeEdit } from "react-icons/md";
import DataTable from "../../../components/Layout/Table/DataTable";
import IMAGES from "../../../assets/images";
import PATH from "../../../utils/path";
import CreateUpdate from "./CreateUpdate";
import SearchForm from "./component/SearchForm";
import UserList from "./component/UserList";
import DeleteUserModal from "../../../components/Layout/DeleteModel/DeleteUserModal";
import CompleteProjects from "./component/Projects/CompleteProjects";
import ArchiveProjects from "./component/Projects/ArchiveProjects";
import {
  archiveProject,
  completeProject,
  deleteProject,
  getAllArchivedProjects,
  getAllCompletedProjects,
  getAllProjectList,
} from "../../../app/features/Projects/project.slice";
import { CheckCircleFilled } from "@ant-design/icons";

const { Search } = Input;

function Index() {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [cheacky, setCheacky] = useState([]);
  const navigate = useNavigate();
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [addUpdateNewProjectModal, setAddUpdateNewProjectModal] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [active, setActive] = useState(true);
  const [showNewProject, setShowNewProject] = useState(true);
  const [showCompletedProject, setShowCompletedProject] = useState(false);
  const [showArchiveProject, setShowArchiveProject] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [searchProjectManagerIdValue, setSearchProjectManagerIdValue] =
    useState();
  const [searchProjectTypeIdValue, setSearchProjectTypeIdValue] = useState();
  const [searchProjectStatusIdValue, setSearchProjectStatusIdValue] =
    useState();
  const { projects, isLoading } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const [projectFilter, setProjectFilter] = useState({
    search: "",
    searchProjectManagerIdValue: null,
    searchProjectTypeIdValue: null,
    searchProjectStatusIdValue: null,
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const onNewProjects = () => {
    setShowNewProject(true);
    setShowCompletedProject(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onCompletedProjects = () => {
    setShowCompletedProject(true);
    setShowNewProject(false);
    setShowArchiveProject(false);
    setActive(false);
  };
  const onArchiveProjects = () => {
    setShowArchiveProject(true);
    setShowCompletedProject(false);
    setShowNewProject(false);
  };

  const closeAddUpdateNewProjectModal = () => {
    setAddUpdateNewProjectModal(false);
  };

  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    form.resetFields();
    setSearchProjectStatusIdValue(null);
    setSearchProjectTypeIdValue(null);
    setSearchProjectManagerIdValue(null);
    setSearchValue("");
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const pageNumbers =
    projectFilter.pagination.pageNo * projectFilter.pagination.size -
    projectFilter.pagination.size +
    1;

  const nextPageNumber =
    projectFilter.pagination.pageNo * projectFilter.pagination.size >
    projects?.[0]?.totalRecords
      ? projects?.[0]?.totalRecords
      : projectFilter.pagination.pageNo * projectFilter.pagination.size;

  // const handleSearch = (e) => {
  //   setSearchValue(e.target.value);
  // };
  // const handleSearchProjectManagerIdValue = (e) => {
  //   setSearchProjectManagerIdValue(e);
  // };
  // const handleSearchProjectTypeIdValue = (e) => {
  //   setSearchProjectTypeIdValue(e);
  // };
  // const handleSearchProjectStatusIdValue = (e) => {
  //   setSearchProjectStatusIdValue(e);
  // };
  useEffect(() => {
    if (
      searchValue?.length > 3 ||
      searchValue?.length == 0 ||
      searchValue?.length == "" ||
      searchProjectManagerIdValue ||
      searchProjectStatusIdValue ||
      searchProjectTypeIdValue ||
      projectFilter
    )
      if (showNewProject) {
        dispatch(
          getAllProjectList({
            search: searchValue ? searchValue : "",
            projectManagerId: searchProjectManagerIdValue
              ? searchProjectManagerIdValue
              : null,
            ProjectStatusId: searchProjectStatusIdValue
              ? searchProjectStatusIdValue
              : null,
            ProjectTypeId: searchProjectTypeIdValue
              ? searchProjectTypeIdValue
              : null,
            pageNo: projectFilter.pagination.pageNo,
            size: projectFilter.pagination.size,
          })
        );
      } else if (showCompletedProject) {
        dispatch(
          getAllCompletedProjects({
            search: searchValue ? searchValue : "",
            projectManagerId: searchProjectManagerIdValue
              ? searchProjectManagerIdValue
              : null,
            ProjectStatusId: searchProjectStatusIdValue
              ? searchProjectStatusIdValue
              : null,
            ProjectTypeId: searchProjectTypeIdValue
              ? searchProjectTypeIdValue
              : null,
            pageNo: projectFilter.pagination.pageNo,
            size: projectFilter.pagination.size,
          })
        );
      } else if (showArchiveProject) {
        dispatch(
          getAllArchivedProjects({
            search: searchValue ? searchValue : "",
            projectManagerId: searchProjectManagerIdValue
              ? searchProjectManagerIdValue
              : null,
            ProjectStatusId: searchProjectStatusIdValue
              ? searchProjectStatusIdValue
              : null,
            ProjectTypeId: searchProjectTypeIdValue
              ? searchProjectTypeIdValue
              : null,
            pageNo: projectFilter.pagination.pageNo,
            size: projectFilter.pagination.size,
          })
        );
      }
  }, [
    dispatch,
    searchValue,
    searchProjectManagerIdValue,
    searchProjectStatusIdValue,
    searchProjectTypeIdValue,
    projectFilter,
    showNewProject,
    showArchiveProject,
    showCompletedProject,
  ]);
  // const onSearch = () => {
  //   dispatch(
  //     getAllProjectList({
  //       search: searchValue ? searchValue : "",
  //       projectManagerId: searchProjectManagerIdValue
  //         ? searchProjectManagerIdValue
  //         : null,
  //       ProjectStatusId: searchProjectStatusIdValue
  //         ? searchProjectStatusIdValue
  //         : null,
  //       ProjectTypeId: searchProjectTypeIdValue
  //         ? searchProjectTypeIdValue
  //         : null,
  //       pageNo: projectFilter.pagination.pageNo,
  //       size: projectFilter.pagination.size,
  //     })
  //   );
  // };

  const initialColState = [
    {
      id: 0,
      hidden: false,
      title: "Project Reference",
      dataIndex: "projectId",
    },
    {
      id: 1,
      hidden: false,
      title: "Project Name",
      dataIndex: "projectName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 1,
      hidden:  user?.roleId === 3 ? true : false,
      title: "Project Manager",
      dataIndex: "projectManager",
      render: (text) => ( text ? text : null),
    },
    {
      id: 2,
      hidden: false,
      title: "Client Name",
      dataIndex: "clientName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 3,
      hidden: false,
      title: "Project Type",
      dataIndex: "projectType",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Project Status",
      dataIndex: "projectStatus",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 8,
      hidden: false,
      title: "Notes",
      dataIndex: "description",
      render: (text) => (
        <Text
          style={{
            width: 200,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text ? text : "N/A"}
        </Text>
      ),
    },
    {
      id: 7,
      hidden: false,
      title: "Actions",
      dataIndex: "description",
      render: (text, row) => (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.projectId
                            )
                          )
                        }
                        onKeyDown={() =>
                          navigate(
                            PATH.PROJECT_DETAILS.replace(
                              ":projectId",
                              row.projectId
                            )
                          )
                        }
                      >
                        <BsFillEyeFill />
                        <h5 className="mb-0 ml-3">View project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "10",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => completeProjectFunction(row)}
                        onKeyDown={() => completeProjectFunction(row)}
                      >
                        <CheckCircleFilled />
                        <h5 className="mb-0 ml-3">Complete Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "4",
                    label: (
                      <div
                        className="d-flex justify-content-left align-items-center table-dropdown"
                        role="button"
                        tabIndex="0"
                        onClick={() => archiveProjectFunction(row)}
                        onKeyDown={() => archiveProjectFunction(row)}
                      >
                        <MdArchive />
                        <h5 className="mb-0 ml-3">Archive Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "5",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaShareSquare />
                        <h5 className="mb-0 ml-3">Export Project</h5>
                      </div>
                    ),
                  },
                  {
                    key: "6",
                    label: (
                      <div className="d-flex justify-content-left align-items-center table-dropdown">
                        <FaRegCopy />
                        <h5 className="mb-0 ml-3">Clone Project</h5>
                      </div>
                    ),
                  },
                ]}
              />
            }
            trigger={["click"]}
          >
            <BsThreeDotsVertical />
          </Dropdown>
        </div>
      ),
    },
  ];

  // const [columns, setColumns] = useState(initialColState);

  // const handleReset = () => {
  //   setColumns(initialColState);
  // };

  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //   } else {
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };

  // Hide Table Column Dropdown

  // const initialHideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="projectName"
  //             name="Project Name"
  //             id={1}
  //             // checked={cheacky.includes("projectName")}
  //             onChange={handleChange}
  //           >
  //             Project Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="projectManager"
  //             name="Project Manager"
  //             id={2}
  //             // checked={cheacky.includes("projectManager")}
  //             onChange={handleChange}
  //           >
  //             Project Manager
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="clientName"
  //             name="Client Name"
  //             id={3}
  //             // checked={cheacky.includes("clientName")}
  //             onChange={handleChange}
  //           >
  //             Client Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="projectType"
  //             name="Project Type"
  //             id={4}
  //             // checked={cheacky.includes("projectType")}
  //             onChange={handleChange}
  //           >
  //             Project Type
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <Checkbox
  //             value="projectStatus"
  //             name="Project Status"
  //             id={5}
  //             // checked={cheacky.includes("projectStatus")}
  //             onChange={handleChange}
  //           >
  //             Project Status
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 8,
  //         label: (
  //           <Checkbox
  //             value="description"
  //             name="Notes"
  //             id={5}
  //             // checked={cheacky.includes("description")}
  //             onChange={handleChange}
  //           >
  //             Notes
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: "11",
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={handleReset}>
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // const [hideTableColumns, sethideTableColumns] = useState(
  //   initialHideTableColumns
  // );

  const navigateRow = (record) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(PATH.PROJECT_DETAILS.replace(":projectId", path.projectId)); // to avoid es lint error
      }, // click row
    };
  };

  // const [open, setOpen] = useState(false);

  // const handleOpenChange = (flag) => {
  //   setOpen(flag);
  // };

  // Api's
  // useEffect(() => {
  //   dispatch(getAllProjectList(projectFilter));
  // }, [dispatch, projectFilter]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDeleteProject = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setProjectFilter(filters);
    setSelectedRowKeys([]);
  };
  const deleteProjects = () => {
    let finalData = [];
    finalData = selectedRowKeys.map((item) => item.projectId);
    dispatch(
      deleteProject({ finalData, moveToNextDeleteProject, notifyToaster })
    );
  };
  const moveToNextArchived = () => {
    dispatch(getAllProjectList(projectFilter));
  };
  const archiveProjectFunction = (row) => {
    const finalData = {
      projectId: row.projectId,
      isArchived: true,
    };
    dispatch(archiveProject({ finalData, moveToNextArchived, notifyToaster }));
  };
  const completeProjectFunction = (row) => {
    const finalData = {
      projectId: row.projectId,
    };
    dispatch(completeProject({ finalData, moveToNextArchived, notifyToaster }));
  };
  const assetDetailUpdate = () => {
    dispatch(getAllProjectList(projectFilter));
  };
  return (
    <div className="project" key="Projects">
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col sm={24} md={7} className="d-flex">
          {user && user?.roleId && user.roleId !== 4 && (
            <div>
              <Button
                type="default"
                className="add-project-btn d-flex justify-content-between align-items-center new-user"
                onClick={() => setAddUpdateNewProjectModal(true)}
              >
                <span className="project-btn-span">
                  <AiOutlinePlus size={30} />
                </span>
                <h5 className="mb-0 ml-3">Add New Project</h5>
              </Button>
            </div>
          )}
          <div>
            <div className="table-footer-btn d-flex align-items-center ml-2 mt-0">
              <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div">
                <Button
                  className="btn-action d-flex justify-content-center align-items-center asset-btn"
                  onClick={onNewProjects}
                >
                  <div
                    className="active-action"
                    style={{
                      backgroundColor: active === true ? "#f2f3f5" : " ",
                    }}
                  >
                    <div
                      className="active-action d-flex align-items-center"
                      style={{
                        Color: active === true ? "#000" : "#eee",
                      }}
                    >
                      {/* <BsFillCircleFill size={10} fill="#2978A0" /> */}
                      <h4 className="mb-0 ml-2 text-black">New</h4>
                    </div>
                  </div>
                </Button>
                <Divider type="vertical" />
                <Button
                  className="btn-action d-flex justify-content-center align-items-center portofolio-btn"
                  onClick={onCompletedProjects}
                >
                  <div className="active-action d-flex align-items-center">
                    {/* <BsFillCircleFill size={10} fill="#DB5461" /> */}
                    <h4 className="mb-0 ml-2 text-black">Completed</h4>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={7} className="w-100 mt-2 mt-sm-2 mt-md-0">
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              onChange={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              className="mr-2"
              placeholder="Search a project"

              // onClick={() => setIsModalOpen(true)}
            />
            <span
              onClick={() => setIsModalOpen(true)}
              className="filter-icon-span mr-2 cursor-pointer"
            >
              <FaFilter />
            </span>
            <span
              className="filter-icon-span mr-2 cursor-pointer"
              onClick={() => {
                onArchiveProjects();
              }}
              role="button"
              tabIndex="0"
              onKeyDown={() => {
                onArchiveProjects();
              }}
            >
              <img src={IMAGES.ARCHIVE_ICON} alt="Table Icon" />
            </span>
            {/* <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
          </div>
        </Col>
      </Row>
      {/* Data Table */}
      {showNewProject && (
        <>
          <div className="mt-3">
            <DataTable
              columns={initialColState.filter((item) => item.hidden === false)}
              dataSource={projects}
              pagination={{
                current: projectFilter?.pagination?.pageNo || 1,
                pageSize: 10,
                total: projects?.[0]?.totalRecords,
                showSizeChanger: false,
                disabled: false,
                onChange: (page, pageSize) => {
                  setProjectFilter({
                    ...projectFilter,
                    pagination: {
                      pageNo: page,
                      size: pageSize,
                    },
                  });
                },
              }}
              className="antd-table-striped"
              onRow={navigateRow}
              loading={isLoading}
              rowSelection={rowSelection}
              rowKey={(record) => record}
              scroll={{
                x: "1100px",
                y: "calc(100vh - 372px)",
              }}
            />
          </div>
          {projects?.length > 0 && (
            <>
              <div className="table-count">
                <h5 className="table-count-content">
                  Showing {pageNumbers} to {nextPageNumber} of{" "}
                  {projects?.[0]?.totalRecords} entries
                </h5>
              </div>
              <Row gutter={24} className="ml-0">
                <Col
                  xs={24}
                  style={{
                    padding: 0,
                  }}
                >
                  <div className="table-footer-btn d-flex align-items-center">
                    <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                      <Button
                        className="btn-action d-flex justify-content-center align-items-center text-danger"
                        onClick={() => setDeleteUserModal(true)}
                        disabled={
                          selectedRowKeys?.length === 0 ? "true" : false
                        }
                      >
                        <div className="active-action d-flex align-items-center">
                          <MdDelete />
                          <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                        </div>
                      </Button>

                      {user?.roleId === 1 ||
                        location.pathname.startsWith(`project`) ||
                        user?.roleId === 3 ||
                        user?.roleId === 4 ? (
                          <>
                            {selectedRowKeys?.length > 1 ? (
                              ""
                            ) : (
                              <>
                                <Divider type="vertical" />
                                <Button
                                  className="btn-action d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    setFlag(true);
                                    setAddUpdateNewProjectModal(true);
                                  }}
                                  disabled={
                                    selectedRowKeys?.length === 0
                                      ? "true"
                                      : false
                                  }
                                >
                                  <div className="active-action d-flex align-items-center">
                                    <MdModeEdit className="mt-1" />
                                    <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                                  </div>
                                </Button>
                              </>
                            )}
                          </>
                        ) : ""}
                      {user?.roleId === 3 ||
                      user?.roleId === 4 ||
                      location.pathname.startsWith("user-management") ? (
                        ""
                      ) : (
                        <>
                          <Divider type="vertical" />
                          <Button
                            className="btn-action d-flex justify-content-center align-items-center"
                            onClick={() => setAddUpdateUserModal(true)}
                            disabled={
                              selectedRowKeys?.length === 0 ? "true" : false
                            }
                          >
                            <div className="active-action d-flex align-items-center">
                              <BsFileEarmarkExcelFill className="mt-1" />
                              <h5 className="mb-0 ml-1 mt-1 ">
                                Assign Project
                              </h5>
                            </div>
                          </Button>
                        </>
                      )}
                    </div>
                    {/* <Button className="btn-cancel py-2">
              <h5 className="mb-0">Cancel</h5>
            </Button> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {showCompletedProject && (
        <CompleteProjects
          showCompletedProject={showCompletedProject}
          // sethideTableColumns={sethideTableColumns}
          // columns={columns}
          setShowNewProject={setShowNewProject}
          setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
          setActive={setActive}
          projectFilter={projectFilter}
          searchProjectTypeIdValue={searchProjectTypeIdValue}
          searchProjectStatusIdValue={searchProjectStatusIdValue}
          searchProjectManagerIdValue={searchProjectManagerIdValue}
          searchValue={searchValue}
        />
      )}
      {showArchiveProject && (
        <ArchiveProjects
          showArchiveProject={showArchiveProject}
          // sethideTableColumns={sethideTableColumns}
          // columns={columns}
          setShowNewProject={setShowNewProject}
          setAddUpdateNewProjectModal={setAddUpdateNewProjectModal}
          setActive={setActive}
        />
      )}

      {addUpdateUserModal && (
        <UserList
          visible={addUpdateUserModal}
          handleCancel={closeAddUpdateUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setIsModalOpen={setIsModalOpen}
        setSearchValue={setSearchValue}
        form={form}
        setSearchProjectManagerIdValue={setSearchProjectManagerIdValue}
        setSearchProjectTypeIdValue={setSearchProjectTypeIdValue}
        setSearchProjectStatusIdValue={setSearchProjectStatusIdValue}
      />
      <CreateUpdate
        visible={addUpdateNewProjectModal}
        handleCancel={closeAddUpdateNewProjectModal}
        selectedRowKeys={selectedRowKeys?.[0]}
        isLoaded={isLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        modalHeading="Create New Project"
        flag={flag}
        setFlag={setFlag}
        assetDetailUpdate={assetDetailUpdate}
        user={user}
      />
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Project"
        onSubmit={deleteProjects}
        isLoaded={isLoading}
      />
    </div>
  );
}

export default Index;
