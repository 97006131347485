import { CaretDownOutlined } from "@ant-design/icons";
import { Card, Cascader, Col, Row, Table } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VhlFinancing() {
  const options = [
    {
      value: "demo",
      label: "Net initial yield (Potential)",
    },
    {
      value: "demo2",
      label: "demo",
    },
  ];

  const secondColumns = [
    {
      title: " ",
      dataIndex: "assetID",
    },
    {
      title: " ",
      dataIndex: "iD",
    },
    {
      title: "",
      dataIndex: "cluster",
    },
    {
      title: " ",
      dataIndex: "analysisData",
    },
  ];
  const secondDataSource = [
    {
      key: "1",
      assetID: "Unleveraged IRR",
      iD: "5.50 %",
      cluster: "WACC",
      analysisData: "5.50 %",
    },
    {
      key: "1",
      assetID: "leveraged IRR",
      iD: "12.50 %",
      cluster: "Equity Multiple",
      analysisData: "3.50 %",
    },
    {
      key: "1",
      assetID: "Cash on Cash (year 1)",
      iD: "3.50 %",
      cluster: "Cash profit (Unleveraged)",
      analysisData: "3.50 %",
    },
    {
      key: "1",
      assetID: "Cash on Cash (average)",
      iD: "5.50 %",
      cluster: "Cash Profit (leverage)",
      analysisData: "5.50 %",
    },
  ];

  return (
    <div className="financingComponent">
      <Card bordered={false}>
        <div>
          <h3 className="text-black">Financing</h3>
          <hr className="border-top-0 border-2" />
        </div>
        <Row gutter={24} className="pt-1">
          <Col xs={24} xl={12}>
            <h4 className="text-black">Type</h4>
            <Cascader
              suffixIcon={<CaretDownOutlined />}
              options={options}
              placeholder="Net initial yield (Potential)"
              className="calculation-cascader mb-2 w-100"
            />
          </Col>
          <Col xs={24} xl={12}>
            <h4 className="text-black">Amount</h4>
            <Cascader
              suffixIcon={<CaretDownOutlined />}
              placeholder="6,500,000 €"
              className="calculation-cascader mb-2 w-100"
            />
          </Col>
        </Row>
        <Row gutter={18}>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">LTV %</h5>
            <IncDecCounter className="m-0" />
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Credit Period (y)</h5>
            <IncDecCounter className="m-0" />
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Interest Rate %</h5>
            <IncDecCounter className="m-0" />
          </Col>
          <Col
            xs={24}
            xl={12}
            className="d-flex align-items-center justify-content-between my-1"
          >
            <h5 className="mb-0 text-black">Amortization %</h5>
            <IncDecCounter className="m-0" />
          </Col>
        </Row>
        <Table
          showHeader={false}
          className="second-table-mini"
          columns={secondColumns}
          dataSource={secondDataSource}
          bordered={false}
          pagination={false}
          rowClassName="ant-table-row-light"
        />
      </Card>
    </div>
  );
}

export default VhlFinancing;
