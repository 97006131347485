// /* eslint-disable */
import api from "../../../utils/api";

// Create an account for projects
const createProject = async (userAccount) => {
  const response = await api.post(`/api/Project/save`, userAccount);
  return response.data;
};

// Get All list of projects
const getAllProjectList = async (data) => {
  const response = await api.get(
    `/api/Project/getAll`, {
      params: data,
    }
  );
  return response.data.data;
};
// Get Single projects
const getSingleProject = async (data) => {
  const response = await api.get("/api/Project/get", {
    params: data,
  });
  return response.data.data;
};

// Delete projects
const deleteProject = async (data) => {
  const response = await api.delete("/api/Project/delete", { data });
  return response.data;
};

// Get Project Managers
const getAllProjectManager = async (data) => {
  const response = await api.get("/api/Project/getAllProjectManagers", {
    params: data,
  });
  return response.data.data;
};

// Create an account for projects
const archiveProject = async (data) => {
  const response = await api.post(
    `/api/Project/archiveProject?projectId=${data.projectId}&isArchived=${data.isArchived}`
  );
  return response.data;
};

// Get All list of projects
const getAllArchivedProjects = async (data) => {
  const response = await api.get("/api/Project/getAllArchivedProjects", {
    params: data,
  });
  return response.data.data;
};

const completeProject = async (data) => {
  const response = await api.post(
    `/api/Project/markProjectComplete?projectId=${data.projectId}`
  );
  return response.data;
};

// Get All list of projects
const getAllCompletedProjects = async (data) => {
  const response = await api.get("/api/Project/getAllCompletedProjects", {
    params: data,
  });
  return response.data.data;
};

const assignProjectsToUser = async (data) => {
  const response = await api.post(`/api/Project/assignProjectsToUser`, data);
  return response.data;
};

const projects = {
  createProject,
  getAllProjectList,
  getSingleProject,
  deleteProject,
  getAllProjectManager,
  archiveProject,
  getAllArchivedProjects,
  completeProject,
  getAllCompletedProjects,
  assignProjectsToUser,
};

export default projects;
