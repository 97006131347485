/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import { Card, Checkbox, Input, Form, Table, notification, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  getAssetErvGrowth,
  getAssetInflation,
  saveAssetErvGrowth,
  saveAssetInflation,
} from "../../../../../../../../app/features/calculation/calculationAsset.slice";

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

function ErvEditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleErvSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleErvSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        role="presentation"
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
}

function VeInflationAndERVGrowth(props) {
  const dispatch = useDispatch();
  const param = useParams();
  const localStorageData = localStorage.getItem("checkboxDefault");
  const CheckboxData = localStorageData ? JSON.parse(localStorageData) : null;
  const [inflationYearsColumn, setInflationYearsColumn] = useState();
  const [inflationDataValue, setInflationDataValue] = useState();
  const [ervGrowthYearsColumn, setErvGrowthYearsColumn] = useState();
  const [ervGrowthDataValue, setErvGrowthDataValue] = useState();
  const [checkboxvalue, setCheckBoxBalue] = useState(CheckboxData);
  const { inflationRate, ervGrowthRate } = useSelector(
    (state) => state.calculation
  );

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  /* Inflation */

  useEffect(() => {
    extractData();
  }, [inflationRate]);

  function extractData() {
    let columns = [];
    const record = [{}];

    for (let i = 0; i < inflationRate?.length; i++) {
      const item = inflationRate[i];
      let column = {
        title: item.year,
        dataIndex: `inflationValue-${item.inflationId}`,
        key: `inflationValue-${item.inflationId}`,
        align: "center",
        editable: true,
      };
      columns.push(column);
      record[0][`inflationValue-${item.inflationId}`] = item.inflationValue;
    }
    setInflationYearsColumn(columns);
    setInflationDataValue(record);
  }

  const moveToNext = () => {
    const finalData = {
      assetId: param.assetDetailId,
    };
    dispatch(getAssetInflation(finalData));
  };
  function enterInflationData(updatedRow) {
    const record = [];
    for (let i = 0; i < inflationRate?.length; i++) {
      let item = inflationRate[i];
      const newValue = updatedRow[0][`inflationValue-${item.inflationId}`];
      const newValues = {
        ...item,
        inflationValue: +newValue,
      };
      record.push(newValues);
    }
    dispatch(saveAssetInflation({ record, moveToNext, notifyToaster }));
  }

  const handleSave = (row) => {
    const newData = [...inflationDataValue];
    newData[0] = row;
    setInflationDataValue(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = inflationYearsColumn?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  /* Erv Growth */

  useEffect(() => {
    extractErvGrowthData();
  }, [ervGrowthRate]);

  function extractErvGrowthData() {
    let columns = [];
    const record = [{}];

    for (let i = 0; i < ervGrowthRate?.length; i++) {
      const item = ervGrowthRate[i];
      let column = {
        title: item.year,
        dataIndex: `ervValue-${item.ervId}`,
        key: `ervValue-${item.ervId}`,
        align: "center",
        editable: true,
      };
      columns.push(column);
      record[0][`ervValue-${item.ervId}`] = item.ervValue;
    }
    setErvGrowthYearsColumn(columns);
    setErvGrowthDataValue(record);
  }
  const moveToNext2 = () => {
    const finalData = {
      assetId: param.assetDetailId,
    };
    dispatch(getAssetErvGrowth(finalData));
  };

  // const [syncWithInflation, setSyncWithInflation] = useState(false);
  // console.log("syncWithInflation", syncWithInflation);
  function enterErvGrowthData(updatedRow) {
    const record = [];
    for (let i = 0; i < ervGrowthRate?.length; i++) {
      let item = ervGrowthRate[i];
      const newValue = updatedRow[0][`ervValue-${item.ervId}`];
      const newValues = {
        ...item,
        ervValue: +newValue,
        isSyncWithInflation: checkboxvalue,
      };
      record.push(newValues);
    }
    dispatch(saveAssetErvGrowth({ record, moveToNext2, notifyToaster }));
  }

  const { striped } = props;

  const handleErvSave = (row) => {
    const newData = [...ervGrowthDataValue];
    newData[0] = row;
    setErvGrowthDataValue(newData);
  };

  const ervComponents = {
    body: {
      row: EditableRow,
      cell: ErvEditableCell,
    },
  };

  const secondColumns = ervGrowthYearsColumn?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleErvSave,
      }),
    };
  });

  useEffect(() => {
    const finalData = {
      assetId: param.assetDetailId,
    };
    dispatch(getAssetInflation(finalData));
    dispatch(getAssetErvGrowth(finalData));
  }, [dispatch]);

  const handleCheckValue = () => {
    setCheckBoxBalue(!checkboxvalue);
    localStorage.setItem("checkboxDefault", JSON.stringify(!checkboxvalue));

    // console.log(e.target.checked, "value event");
    // setSyncWithInflation(e.target.checked);
    // enterErvGrowthData(ervGrowthDataValue);
  };

  useEffect(() => {
    if (ervGrowthDataValue) {
      enterErvGrowthData(ervGrowthDataValue);
    }
  }, [checkboxvalue]);

  return (
    <div className="erv-table">
      <Card bordered={false}>
        <div>
          <h3 className="text-black">Inflation</h3>
          <hr className="border-top-0 border-2" />
        </div>
        <Table
          components={components}
          rowClassName={(record, index) =>
            striped !== false && index % 2 === 0
              ? "ant-table-row-light"
              : "ant-table-row-level"
          }
          columns={columns}
          dataSource={inflationDataValue}
          // bordered
          pagination={false}
          className="calculation-table mt-2"
          scroll={{
            x: 900,
          }}
        />
        <div className="py-2">
          <Button
            onClick={() => enterInflationData(inflationDataValue)}
            className="btn-primary float-right"
          >
            Submit
          </Button>
        </div>
        <div className="pt-2 mt-3 d-flex justify-content-between w-100">
          <div>
            <h3 className="text-black mb-0">ERV Growth</h3>
          </div>
          <div>
            <Checkbox
              style={{ color: "#6F7173" }}
              checked={checkboxvalue}
              onClick={() => {
                handleCheckValue();
                // setSyncWithInflation(e.target.checked),
                // enterErvGrowthData(ervGrowthDataValue);
              }}
            >
              SYNC WITH INFLATION
            </Checkbox>
          </div>
        </div>
        <hr className="border-top-0 border-2" />
        <Table
          components={ervComponents}
          rowClassName={(record, index) =>
            striped !== false && index % 2 === 0
              ? "ant-table-row-light"
              : "ant-table-row-level"
          }
          columns={secondColumns}
          dataSource={ervGrowthDataValue}
          // bordered
          pagination={false}
          className="calculation-table mt-2"
          scroll={{
            x: 900,
          }}
        />
        <div className="py-2">
          <Button
            onClick={() => enterErvGrowthData(ervGrowthDataValue)}
            className="btn-primary float-right"
          >
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default VeInflationAndERVGrowth;
VeInflationAndERVGrowth.propTypes = {
  striped: PropTypes.node.isRequired,
};

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};

ErvEditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleErvSave: PropTypes.node.isRequired,
};
