import PropTypes from "prop-types";
// import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from "antd";
// import DataTable from '../../../../../../components/Theme/DataTable';

// const icon = ({ expanded, onExpand, record }) =>
//     expanded ? (
//         <CaretUpOutlined onClick={e => onExpand(record, e)} />
//     ) : (
//         <CaretDownOutlined onClick={e => onExpand(record, e)} />
//     )

export default function CashflowDataTable(props) {
  const { showTanents, active } = props;

  const tanents = [
    {
      title: <div style={{ fontSize: "18px" }}>Tentants</div>,
    },
    {
      title: "Abu Dubai Tourism Authority",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Albrecht GmbH",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Trading Technologies GmbH",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Vacancy",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
  ];

  const monthColumn = [
    {
      title: "Date",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "250px",
      align: "left",
      render: (text) => <div className="text-blue">{text}</div>,
      // children: [
      //     {
      //         title: "Month",

      //     },
      // ],
    },
    {
      title: "1/1/2022",
      dataIndex: "month1",
      key: "month1",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "1",

      //     },
      // ],
    },
    {
      title: "2/1/2022",
      dataIndex: "month2",
      key: "month2",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "2",

      //     },
      // ],
    },
    {
      title: "3/1/2022",
      dataIndex: "month3",
      key: "month3",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "3",

      //     },
      // ],
    },
    {
      title: "4/1/2022",
      dataIndex: "month4",
      key: "month4",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "4",

      //     },
      // ],
    },
    {
      title: "5/1/2022",
      dataIndex: "month5",
      key: "month5",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "5",

      //     },
      // ],
    },
    {
      title: "6/1/2022",
      dataIndex: "month6",
      key: "month6",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "6",

      //     },
      // ],
    },
    {
      title: "7/1/2022",
      dataIndex: "month7",
      key: "month7",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "7",

      //     },
      // ],
    },
    {
      title: "8/1/2022",
      dataIndex: "month8",
      key: "month8",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "8",

      //     },
      // ],
    },
    {
      title: "9/1/2022",
      dataIndex: "month9",
      key: "month9",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "9",

      //     },
      // ],
    },
    {
      title: "10/1/2022",
      dataIndex: "month10",
      key: "month10",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "10",

      //     },
      // ],
    },
    {
      title: "11/1/2022",
      dataIndex: "month11",
      key: "month11",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "11",

      //     },
      // ],
    },
    {
      title: "12/1/2022",
      dataIndex: "month12",
      key: "month12",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "12",

      //     },
      // ],
    },
  ];

  const yearColumn = [
    {
      title: "Date",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      width: "250px",
      align: "left",
      render: (text) => <div className="text-blue">{text}</div>,
      // children: [
      //     {
      //         title: "Year",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month1",
      key: "month1",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "1",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month2",
      key: "month2",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),

      // children: [
      //     {
      //         title: "2",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month3",
      key: "month3",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "3",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month4",
      key: "month4",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "4",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month5",
      key: "month5",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "5",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month6",
      key: "month6",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "6",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month7",
      key: "month7",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "7",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month8",
      key: "month8",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "8",

      //     },
      // ],
    },
    {
      title: "2022",
      dataIndex: "month9",
      key: "month9",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "9",

      //     },
      // ],
    },
    {
      title: "2006",
      dataIndex: "month10",
      key: "month10",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "10",

      //     },
      // ],
    },
    {
      title: "2005",
      dataIndex: "month11",
      key: "month11",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "11",

      //     },
      // ],
    },
    {
      title: "2004",
      dataIndex: "month12",
      key: "month12",
      width: 110,
      render: (text) =>
        text < 0 ? (
          <div className="text-danger">{text}</div>
        ) : (
          <div>{text}</div>
        ),
      // children: [
      //     {
      //         title: "12",

      //     },
      // ],
    },
  ];

  const dataSource = [
    {
      title: "Gross Rental Income",
      month1: -2034,
      month2: 29695,
      month3: 29695,
      month4: 29695,
      month5: 29695,
      month6: 29695,
      month7: 29695,
      month8: 29695,
      month9: -9032,
      month10: 29695,
      month11: 29695,
      month12: 29695,
    },
    {
      title: "Market Rent",
      month1: 39350,
      month2: 39350,
      month3: 39350,
      month4: 39350,
      month5: 39350,
      month6: 39350,
      month7: 39350,
      month8: 39350,
      month9: 39350,
      month10: 39350,
      month11: 39350,
      month12: 39350,
    },
    {
      title: "Potential Rent",
      month1: 29340,
      month2: 29695,
      month3: 29695,
      month4: 29695,
      month5: 29695,
      month6: 29695,
      month7: 29695,
      month8: 29695,
      month9: 29695,
      month10: 29695,
      month11: 29695,
      month12: 29695,
    },
    {
      key: 1,
      title: "Total Non Recoverable Costs",
      month1: 29340,
      month2: 29695,
      month3: 29695,
      month4: 29695,
      month5: 29695,
      month6: 29695,
      month7: 29695,
      month8: 29695,
      month9: 29695,
      month10: 29695,
      month11: 29695,
      month12: 29695,
      children: [
        {
          key: 11,
          title: "Maintenance Cost",
          month1: 29340,
          month2: 29695,
          month3: 29695,
          month4: 29695,
          month5: 29695,
          month6: 29695,
          month7: 29695,
          month8: 29695,
          month9: 29695,
          month10: -39847,
          month11: 29695,
          month12: 29695,
        },
        {
          key: 12,
          title: "Management Cost",
          month1: 29340,
          month2: 29695,
          month3: 29695,
          month4: 29695,
          month5: 29695,
          month6: 29695,
          month7: 29695,
          month8: 29695,
          month9: 29695,
          month10: 29695,
          month11: 29695,
          month12: 29695,
        },
        {
          key: 13,
          title: "Other Cost",
          month1: -293,
          month2: -296,
          month3: -296,
          month4: -296,
          month5: -296,
          month6: -296,
          month7: -296,
          month8: -296,
          month9: -296,
          month10: -296,
          month11: -296,
          month12: -296,
        },
      ],
    },
    {
      title: "Net Operating Income",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Extra Costs",
      month1: 0,
      month2: 0,
      month3: 0,
      month4: 0,
      month5: 0,
      month6: 0,
      month7: 0,
      month8: 0,
      month9: 0,
      month10: 0,
      month11: 0,
      month12: 0,
    },
    {
      title: "Free Cash Flow",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Terminal Value",
      month1: 0,
      month2: 0,
      month3: 0,
      month4: 0,
      month5: 0,
      month6: 0,
      month7: 0,
      month8: 0,
      month9: 0,
      month10: 0,
      month11: 0,
      month12: 0,
    },
    {
      title: "Discounted Cash FLow",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: <div style={{ fontSize: "18px", color: "#000"  }}>Financing Cash Flow</div>,
    },
    {
      title: "Remaining Debt",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Interest Expenses",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Amortization",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Prepayment Fee",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Final Instalment",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Total Financing Cash Flow",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
    {
      title: "Leverage Cash Flow",
      month1: 27580,
      month2: 27580,
      month3: 27580,
      month4: 27580,
      month5: 27580,
      month6: 27580,
      month7: 27580,
      month8: 27580,
      month9: 27580,
      month10: 27580,
      month11: 27580,
      month12: 27580,
    },
  ];

  if (showTanents === true) {
    dataSource.splice(3, 0, ...tanents);
  }

  const highlighted = [
    "Net Operating Income",
    "Free Cash Flow",
    "Discounted Cash Flow",
    "Total Financing Cash Flow",
    "Leverage Cash Flow",
  ];

  return (
    // <DataTable
    //     columns={columns}
    //     dataSource={dataSource}
    //     pagination={false}
    //     className="cashflow-table py-3"
    //     scroll={{
    //         x: 1300,
    //     }}
    //     expandable={{
    //         expandIcon: icon,
    //         rowExpandable: (record) => record.title !== 'Total Non Recoverable Costs',
    //     }}
    // />
    <Table
      rowClassName={(record) =>
        highlighted.includes(record.title)
          ? "highlighted-row table-row-light"
          : "table-row-light"
      }
      className="antd-custom-card-table cashflow-table py-3"
      columns={active === "monthly" ? monthColumn : yearColumn}
      dataSource={dataSource}
      pagination={false}
      scroll={{
        x: 1300,
      }}
      // expandable={{
      //     expandIcon: icon,
      //     rowExpandable: (record) => record.title === 'Total Non Recoverable Costs',
      // }}
    />
  );
}

CashflowDataTable.propTypes = {
  showTanents: PropTypes.node.isRequired,
  active: PropTypes.node.isRequired,
};
