import { Card } from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";

export default function AreaIncomeAnalysis() {
  const columns = [
    {
      title: "Use Type",
      children: [
        {
          title: "",
          children: [
            {
              title: "",
              dataIndex: "useType",
              key: "useType",
              align: "left",
            },
            {
              title: "in % of Total Area",
              dataIndex: "areaPer",
              key: "areaPer",
            },
          ],
        },
      ],
    },
    {
      title: "Lettable Area",
      children: [
        {
          title: "Total",
          children: [
            {
              title: "sqm",
              dataIndex: "total",
              key: "total",
            },
          ],
        },
        {
          title: "Let",
          children: [
            {
              title: "sqm",
              dataIndex: "let",
              key: "let",
            },
          ],
        },
        {
          title: "Vacant",
          children: [
            {
              title: "sqm",
              dataIndex: "vacant",
              key: "vacant",
            },
          ],
        },
      ],
    },
    {
      title: "Vacancy Rate",
      children: [
        {
          title: "",
          children: [
            {
              title: "%",
              dataIndex: "vacancyRate",
              key: "vacancyRate",
            },
          ],
        },
      ],
    },
    {
      title: "Gross Rental Income EUR",
      children: [
        {
          title: "Current Rate",
          children: [
            {
              title: "sq",
              dataIndex: "currentArea",
              key: "currentArea",
            },
            {
              title: "per",
              dataIndex: "currentRate",
              key: "currentRate",
            },
          ],
        },
        {
          title: "Market Rate",
          children: [
            {
              title: "sq",
              dataIndex: "marketArea",
              key: "marketArea",
            },
            {
              title: "per",
              dataIndex: "marketRate",
              key: "marketRate",
            },
          ],
        },
      ],
    },
    {
      title: "Walt *",

      children: [
        {
          title: "",
          children: [
            {
              title: "Years",
              dataIndex: "years",
              key: "years",
            },
          ],
        },
      ],
    },
    {
      title: "Rental Level",

      children: [
        {
          title: "",
          children: [
            {
              title: "%",
              dataIndex: "level",
              key: "level",
            },
          ],
        },
      ],
    },
  ];

  const dataSource = [
    {
      areaPer: "64.5%",
      useType: "Office",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Retail",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Residential",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Total (Areas)",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Parking Internal",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Antenna",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Total (Units)",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
    {
      areaPer: "64.5%",
      useType: "Total",
      total: "1074",
      let: "1074",
      vacant: "0",
      vacancyRate: "0.00%",
      currentArea: "23.3",
      currentRate: "305,455",
      marketArea: "25.5%",
      marketRate: "315,600",
      years: "3.3",
      level: "-51%",
    },
  ];

  return (
    <Card title={<h2 className="mb-0 text-black">Area & Income Analysis</h2>} className="area-analysis-wrapper">
      <hr className="my-2 mx-3" />
      <DataTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="antd-table-striped p-3"
        // rowSelection={rowSelection}
      />
    </Card>
  );
}
