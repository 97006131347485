import { Card, Col, Row } from "antd";
import ReactApexChart from "react-apexcharts";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";

export default function TanentsAnalysis() {
    const columns = [
        {
            title: "No.",
            children: [
                {
                    title: "",
                    children: [
                        {
                            title: "",
                            dataIndex: "id",
                            key: "id",
                        },
                    ],
                },
            ],
        },
        {
            title: "Top 5 Tenants",
            children: [
                {
                    title: "",
                    children: [
                        {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                        },
                    ],
                },
            ],
        },
        {
            title: "Share",
            children: [
                {
                    title: "Income",
                    children: [
                        {
                            title: "%",
                            dataIndex: "income",
                            key: "income",
                        },
                    ],
                },
            ],
        },
        {
            title: "Lettable",
            children: [
                {
                    title: "Area",
                    children: [
                        {
                            title: "sq",
                            dataIndex: "area",
                            key: "area",
                        },
                    ],
                },
            ],
        },
        {
            title: "Gross Rental Income",

            children: [
                {
                    title: "Current Rate",
                    children: [
                        {
                            title: "EUR p.a",
                            dataIndex: "eur",
                            key: "eur",
                        },
                        {
                            title: "EUR/sqm",
                            dataIndex: "eurpersq",
                            key: "eurpersq",
                        },
                    ],
                },
            ],
        },
        {
            title: "Expiry",

            children: [
                {
                    title: "",
                    children: [
                        {
                            title: "Date",
                            dataIndex: "date",
                            key: "date",
                        },
                        {
                            title: "Walt",
                            dataIndex: "walt",
                            key: "walt",
                        },
                    ],
                },
            ],
        },
        {
            title: "Options",

            children: [
                {
                    title: "Years",
                    children: [
                        {
                            title: "1st",
                            dataIndex: "firstYear",
                            key: "firstYear",
                        },
                        {
                            title: "2nd",
                            dataIndex: "secondYear",
                            key: "secondYear",
                        },
                    ],
                },
            ],
        },
    ];

    const dataSource = [
        {
            id: 1,
            name: "Arlene McCoy",
            income: "33.1%",
            area: "245",
            eur: "27,000",
            eurpersq: "94.21",
            date: "23/02/2022",
            walt: "4.5",
            firstYear: "-",
            secondYear: "-",
        },
        {
            id: 2,
            name: "Arlene McCoy",
            income: "33.1%",
            area: "245",
            eur: "27,000",
            eurpersq: "94.21",
            date: "23/02/2022",
            walt: "4.5",
            firstYear: "-",
            secondYear: "-",
        },
        {
            id: 3,
            name: "Arlene McCoy",
            income: "33.1%",
            area: "245",
            eur: "27,000",
            eurpersq: "94.21",
            date: "23/02/2022",
            walt: "4.5",
            firstYear: "-",
            secondYear: "-",
        },
        {
            id: 4,
            name: "Arlene McCoy",
            income: "33.1%",
            area: "245",
            eur: "27,000",
            eurpersq: "94.21",
            date: "23/02/2022",
            walt: "4.5",
            firstYear: "-",
            secondYear: "-",
        },
        {
            id: 5,
            name: "Arlene McCoy",
            income: "33.1%",
            area: "245",
            eur: "27,000",
            eurpersq: "94.21",
            date: "23/02/2022",
            walt: "4.5",
            firstYear: "-",
            secondYear: "-",
        },
        {
            id: "Total",
            name: "",
            income: "91.0%",
            area: "1,331",
            eur: "762,401,000",
            eurpersq: "47.74",
            date: "",
            walt: "",
            firstYear: "",
            secondYear: "",
        },
    ];

    const chartData = {
        series: [60],
        options: {
            chart: {
                type: "radialBar",
            },
            colors: ["#00215B", "#F2F4F7"],
            legend: {
                show: false,
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        show: true,
                        name: {
                            show: false,
                        },
                        value: {
                            show: true,
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "#00215B",
                            offsetY: 5,
                            formatter: (val) => `${val}%`,
                        },
                    },
                    hollow: {
                        size: "40%",
                    },
                },
            },
            dataLabels: {
                enabled: false,
                textAnchor: "middle",
            },
            labels: [" "],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                    },
                },
            ],
        },
    };

    return (
        <Card title={<h2 className="mb-0 text-black">Top Tenants Analysis</h2>} className="tanent-analysis-wrapper">
        <hr className="my-2 mx-3" />
            <Row>
                <Col xs={24} md={16} >
                    <DataTable
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        className="antd-table-striped p-3"
                        scroll={{
                            x: 130,
                        }}
                    />
                </Col>
                <Col xs={24} md={8} className="p-3">
                    <Row className="py-2">
                        <Col xs={10} className="d-flex align-items-center">
                            <h4 className="text-black mb-0">
                                Top 5 Tenants Share
                                of Income
                            </h4>
                        </Col>
                        <Col xs={14} className="d-flex align-items-center">
                            <div id="chart">
                                <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={165} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="py-2 mb-2">
                        <Col xs={10} className="d-flex align-items-center">
                            <h4 className="text-black mb-0">
                                Share of Income with
                                Prolongation Options
                            </h4>
                        </Col>
                        <Col xs={14} className="d-flex align-items-center">
                            <div id="chart">
                                <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={165} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="w-100 rounded-sm bg-light-blue p-3 ">
                        <Col xs={24} className="d-flex justify-content-between">
                            <h4 className="text-blue">Total Tenants</h4>
                            <h4 className="text-blue">25</h4>
                        </Col>
                        <Col xs={24} className="d-flex justify-content-between">
                            <div className="d-flex"><h4 className="text-blue mr-1">Total Tenants </h4><p className="m-0 text-blue">(excluding units)</p></div>
                            <h4 className="text-blue">15</h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}
