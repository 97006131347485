/* eslint-disable */

import { UserOutlined } from "@ant-design/icons";
import { BsBell } from "react-icons/bs";
import { useAuth } from "react-oidc-context"
import {
  Avatar,
  Dropdown,
  Layout,
  Menu,
  Space,
  Divider,
  Badge,
  Tag,
} from "antd";
import PropTypes from "prop-types";
import { IoIosArrowDown } from "react-icons/io";
import { useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../../assets/images";
import { logout } from "../../../app/features/auth/auth.slice";
import SIDE_MENU_LIST from "../SideMenu/SideMenuItems";
import PATH from "../../../utils/path";
import { getPageInfo } from "../../../utils/helpers";
import { getRoleName } from "../../../utils/authenticateUsers";

const { Header, Content, Sider } = Layout;
function AppLayout({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const param = useParams();
  const pageInfo = getPageInfo(location.pathname);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const role = getRoleName(user?.roleId);

  const logoutFun = () => {
    dispatch({type:"auth/logout"});
    auth.signoutRedirect()
    
  };
  let sideMenuItems = [];

  if (user && user.roleId && user.roleId === 2)
    sideMenuItems = [...SIDE_MENU_LIST.ADMIN, ...sideMenuItems];
  if (user && user.roleId && user.roleId === 1)
    sideMenuItems = [...SIDE_MENU_LIST.CORPORATE, ...sideMenuItems];
  if (user && user.roleId && user.roleId === 3)
    sideMenuItems = [...SIDE_MENU_LIST.INDIVIDUAL, ...sideMenuItems];
  if (user && user.roleId && user.roleId === 4)
    sideMenuItems = [...SIDE_MENU_LIST.PROJECT_MANAGER, ...sideMenuItems];
  const profileDropdown = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link className="text-dark" to={PATH.PROFILE_SETTING}>
              Profile Setting
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link
              className="text-dark"
              to={location.pathname}
              onClick={logoutFun}
            >
              Logout
            </Link>
          ),
        },
      ]}
    />
  );
  // const footerMenuItems = [
  //   // {
  //   //   // key: PATH.ADMIN_DASHBOARD,
  //   //   icon: <img src={IMAGES.HELP_ICON} alt="" />,
  //   //   label: (
  //   //     <Link to={location.pathname} onClick={logoutFun}>
  //   //       Help
  //   //     </Link>
  //   //   ),
  //   // },
  //   {
  //     // key: PATH.ADMIN_PROJECTS,
  //     icon: <img src={IMAGES.LOGOUT} alt="" />,
  //     label: (
  //       <Link to={location.pathname} onClick={logoutFun}>
  //         Logout
  //       </Link>
  //     ),
  //   },
  // ];
  const getActualParam = (item) => {
    const actualParam = param[item.param];
    const actualPath = item.path.replace(`:${item.param}`, actualParam);
    return actualPath;
  };
  return (
    <Layout className="antd-custom-layout">
      <Sider
        breakpoint="lg"
        className="antd-custom-sidebar"
        collapsedWidth="0"
        width="235"
      >
        <div className="sidebar-inner-section">
          <div className="w-100">
            <div
              className="text-center user-info-section cursor-pointer"
              role="button"
              tabIndex="0"
            >
              {/* <Link to='#'> */}
              <img src={IMAGES.LOGO} alt="" /> {/* </Link> */}
            </div>
            <div className="custon-menu">
              <Menu
                className="mt-2 custom-antd-menu custom-scroll"
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["STUDENT_DASHBOARD"]}
                selectedKeys={[
                  (location.pathname.startsWith("/projects") && "/projects") ||
                    (location.pathname.startsWith("/user-management") &&
                      "/user-management") ||
                    (location.pathname.startsWith(`/${role}/dashboard`) && `${role}/dashboard`) ||
                    (location.pathname.startsWith("/parameters") && "/parameters"),
                ]}
                items={sideMenuItems}
                defaultOpenKeys={[location?.pathname?.split("/")?.[2] || ""]}
              />
            </div>
          </div>
          {/* <div className="footer-section">
            <Menu
              className="mt-2 custom-antd-menu custom-scroll"
              mode="inline"
              items={footerMenuItems}
            />
          </div> */}
        </div>
      </Sider>
      <Layout>
        <div className="container p-0 w-100 h-100">
          <Header
            className="site-layout-sub-header-background custom-header d-flex align-items-center justify-content-between px-4"
            style={{
              padding: 0,
            }}
          >
            <h3 className="header-page-title mb-0 text-black-50 d-flex font-weight-light">
              {pageInfo?.pages?.map((item, index) => (
                <>
                  <Link
                    className={` mx-1 ${
                      pageInfo?.pages.length === index + 1 &&
                      "activeBreadcrumbs"
                    } `}
                    to={item.param ? getActualParam(item) : item.path}
                  >
                    {item.title}
                  </Link>
                  {pageInfo?.pages.length !== index + 1 && "/"}
                </>
              ))}
            </h3>
            {user &&
            user.roleId &&
            user.roleId === 2 &&
            location.pathname.startsWith(
              `/user-management/${param.userId}/user-details`
            ) ? (
              <Tag fill="#00215b" className="user-banner">
                <h5 className="mb-0 text-white">You are Viewing as Appostel</h5>
              </Tag>
            ) : (
              ""
            )}
            <div className="align-items-center d-flex">
              <Badge dot fill="#FF3636">
                <BsBell size={20} className="cursor-pointer" />
              </Badge>

              <Divider type="vertical" />
              <Dropdown overlay={profileDropdown} trigger={["click"]}>
                <Space>
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${`${user.username}`}&background=00215b&color=fff`}
                    size={35}
                    className="cursor-pointer"
                    icon={<UserOutlined />}
                  />
                  <h5 className="mb-0 text-black cursor-pointer">
                    {user.username}
                  </h5>
                  <IoIosArrowDown fill="black" className="cursor-pointer" />
                </Space>
              </Dropdown>
            </div>
          </Header>
          <Content>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              {children}
            </div>
          </Content>
          {/* <Footer
            style={{
              textAlign: "center",
            }}
          >
            Ant Design ©2018 Created by Ant UED
          </Footer> */}
        </div>
      </Layout>
    </Layout>
  );
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppLayout;
