/* eslint-disable */
import {
  Button,
  Checkbox,
  // Col,
  // Divider,
  Menu,
  // Row,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { MdDelete, MdModeEdit } from "react-icons/md";
import PropTypes from "prop-types";
// import UserList from "../CreateUpdateAssets/UserList";
import DataTable from "../../../../../../components/Layout/Table/DataTable";
// import MergeToPortfolio from "../CreateUpdateAssets/MergeToPortfolio";
import PATH from "../../../../../../utils/path";
// import DeleteUserModal from "../../../../../../components/Layout/DeleteModel/DeleteUserModal";
// import CreateUpdateAssets from "../../CreateUpdateAssets";
import {
  // deleteAssetDetail,
  getPropertyAddressList,
  updatePropertyAddress,
} from "../../../../../../app/features/projectDetails/projectDetail.slice";
import {
  getAllCities,
  getAllCountry,
  getAllState,
  updateVisibleColumns,
} from "../../../../../../app/features/Lookups/lookup.slice";

export default function PropertyAddress({
  sethideTableColumns,
  activeKey,
  searchAsset,
  // closeModal,
  // user
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const assignedUserId =  param.userId;
  const [rowRecord, setRowRecord] = useState("");

  const [editingKey, setEditingKey] = useState("");
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  // const [addMergeUserModal, setAddMergeUserModal] = useState(false);
  // const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [countryIdData, setCountryIdData] = useState();
  const [stateId, setStateId] = useState();
  // const [createUpdateAsset, setCreateUpdateNewAsset] = useState(false);
  const [toggle, setToggle] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const { getPropertyAddress, isLoading, projectDetailFilters } = useSelector(
    (state) => state.projectDetails
  );
  const { country, states, cities } = useSelector((state) => state.lookUps);
  const [projectDetailFilter, setProjectDetailFilter] = useState({
    Search: "",
    AssetId: "",
    CountryId: "",
    StateId: "",
    CityId: "",
    Street: "",
    RiskClassId: "",
    UpsidePotentialId: "",
    CapexRequirementId: "",
    ExpectedLiquidityId: "",
    ManagementRequirementId: "",
    OwnershipId: "",
    InspectionTypeId: "",
    Selection: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  const pageNumbers =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size -
    projectDetailFilter.pagination.size +
    1;

  const nextPageNumber =
    projectDetailFilter.pagination.pageNo *
      projectDetailFilter.pagination.size >
    getPropertyAddress?.totalRecords
      ? getPropertyAddress?.totalRecords
      : projectDetailFilter.pagination.pageNo *
        projectDetailFilter.pagination.size;

  // const closeAddUpdateUserModal = () => {
  //   setAddUpdateUserModal(false);
  // };
  // const closeAddMergeUserModal = () => {
  //   setAddMergeUserModal(false);
  // };
  // const closeDeleteUserModal = () => {
  //   setDeleteUserModal(false);
  // };
  // const CreateUpdateAssetcloseModal = () => {
  //   setCreateUpdateNewAsset(false);
  // };
  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  useEffect(() => {
    dispatch(getAllCountry());
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      // countryId: getPropertyAddress?.address?.[0]?.countryId,
      countryId: rowRecord?.countryId,
    };
    dispatch(getAllState(finalData));
  }, [dispatch, getPropertyAddress, rowRecord]);

  useEffect(() => {
    const finalData = {
      stateId: rowRecord?.stateId,
      // stateId: getPropertyAddress?.address?.[0]?.stateId,
    };
    dispatch(getAllCities(finalData));
  }, [dispatch, getPropertyAddress, rowRecord]);

  const getStates = (e) => {
    form.setFieldValue("stateId", parseInt("0"));
    form.setFieldValue("cityId", parseInt("0"));
    const finalData = {
      countryId: e,
    };
    dispatch(getAllState(finalData));
  };

  const getCities = (e) => {
    form.setFieldValue("cityId", parseInt("0"));
    const finalData = {
      stateId: e,
    };
    dispatch(getAllCities(finalData));
  };
  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "countryDropdown" ? (
        <Select
          placeholder="Select"
          allowClear
          value={countryIdData}
          onChange={(e) => {
            setCountryIdData(e);
            getStates(e);
            getCities();
          }}
        >
          {country?.map((item) => (
            <Option key={item.countryId} value={item.countryId}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : inputType === "stateDropdown" ? (
        <Select
          placeholder="Select"
          allowClear
          value={stateId}
          onChange={(e) => {
            setStateId(e);
            getCities(e);
            // getStates(e.target.value);
          }}
        >
          {states?.map((item) => (
            <Option key={item.stateId} value={item.stateId}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : inputType === "cityDropdown" ? (
        <Select placeholder="Select" allowClear>
          {cities?.map((item) => (
            <Option key={item.cityId} value={item.cityId}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                    {
                      max: 10,
                      message: "The max lenght of 10 Characters is reached!",
                    },
                  ]
                : inputType === "dropdown"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "text"
                ? [
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                  ]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }
  const isEditing = (record) => record.assetId === editingKey;
  const edit = (record) => {
    setRowRecord(record);
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.assetId);
  };

  const cancel = () => {
    setEditingKey("");
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    dispatch(getPropertyAddressList(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      projectId: +ProjectId,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getPropertyAddressList(finalData));
  };

  const save = async (record) => {
    const row = await form.validateFields();
    const finalData = {
      assetId: record.assetId,
      ...row,
    };
    dispatch(updatePropertyAddress({ finalData, moveToNext, notifyToaster }));
  };

  // const moveToNextDelete = () => {
  //   closeDeleteUserModal();
  //   const filters = {
  //     Search: "",
  //     AssetId: "",
  //     CountryId: "",
  //     StateId: "",
  //     CityId: "",
  //     Street: "",
  //     RiskClassId: "",
  //     UpsidePotentialId: "",
  //     CapexRequirementId: "",
  //     ExpectedLiquidityId: "",
  //     ManagementRequirementId: "",
  //     OwnershipId: "",
  //     InspectionTypeId: "",
  //     Selection: "",
  //     pagination: {
  //       pageNo: 1,
  //       size: 10,
  //     },
  //   };
  //   setProjectDetailFilter(filters);
  //   setSelectedRowKeys([]);
  //   setEditingKey("");
  //   const finalData = {
  //     projectId: +ProjectId,
  //     pageNo: projectDetailFilter?.pagination?.pageNo,
  //     size: projectDetailFilter?.pagination.size,
  //   };
  //   dispatch(getPropertyAddressList(finalData));
  // };
  // const deleteProjectDetail = () => {
  //   const finalData = selectedRowKeys;
  //   dispatch(deleteAssetDetail({ finalData, moveToNextDelete, notifyToaster }));
  // };

  const initialColState = [
    {
      id: 12,
      title: "Asset ID",
      dataIndex: "assetId",
      hidden: false,
      width: "15%",
    },
    {
      id: 0,
      title: "Country",
      dataIndex: "countryName",
      hidden: toggle.includes("countryId"),
      editable: true,
      // render: (text, row) =>
      //   country?.find((item) => item?.countryId === row?.countryId)?.name,
      onCell: (record) => ({
        record,
        inputType: "countryDropdown",
        dataIndex: "countryId",
        title: "Country",
        editing: isEditing(record),
      }),
    },

    {
      id: 1,
      title: "State (Federal)",
      dataIndex: "stateName",
      hidden: toggle.includes("stateId"),
      editable: true,
      // render: (text, row) =>
      //   states?.find((item) => item?.stateId === row?.stateId)?.name,
      onCell: (record) => ({
        record,
        inputType: "stateDropdown",
        dataIndex: "stateId",
        title: "State (Federal)",
        editing: isEditing(record),
      }),
    },
    {
      id: 3,
      title: "City",
      dataIndex: "cityName",
      hidden: toggle.includes("cityId"),
      editable: true,
      // render: (text, row) =>
      //   cities?.find((item) => item?.cityId === row?.cityId)?.name,
      onCell: (record) => ({
        record,
        inputType: "cityDropdown",
        dataIndex: "cityId",
        title: "City",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Zip",
      dataIndex: "zipCode",
      hidden: toggle.includes("zipCode"),
      editable: true,
      render: (text) => (text !== null && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "zipCode",
        title: "Zip",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Street No.",
      dataIndex: "street",
      hidden: toggle.includes("street"),
      editable: true,
      render: (text) => (text !== null && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "street",
        title: "Street",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "House No.",
      dataIndex: "houseNumber",
      hidden: toggle.includes("houseNumber"),
      editable: true,
      render: (text) => (text !== null && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "houseNumber",
        title: "House No.",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      width: "10%",
      hidden: false,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span
            className="d-flex align-items-center justify-content-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
               <ImCross size={15} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            role="button"
            tabIndex="0"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const changePath = (record) => {
    if (PATH.PROJECT_DETAILS.replace(":projectId", +ProjectId) === location.pathname) {
      // return navigate(PATH.ASSET_DETAILS);
      return navigate(
        `/projects/${ProjectId}/project-detail/${record.assetId}/asset-detail`
      );
    } else {
      return navigate(`/user-management/${param.userId}/user-details/${ProjectId}/project-detail/${record.assetId}/asset-detail`);
    }
  };
  const navigateRow = (record, rowIndex) => {
    return {
      onClick: () => {
        editingKey === "" && changePath(record);
      }, // click row
    };
  };

  const propertyAddressHideTableColumns = [
    {
      key: 1,
      type: "group",
    },
    {
      key: 2,
      value: "countryId",
      name: "Country",
      isChecked: false,
    },
    {
      key: 3,
      value: "stateId",
      name: "State (Federal) ",
      isChecked: false,
    },
    {
      key: 4,
      value: "cityId",
      name: "City",
      isChecked: false,
    },
    {
      key: 5,
      value: "zipCode",
      name: "Zip",
      isChecked: false,
    },
    {
      key: 6,
      value: "street",
      name: "Street No.",
      isChecked: false,
    },
    {
      key: 7,
      value: "houseNumber",
      name: "House No.",
      isChecked: false,
    },

    {
      key: 8,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(
    propertyAddressHideTableColumns
  );

  useEffect(() => {
    if (projectDetailFilter) {
    }
    const finalData = {
      projectId: +ProjectId,
      assignedToUserId: assignedUserId !== null ?  assignedUserId : null,
      pageNo: projectDetailFilter?.pagination?.pageNo,
      size: projectDetailFilter?.pagination.size,
      search: searchAsset?.search || "",
      assetId: projectDetailFilters?.assetId
        ? projectDetailFilters.assetId
        : searchAsset?.assetId || "",
      countryId: projectDetailFilters?.countryId
        ? projectDetailFilters.countryId
        : searchAsset?.countryId || "",
      stateId: projectDetailFilters?.stateId
        ? projectDetailFilters.stateId
        : searchAsset?.stateId || "",
      cityId: projectDetailFilters?.cityId
        ? projectDetailFilters.cityId
        : searchAsset?.cityId || "",
      street: projectDetailFilters?.street
        ? projectDetailFilters.street
        : searchAsset?.street || "",
      riskClassId: projectDetailFilters?.riskClassId
        ? projectDetailFilters.riskClassId
        : searchAsset?.riskClassId || "",
      upsidePotentialId: projectDetailFilters?.upsidePotentialId
        ? projectDetailFilters.upsidePotentialId
        : searchAsset?.upsidePotentialId || "",
      capexRequirementId: projectDetailFilters?.capexRequirementId
        ? projectDetailFilters.capexRequirementId
        : searchAsset?.capexRequirementId || "",
      expectedLiquidityId: projectDetailFilters?.expectedLiquidityId
        ? projectDetailFilters.expectedLiquidityId
        : searchAsset?.expectedLiquidityId || "",
      managementRequirementId: projectDetailFilters?.managementRequirementId
        ? projectDetailFilters.managementRequirementId
        : searchAsset?.managementRequirementId || "",
      ownershipId: projectDetailFilters?.ownershipId
        ? projectDetailFilters.ownershipId
        : searchAsset?.ownershipId || "",
      inspectionTypeId: projectDetailFilters?.inspectionTypeId
        ? projectDetailFilters.inspectionTypeId
        : searchAsset?.inspectionTypeId || "",
      selection: projectDetailFilters?.selection
        ? projectDetailFilters.selection
        : searchAsset?.selection || "",
    };
    dispatch(getPropertyAddressList(finalData));
  }, [dispatch, projectDetailFilter, searchAsset]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    getPropertyAddress?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [getPropertyAddress]);

  useEffect(() => {
    if (getPropertyAddress) {
      const a = getPropertyAddress.visibleColumns?.map((item) => item.moduleId);
      setModuleIdValue(a?.pop());

      const b = getPropertyAddress.visibleColumns?.map(
        (item) => item.moduleTabId
      );
      setModuleTabIdValue(b?.pop());
    }
  }, [getPropertyAddress]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];

    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 8) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 8) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  return (
    <div>
      <div className="basic-data">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName={() => "editable-row"}
            columns={initialColState.filter((item) => item.hidden === false)}
            dataSource={
              getPropertyAddress?.address ? getPropertyAddress?.address : ""
            }
            pagination={{
              current: projectDetailFilter?.pagination?.pageNo || 1,
              pageSize: 10,
              total: getPropertyAddress?.totalRecords,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                setProjectDetailFilter({
                  ...projectDetailFilter,
                  pagination: {
                    pageNo: page,
                    size: pageSize,
                  },
                });
              },
            }}
            className="antd-table-striped"
            // rowSelection={rowSelection}
            loading={isLoading}
            rowKey={(record) => record.assetId}
            onRow={navigateRow}
            scroll={{
              x: 750,
              y: "calc(100vh - 422px)",
            }}
          />
        </Form>
      </div>
      {getPropertyAddress?.address?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {getPropertyAddress?.totalRecords} entries
            </h5>
          </div>
          {/* <Row gutter={24}>
            <Col
              xs={8}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />{" "}
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button> */}

                  {/* {selectedRowKeys?.length > 1 ? (
                ""
              ) : (
                <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setCreateUpdateNewAsset(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdModeEdit className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                    </div>
                  </Button>
                </>
              )} */}
                  {/* <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddMergeUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <BsFileEarmarkExcelFill className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Merge to Portfolio</h5>
                    </div>
                  </Button>
                  {user?.roleId === 3 || user?.roleId === 4 || location.pathname.startsWith("user-management") ? ("") : (
                    <>
                  <Divider type="vertical" />
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center"
                    onClick={() => setAddUpdateUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <BsFileEarmarkExcelFill className="mt-1" />
                      <h5 className="mb-0 ml-1 mt-1 ">Assign Task</h5>
                    </div>
                  </Button>
                  </>
                  )}
                </div> */}

                {/* <Button className="btn-cancel">
              <h5 className="mb-0">Cancel</h5>
            </Button> */}
              {/* </div>
            </Col>
          </Row> */}
        </>
      )}

      {/* {addUpdateUserModal && (
        <UserList
          visible={addUpdateUserModal}
          handleCancel={closeAddUpdateUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      {addMergeUserModal && (
        <MergeToPortfolio
          visible={addMergeUserModal}
          handleCancel={closeAddMergeUserModal}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      /> */}
      {/* <CreateUpdateAssets
        visible={createUpdateAsset}
        handleCancel={CreateUpdateAssetcloseModal}
      /> */}
    </div>
  );
}

PropertyAddress.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
  searchAsset: PropTypes.node.isRequired,
  closeModal: PropTypes.node.isRequired,
};
