import { Modal, Col, Row, Input, Cascader, Button } from "antd";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";

function SearchForm(props) {
  const { visible, handleCancel } = props;
  const { Search } = Input;
  const options = [];

  return (
    <Modal
      className="search-modals"
      visible={visible}
      handleCancel={handleCancel}
      width={600}
      style={{
        top: 80,
        // left: 115,
      }}
      footer={[
        <Button
          key="submit"
          type="default"
          className="btn-cancel"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button key="submit" type="default" className="btn-primary">
          Search
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <span className="searchbar-icon">
            <FiSearch size={20} />
          </span>
          <Search className="mr-2" placeholder="Search a Rent roll" />
        </Col>
      </Row>
      <br />

      <Row className="row d-flex search-form-filter-row" gutter={10}>
        <Col xs={24} sm={12} lg={6} className="mb-2">
          <Cascader
            className="w-100"
            options={options}
            placeholder="Project ID"
          />
        </Col>
        <Col xs={24} sm={12} lg={6} className="mb-2">
          <Input placeholder="Project Name" className="w-100" />
        </Col>
        <Col xs={24} sm={12} lg={6} className="mb-2">
          <Input placeholder="Client Name" className="w-100" />
        </Col>

        <Col xs={24} sm={12} lg={6} className="mb-2">
          <Cascader
            className="w-100"
            options={options}
            placeholder="Project Country"
          />
        </Col>
        <Col xs={24} sm={12} lg={6} className="mb-2">
          <Cascader
            className="w-100"
            options={options}
            placeholder="Project State"
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default SearchForm;
SearchForm.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
