import api from "../../../utils/api";

const getAssetInflation = async (data) => {
  const response = await api.get("/api/AssetInflation/getByAssetId", {
    params: data,
  });
  return response.data.data;
};

const saveAssetInflation = async (data) => {
  const response = await api.post("/api/AssetInflation/save", data);
  return response.data;
};

const getAssetErvGrowth = async (data) => {
  const response = await api.get(
    "/api/AssetEstimatedRentalValue/getByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

const saveAssetErvGrowth = async (data) => {
  const response = await api.post("/api/AssetEstimatedRentalValue/save", data);
  return response.data;
};

const getAssetFinancing = async (data) => {
  const response = await api.get("/api/AssetFinancing/getByAssetId", {
    params: data,
  });
  return response.data.data;
};

const saveAssetFinancing = async (data) => {
  const response = await api.post("/api/AssetFinancing/save", data);
  return response.data;
};

const getAssetAcquisitionCostExit = async (data) => {
  const response = await api.get("/api/AssetAcquisitionCostExit/getByAssetId", {
    params: data,
  });
  return response.data.data;
};

const saveAssetAcquisitionCostExit = async (data) => {
  const response = await api.post("/api/AssetAcquisitionCostExit/save", data);
  return response.data;
};

const getAssetAcquisitionCostInitial = async (data) => {
  const response = await api.get(
    "/api/AssetAcquisitionCostInitial/getByAssetId",
    {
      params: data,
    }
  );
  return response.data.data;
};

const saveAssetAcquisitionCostInitial = async (data) => {
  const response = await api.post(
    "/api/AssetAcquisitionCostInitial/save",
    data
  );
  return response.data;
};

const calculation = {
  getAssetInflation,
  saveAssetInflation,
  getAssetErvGrowth,
  saveAssetErvGrowth,
  getAssetFinancing,
  saveAssetFinancing,
  getAssetAcquisitionCostExit,
  saveAssetAcquisitionCostExit,
  getAssetAcquisitionCostInitial,
  saveAssetAcquisitionCostInitial,
};

export default calculation;
