import { useState } from "react";
import { Col, notification, Row, Tag } from "antd";
// import { FaShareSquare } from "react-icons/fa";
import {
  MdDelete,
  MdEdit,
  MdOutlineKeyboardArrowRight,
  // MdShare,
} from "react-icons/md";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import IMAGES from "../../../../../assets/images";
import DeleteUserModal from "../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdate from "../../CreateUpdate";
import { deleteProject } from "../../../../../app/features/Projects/project.slice";
import PATH from "../../../../../utils/path";
import { getBasicDataList } from "../../../../../app/features/projectDetails/projectDetail.slice";

export default function DetailDrawer(props) {
  const {
    setShowDrawer,
    showDrawer,
    onPortfolioClick,
    onAssetClick,
    getBasicData,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const ProjectId = param.projectId;
  const [addUpdateNewProjectModal, setAddUpdateNewProjectModal] =
    useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const { user } = useSelector((state) => state.auth);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const closeAddUpdateNewProjectModal = () => {
    setAddUpdateNewProjectModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDeleteProject = () => {
    closeDeleteUserModal();
    navigate(PATH.PROJECT_LIST);
  };

  const deleteProjects = () => {
    const finalData = [getBasicData?.getBasicData?.projectId];
    dispatch(deleteProject({ finalData, moveToNextDeleteProject, notifyToaster }));
  };
  const assetDetailUpdate = () => {
    const finalData = {
      projectId: +ProjectId,
    };
    dispatch(getBasicDataList(finalData));
  };
  return (
    <div className="">
      <div className=" custom-drawer-content custom-drawer">
        <div className="text-center">
          <img src={IMAGES.DRAWER_ICON} alt="" />
        </div>
        <div>
          <h2 className="text-black font-weight-bold text-center pt-2 mb-1">
            {getBasicData?.getBasicData?.projectName || "N/A"}
          </h2>
          <span
            className="drawer-close-btn cursor-pointer"
            role="button"
            tabIndex="0"
          >
            <MdOutlineKeyboardArrowRight
              size={25}
              fill="#fff"
              onClick={() => setShowDrawer(!showDrawer)}
            />
          </span>
        </div>

        <div className= {`d-flex align-items-center justify-content-center mt-4 ${user.roleId === 2 && "drawer-manager"}`}>
          {user && user.roleId && user.roleId !== 2 && (
            <>
              <MdEdit
                size={33}
                className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                onClick={() => {
                  setFlag(true);
                  setAddUpdateNewProjectModal(true);
                  setSelectedRowKeys(getBasicData?.getBasicData);
                }}
              />
              <MdDelete
                size={33}
                className="p-2 bg-light-grey rounded-circle mx-1 cursor-pointer"
                onClick={() => setDeleteUserModal(true)}
              />
            </>
          )}
        </div>
        <Row gutter={24} className="pt-3 pb-4 cards-row">
          <Col xs={12} className="pr-0">
            <Tag
              color="#DB5461"
              className="pt-2 pb-1 mr-0 drawer-card w-100 cursor-pointer"
              // role="button"
              // tabIndex="0"
              onClick={() => onPortfolioClick()}
              // onKeyDown={() => navigateFunction()}
            >
              <p className="text-white mb-0">Portfolio&apos;s</p>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src={IMAGES.PORTFOLIO_ICON}
                    alt=""
                    className="portfolio-img"
                  />
                </div>
                <div>
                  <h1 className="text-white mb-0">
                    {getBasicData?.getBasicData?.totalPorfolio || "0"}
                  </h1>
                </div>
              </div>
            </Tag>
          </Col>
          <Col xs={12} className="pr-0">
            <Tag
              color="#2978A0"
              className="pt-2 pb-1 mr-0 drawer-card w-100 cursor-pointer"
              // role="button"
              // tabIndex="0"
              onClick={() => onAssetClick()}
              // onClick={() => navigateFunction()}
              // onKeyDown={() => navigateFunction()}
            >
              <p className="text-white mb-0">Assets</p>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src={IMAGES.ASSETS_ICON}
                    alt=""
                    className="portfolio-img"
                  />
                </div>
                <div>
                  <h1 className="text-white mb-0">
                    {getBasicData?.getBasicData?.totalAssets || "0"}
                  </h1>
                </div>
              </div>
            </Tag>
          </Col>
        </Row>
        <div className="project-drawer-details custom-scroll">
          <div>
            <h5 className="mb-0 text-black">Project ID</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.projectId || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <h5 className="mb-0 text-black">Project manager</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.projectManager || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <h5 className="mb-0 text-black">Client Name</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.clientName || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <h5 className="mb-0 text-black">Project type</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.projectType || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <h5 className="mb-0 text-black">Project Status</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.projectStatus || "N/A"}
            </p>
          </div>
          <hr className="my-2" />
          <div>
            <h5 className="mb-0 text-black">Notes</h5>
            <p className="mb-0 text-gray pt-2">
              {getBasicData?.getBasicData?.description || "N/A"}
            </p>
          </div>
        </div>
      </div>
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Project"
        onSubmit={deleteProjects}
      />
      <CreateUpdate
        visible={addUpdateNewProjectModal}
        handleCancel={closeAddUpdateNewProjectModal}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        flag={flag}
        setFlag={setFlag}
        assetDetailUpdate={assetDetailUpdate}
      />
    </div>
  );
}

DetailDrawer.propTypes = {
  setShowDrawer: PropTypes.node.isRequired,
  showDrawer: PropTypes.node.isRequired,
  onAssetClick: PropTypes.node.isRequired,
  onPortfolioClick: PropTypes.node.isRequired,
  getBasicData: PropTypes.node.isRequired,
};
