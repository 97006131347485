import { CaretDownFilled } from "@ant-design/icons";
import { Card, Cascader, Checkbox, Col, Input, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

function VeHoldingPeriodAndExitCalculation() {
  const options = [
    {
      value: "demo",
      label: "Potential",
    },
    {
      value: "demo2",
      label: "Jiangsu",
    },
  ];

  return (
    <div className="holdingPeriod">
      <Card bordered={false}>
        <div>
          <h3 className="text-black">Holding Period & Exit Calculation</h3>
          <hr className="border-top-0 border-2 mt-0" />
        </div>
        <Row className="d-flex align-items-center justify-content-between mt-3">
          <Col>
            <h4 className="text-black">Year</h4>
            <IncDecCounter className="m-0" />
          </Col>
          <Col>
            <h4 className="text-black">Base Rent</h4>
            <Cascader
              suffixIcon={<CaretDownFilled />}
              options={options}
              placeholder="Potential Rent"
            />
          </Col>
          <Col>
            <h4 className="text-black">Non Rec. Costs</h4>
            <div className="d-flex align-items-center">
              <Input
                className="mr-2"
                style={{
                  width: 50,
                  border: "1px solid #B7B7B7",
                  padding: "12px",
                }}
              />
              <Checkbox className="mb-2">INDIVIDUAL</Checkbox>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default VeHoldingPeriodAndExitCalculation;
