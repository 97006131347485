/* eslint-disable*/
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Menu,
  Select,
  notification,
  Col,
  Row,
  InputNumber,
} from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import { useParams } from "react-router-dom";
import {
  getByTypeDropdown,
  updateVisibleColumns,
} from "../../../../../../../app/features/Lookups/lookup.slice";
import {
  deleteRentRoll,
  getBasicDataByAssetId,
  updateBasicData,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { useDispatch, useSelector } from "react-redux";

function BasicData({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const param = useParams();
  const assetId = param.assetDetailId;
  const [editingKey, setEditingKey] = useState("");
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const [toggle, setToggle] = useState([]);

  const { rentRollListBasic, isLoading } = useSelector(
    (state) => state.rentRoll
  );

  const { UseGroupType, UseType, RentRollStatusType, RentRollTabs } =
    useSelector((state) => state.lookUps);
  const [rentRollFilter, setRentRollFilter] = useState({
    Search: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size -
    rentRollFilter.pagination.size +
    1;

  const nextPageNumber =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size >
    rentRollListBasic?.totalRecords
      ? rentRollListBasic?.totalRecords
      : rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size;

  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "statusDropdown" ? (
        <Select placeholder="Select" allowClear>
          {RentRollStatusType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "groupDropdown" ? (
        <Select placeholder="Select" allowClear>
          {UseGroupType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "useDropdown" ? (
        <Select placeholder="Select" allowClear>
          {UseType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "number" ? (
        <InputNumber maxLength={10} />
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                    {
                      required: true,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "dropdown"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "text"
                ? [
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                  ]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  const localStorageData = localStorage.getItem("reia_user");
  const UserId = localStorageData ? JSON.parse(localStorageData) : null;

  const isEditing = (record) => record.rentRollId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.rentRollId);
  };

  useEffect(() => {
    const finalData = {
      type: "UseType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "RentRollTabs",
      order: 1,
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "UseGroupType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "RentRollStatusType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const cancel = () => {
    setEditingKey("");
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");

    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getBasicDataByAssetId(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getBasicDataByAssetId(finalData));
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const finalData = {
        rentRollId: record.rentRollId,
        area: +row.area,
        statusTypeId: row.statusTypeId,
        tenantName: row.tenantName,
        description1: row.description1,
        description2: row.description2,
        location: row.location,
        useTypeId: row.useTypeId,
        useGroupTypeId: row.useGroupTypeId,
      };
      dispatch(updateBasicData({ finalData, moveToNext, notifyToaster }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setRentRollFilter(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getBasicDataByAssetId(finalData));
  };
  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteRentRoll({ finalData, moveToNextDelete, notifyToaster }));
  };

  const initialColState = [
    {
      id: 1,
      title: "PID",
      dataIndex: "rentRollId",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "statusTypeId",
      hidden: false,
      editable: true,
      render: (text, row) =>
        (text &&
          RentRollStatusType?.find(
            (item) => item?.lookUpId === row?.statusTypeId
          )?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "statusDropdown",
        dataIndex: "statusTypeId",
        title: "Status/Type",
        editing: isEditing(record),
      }),
    },
    {
      id: 3,
      title: "Tenant Name",
      dataIndex: "tenantName",
      hidden: toggle.includes("tenantName"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "tenantName",
        title: "Tenant Name",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Description #1",
      dataIndex: "description1",
      hidden: toggle.includes("description1"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "description1",
        title: "Description #1",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Description #2",
      dataIndex: "description2",
      hidden: toggle.includes("description2"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "description2",
        title: "Description #2",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "Location/Floor",
      dataIndex: "location",
      hidden: toggle.includes("location"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: "location",
        title: "Location/Floor",
        editing: isEditing(record),
      }),
    },
    {
      id: 7,
      title: "Use Group",
      dataIndex: "useGroupTypeId",
      hidden: toggle.includes("useGroupTypeId"),
      editable: true,
      render: (text, row) =>
        (text &&
          UseGroupType?.find((item) => item?.lookUpId === row?.useGroupTypeId)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "groupDropdown",
        dataIndex: "useGroupTypeId",
        title: "Use Group",
        editing: isEditing(record),
      }),
    },
    {
      id: 8,
      title: "Use",
      dataIndex: "useTypeId",
      hidden: toggle.includes("useTypeId"),
      editable: true,
      render: (text, row) =>
        (text &&
          UseType?.find((item) => item?.lookUpId === row?.useTypeId)?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "useDropdown",
        dataIndex: "useTypeId",
        title: "Use",
        editing: isEditing(record),
      }),
    },
    {
      id: 9,
      title: "Area/Unit",
      dataIndex: "area",
      hidden: toggle.includes("area"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "area",
        title: "Area/Unit",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const CBData = [
    {
      key: 1,
      value: "group",
    },

    {
      key: 2,
      value: "tenantName",
      name: "Tanent Name",
      isChecked: false,
    },
    {
      key: 3,
      value: "description1",
      name: "Description #1",
      isChecked: false,
    },
    {
      key: 4,
      value: "description2",
      name: "Description #2",
      isChecked: false,
    },
    {
      key: 5,
      value: "location",
      name: "Location/Floor",
      isChecked: false,
    },
    {
      key: 6,
      value: "useGroupTypeId",
      name: "Use Group",
      isChecked: false,
    },
    {
      key: 7,
      value: "useTypeId",
      name: "Use",
      isChecked: false,
    },
    {
      key: 8,
      value: "area",
      name: "Area/Unit",
      isChecked: false,
    },
    {
      key: 9,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(CBData);

  useEffect(() => {
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getBasicDataByAssetId(finalData));
  }, [dispatch, rentRollFilter]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    rentRollListBasic?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [rentRollListBasic]);

  useEffect(() => {
    if (rentRollListBasic) {
      const a = rentRollListBasic.visibleColumns?.map((item) => item.moduleId);
      setModuleIdValue(a?.pop());

      const b = rentRollListBasic.visibleColumns?.map(
        (item) => item.moduleTabId
      );
      setModuleTabIdValue(b?.pop());
    }
  }, [rentRollListBasic]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 9) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 9) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  return (
    <>
    <div className="rentroll-table">
      <Form form={form} component={false}>
        <DataTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          columns={initialColState.filter((item) => item.hidden === false)}
          dataSource={rentRollListBasic ? rentRollListBasic?.basicData : ""}
          pagination={{
            current: rentRollFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: rentRollListBasic?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setRentRollFilter({
                ...rentRollFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.rentRollId}
          scroll={{
            x: 900,
            y: "calc(100vh - 472px)",
          }}
        />
      </Form>
      </div>
      {rentRollListBasic?.basicData?.length > 0 && (
        <>
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of
            {rentRollListBasic?.totalRecords} entries
          </h5>
        </div>
      

      <Row gutter={24} className="ml-0">
        <Col
          xs={24}
          style={{
            padding: 0,
          }}
        >
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
              <Button
                className="btn-action d-flex justify-content-center align-items-center text-danger"
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
              >
                <div className="active-action d-flex align-items-center">
                  <MdDelete />
                  <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                </div>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      </>
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </>
  );
}
export default BasicData;

// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };

BasicData.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};
