/* eslint-disable */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Checkbox,
  Menu,
  Form,
  Input,
  Select,
  notification,
  Row,
  Col,
  InputNumber,
  DatePicker,
} from "antd";
import moment from "moment";
import { MdDelete, MdModeEdit } from "react-icons/md";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getByTypeDropdown,
  updateVisibleColumns,
} from "../../../../../../../app/features/Lookups/lookup.slice";
import {
  deleteRentRoll,
  getIndexationByAssetId,
  updateIndexation,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";

function Indexation({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const param = useParams();
  const assetId = param.assetDetailId;
  const [editingKey, setEditingKey] = useState("");
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [toggle, setToggle] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const { rentRollListIndexation, isLoading } = useSelector(
    (state) => state.rentRoll
  );

  const { IndexationType, IndexType } = useSelector((state) => state.lookUps);
  const [rentRollFilter, setRentRollFilter] = useState({
    Search: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size -
    rentRollFilter.pagination.size +
    1;

  const nextPageNumber =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size >
    rentRollListIndexation?.totalRecords
      ? rentRollListIndexation?.totalRecords
      : rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [dates, setDates] = useState({
    firstDate: null,
    lastDate: null,
    nextDate: null,
    isValid: true,
  });
  const dateChange = (value, field) => {
    setDates({
      ...dates,
      [field]: value?._d ? moment(value?._d).format("YYYY-MM-DD") : null,
    });
  };
  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "typeDropdown" ? (
        <Select placeholder="Selection" allowClear>
          {IndexationType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "indexDropdown" ? (
        <Select placeholder="Selection" allowClear>
          {IndexType?.map((item) => {
            return (
              <Select.Option key={item.lookUpId} value={item.lookUpId}>
                {item.text}
              </Select.Option>
            );
          })}
        </Select>
      ) : inputType === "number" ? (
        <InputNumber maxLength={10} />
      ) : (
        <Input type={inputType} />
      );
    return (
      <td {...restProps}>
        {editing && inputType !== "date" ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "dropdown"
                ? [
                    {
                      required: false,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "text"
                ? [
                    {
                      max: 50,
                      message: "The max lenght of 50 Characters is reached!",
                    },
                  ]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : editing && inputType === "date" ? (
          <>
            <DatePicker
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => dateChange(e, dataIndex)}
              className="w-100 rounded-sm"
              defaultValue={
                dates?.[dataIndex] && moment(dates?.[dataIndex], "YYYY-MM-DD")
              }
              dateFormat="YYYY-MM-DD"
            />
            {(dataIndex === "firstDate" || dataIndex === "lastDate") &&
              dates.isValid === false && (
                <p className="text-danger mb-0">
                  First Date Can't be greater than Last Date
                </p>
              )}
          </>
        ) : (
          children
        )}
      </td>
    );
  }

  const localStorageData = localStorage.getItem("reia_user");
  const UserId = localStorageData ? JSON.parse(localStorageData) : null;

  const isEditing = (record) => record.rentRollId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.rentRollId);
    setDates({
      firstDate: record.firstDate,
      lastDate: record.lastDate,
      nextDate: record.nextDate,
    });
  };
  useEffect(() => {
    const finalData = {
      type: "IndexationType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "IndexType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const cancel = () => {
    setEditingKey("");
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");

    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getIndexationByAssetId(finalData));
  };

  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getIndexationByAssetId(finalData));
  };

  const validateStartDate = () => {
    let isValidDate = false;
    if (dates.firstDate > dates.lastDate) {
      isValidDate = false;
    } else {
      isValidDate = true;
    }
    setDates({ ...dates, isValid: isValidDate });
    return isValidDate;
  };
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const validateDate = validateStartDate();
      if (!validateDate) {
        return;
      }
      const finalData = {
        rentRollId: record.rentRollId,
        firstDate: dates.firstDate ? dates.firstDate : null,
        lastDate: dates.lastDate ? dates.lastDate : null,
        nextDate: dates.nextDate ? dates.nextDate : null,
        ...row,
      };
      dispatch(updateIndexation({ finalData, moveToNext, notifyToaster }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setRentRollFilter(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      projectId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getProjectDetailById(finalData));
  };
  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteRentRoll({ finalData, moveToNextDelete, notifyToaster }));
  };
  const initialColState = [
    {
      id: 1,
      title: "PID",
      dataIndex: "rentRollId",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "statusType",
      hidden: false,
    },
    {
      id: 3,
      title: "Type",
      dataIndex: "indexationTypeId",
      hidden: toggle.includes("indexationTypeId"),
      editable: true,
      render: (text, row) =>
        (text &&
          IndexationType?.find(
            (item) => item?.lookUpId === row?.indexationTypeId
          )?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "typeDropdown",
        dataIndex: "indexationTypeId",
        title: "Type",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Index",
      dataIndex: "indexTypeId",
      hidden: toggle.includes("indexTypeId"),
      editable: true,
      render: (text, row) =>
        (text &&
          IndexType?.find((item) => item?.lookUpId === row?.indexTypeId)
            ?.value) ||
        "N/A",
      onCell: (record) => ({
        record,
        inputType: "indexDropdown",
        dataIndex: "indexTypeId",
        title: "Index",
        editing: isEditing(record),
      }),
    },
    {
      id: 6,
      title: "First",
      dataIndex: "firstDate",
      hidden: toggle.includes("firstDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "firstDate",
        title: "First",
        editing: isEditing(record),
      }),
    },
    {
      id: 5,
      title: "Last",
      dataIndex: "lastDate",
      hidden: toggle.includes("lastDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "lastDate",
        title: "Last",
        editing: isEditing(record),
      }),
    },

    {
      id: 7,
      title: "Next",
      dataIndex: "nextDate",
      hidden: toggle.includes("nextDate"),
      editable: true,
      render: (text) => (text && moment(text).format("YYYY-MM-DD")) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "date",
        dataIndex: "nextDate",
        title: "Next",
        editing: isEditing(record),
      }),
    },
    {
      id: 8,
      title: "Cycle",
      dataIndex: "cycle",
      hidden: toggle.includes("cycle"),
      editable: true,
      render: (text) => (text && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "cycle",
        title: "Cycle",
        editing: isEditing(record),
      }),
    },
    {
      id: 9,
      title: "Hurdle",
      dataIndex: "hurdle",
      hidden: toggle.includes("hurdle"),
      editable: true,
      render: (text) => (text && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "hurdle",
        title: "Hurdle",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Factor",
      dataIndex: "factor",
      hidden: toggle.includes("factor"),
      editable: true,
      render: (text) => (text && text) || "N/A",
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "factor",
        title: "Factor",
        editing: isEditing(record),
      }),
    },
    {
      id: 11,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  const indexationDatahideTableColumns = [
    {
      key: 1,
      type: "group",
    },

    {
      key: 2,
      value: "indexationTypeId",
      name: "Type",
      isChecked: false,
    },
    {
      key: 3,
      value: "indexTypeId",
      name: "Index",
      isChecked: false,
    },
    {
      key: 4,
      value: "firstDate",
      name: "First",
      isChecked: false,
    },
    {
      key: 5,
      value: "lastDate",
      name: "Last",
      isChecked: false,
    },

    {
      key: 6,
      value: "nextDate",
      name: "Next",
      isChecked: false,
    },
    {
      key: 7,
      value: "cycle",
      name: "Cycle",
      isChecked: false,
    },
    {
      key: 8,
      value: "hurdle",
      name: "Hurdle",
      isChecked: false,
    },
    {
      key: 9,
      value: "factor",
      name: "Factor",
      isChecked: false,
    },
    {
      key: 10,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(
    indexationDatahideTableColumns
  );
  useEffect(() => {
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getIndexationByAssetId(finalData));
  }, [dispatch, rentRollFilter]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    rentRollListIndexation?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [rentRollListIndexation]);

  useEffect(() => {
    if (rentRollListIndexation) {
      const a = rentRollListIndexation.visibleColumns?.map(
        (item) => item.moduleId
      );
      setModuleIdValue(a?.pop());

      const b = rentRollListIndexation.visibleColumns?.map(
        (item) => item.moduleTabId
      );
      setModuleTabIdValue(b?.pop());
    }
  }, [rentRollListIndexation]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 10) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 10) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);
  const formData = rentRollListIndexation?.indexation
    ? rentRollListIndexation?.indexation.map((item) => {
        const { nextDate, firstDate, lastDate } = item;
        return {
          ...item,
          nextDate: nextDate && nextDate?.split("T")[0],
          firstDate: firstDate && firstDate?.split("T")[0],
          lastDate: lastDate && lastDate?.split("T")[0],
        };
      })
    : [];

  return (
    <div>
      <div className="rentroll-table">
        <Form form={form} component={false}>
          <DataTable
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{
              x: 900,
              y: "calc(100vh - 488px)",
            }}
            rowClassName={() => "editable-row"}
            columns={initialColState.filter((item) => item.hidden === false)}
            dataSource={formData}
            pagination={{
              current: rentRollFilter?.pagination?.pageNo || 1,
              pageSize: 10,
              total: rentRollListIndexation?.totalRecords,
              showSizeChanger: false,
              disabled: false,
              onChange: (page, pageSize) => {
                setRentRollFilter({
                  ...rentRollFilter,
                  pagination: {
                    pageNo: page,
                    size: pageSize,
                  },
                });
              },
            }}
            className="antd-table-striped"
            rowSelection={rowSelection}
            loading={isLoading}
            rowKey={(record) => record.rentRollId}
          />
        </Form>
      </div>
      {rentRollListIndexation?.indexation?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {rentRollListIndexation?.totalRecords} entries
            </h5>
          </div>

          <Row gutter={24} className="ml-0">
            <Col
              xs={24}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </div>
  );
}

export default Indexation;
Indexation.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};

// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };
