/* eslint-disable */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import {
  Button,
  Form,
  Input,
  Checkbox,
  Menu,
  Select,
  notification,
  Row,
  Col,
  InputNumber,
} from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCurrentRentByAssetId,
  updateCurrentRent,
  deleteRentRoll,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { updateVisibleColumns } from "../../../../../../../app/features/Lookups/lookup.slice";

function CurrentRent({ sethideTableColumns, activeKey }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const param = useParams();
  const assetId = param.assetDetailId;
  const [editingKey, setEditingKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [moduleTabIdValue, setModuleTabIdValue] = useState("");
  const [toggle, setToggle] = useState([]);
  const { rentRollListCurrent, isLoading } = useSelector(
    (state) => state.rentRoll
  );

  const [rentRollFilter, setRentRollFilter] = useState({
    Search: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });

  const pageNumbers =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size -
    rentRollFilter.pagination.size +
    1;

  const nextPageNumber =
    rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size >
    rentRollListCurrent?.totalRecords
      ? rentRollListCurrent?.totalRecords
      : rentRollFilter.pagination.pageNo * rentRollFilter.pagination.size;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const AddUpdateNewRentRollcloseModal = () => {
    setAddUpdateNewRentRoll(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode =
      inputType === "number" ? (
        <InputNumber maxLength={10} />
      ) : (
        <Select placeholder="Select" allowClear>
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={
              inputType === "number"
                ? [
                    {
                      required: true,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : inputType === "dropdown"
                ? [
                    {
                      required: true,
                      message: `Please Input ${title}!`,
                    },
                  ]
                : []
            }
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  const localStorageData = localStorage.getItem("reia_user");
  const UserId = localStorageData ? JSON.parse(localStorageData) : null;

  const isEditing = (record) => record.rentRollId === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.rentRollId);
  };

  const cancel = () => {
    setEditingKey("");
  };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNext = () => {
    setEditingKey("");
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getCurrentRentByAssetId(finalData));
  };
  const moveToNext2 = () => {
    setEditingKey("");
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    hideBtn.disabled = false;
    hideBtn2.disabled = false;
    dispatch(getCurrentRentByAssetId(finalData));
  };

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      const finalData = {
        rentRollId: record.rentRollId,
        perSqMeter: row.perSqMeter,
        perMonth: row.perSqMeter * daysInCurrentMonth,
        perYear: row.perSqMeter * daysInCurrentMonth * 12,
        vat: row?.vat,
      };

      dispatch(updateCurrentRent({ finalData, moveToNext, notifyToaster }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const moveToNextDelete = () => {
    closeDeleteUserModal();
    const filters = {
      Search: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setRentRollFilter(filters);
    setSelectedRowKeys([]);
    setEditingKey("");
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };
    dispatch(getCurrentRentByAssetId(finalData));
  };
  const deleteProjectDetail = () => {
    const finalData = selectedRowKeys;
    dispatch(deleteRentRoll({ finalData, moveToNextDelete, notifyToaster }));
  };
  const currentRentcolumns = [
    {
      id: 1,
      title: "PID",
      dataIndex: "rentRollId",
      hidden: false,
    },
    {
      id: 2,
      title: "Status/Type",
      dataIndex: "statusType",
      hidden: false,
    },
    {
      id: 3,
      title: "Per Sq M",
      dataIndex: "perSqMeter",
      hidden: toggle?.includes("perSqMeter"),
      // hidden: toggle.includes("perSqMeter"),
      editable: true,
      onCell: (record) => ({
        record,
        inputType: "number",
        dataIndex: "perSqMeter",
        title: "Per Sq M",
        editing: isEditing(record),
      }),
    },
    {
      id: 4,
      title: "Per Month",
      dataIndex: "perMonth",
      hidden: toggle?.includes("perMonth"),
      editable: true,
    },

    {
      id: 5,
      title: "Per Year",
      dataIndex: "perYear",
      hidden: toggle?.includes("perYear"),
      editable: true,
    },

    {
      id: 6,
      title: "VAT",
      dataIndex: "vat",
      hidden: toggle?.includes("vat"),
      editable: true,
      render: (vat) => (vat === true ? "Yes" : "No"),
      onCell: (record) => ({
        record,
        inputType: "dropdown",
        dataIndex: "vat",
        title: "VAT",
        editing: isEditing(record),
      }),
    },
    {
      id: 10,
      title: "Action",
      dataIndex: "Action",
      hidden: false,
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span className="d-flex align-items-center justify-content-center">
            <div
              role="presentation"
              className="m-0 mr-2 drawer-action-icons cursor-pointer"
              onClick={() => cancel()}
            >
              <MdDelete size={20} />
            </div>
            <div
              className="m-0 drawer-action-icons cursor-pointer"
              role="presentation"
              onClick={() => save(record)}
            >
              <FaCheck size={20} />
            </div>
          </span>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="drawer-action-icons d-flex align-items-center cursor-pointer"
              role="presentation"
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              <MdModeEdit size={20} />
            </div>
          </div>
        );
      },
    },
  ];

  // const [hiddenTableColumns, setHiddenTableColumns] = useState([]);
  // const hideColumnsName = [];
  // const hideColumn = (column, checked) => {
  //   if (checked) {
  //     const updatedHideTableColumns = [...hiddenTableColumns];
  //     updatedHideTableColumns.push(column);
  //     hideColumnsName.push(column);
  //     console.log("hiddenTableColumns", hiddenTableColumns);
  //     console.log("updatedHideTableColumnsNew", updatedHideTableColumns);
  //     setHiddenTableColumns(updatedHideTableColumns);
  //   } else {
  //     let updatedHideTableColumns = hiddenTableColumns.filter(
  //       (item) => item !== column
  //     );
  //     setHiddenTableColumns(updatedHideTableColumns);
  //     hideColumnsName = hideColumnsName.filter((item) => item !== column);
  //   }
  // };
  // console.log("hiddenTableColumns", hiddenTableColumns);

  // let columnsNames = [{ name: "abc", value: "" }];
  // const hideFunction = () => {
  //   const response = columnsNames.filter(
  //     (item) => !hiddenTableColumns.includes(item.value)
  //   );
  //   console.log(hideFunction, "hideFunction");
  //   const newResponse = response.map((item) => {
  //     return {
  //       dataIndex: item.a,
  //       isVisible: true,
  //     };
  //   });
  //   console.log(newResponse, "newResponse");
  // };
  const CBData = [
    {
      key: 1,
      type: "group",
    },
    {
      key: 2,
      value: "perSqMeter",
      name: "Per Sq M",
      isChecked: false,
    },
    {
      key: 4,
      value: "perMonth",
      name: "Per Month",
      isChecked: false,
    },
    {
      key: 5,
      value: "perYear",
      name: "Per Year",
      isChecked: false,
    },
    {
      key: 6,
      value: "vat",
      name: "VAT",
      isChecked: false,
    },
    {
      key: 7,
    },
  ];

  const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
    return (
      <Checkbox
        value={value}
        name={name}
        checked={isChecked}
        onChange={handleChange}
      >
        {name}
      </Checkbox>
    );
  };

  const [checkBoxData, setCheckBoxData] = useState(CBData);
  useEffect(() => {
    const finalData = {
      assetId: +assetId,
      pageNo: rentRollFilter?.pagination?.pageNo,
      size: rentRollFilter?.pagination.size,
    };

    dispatch(getCurrentRentByAssetId(finalData));
  }, [dispatch, rentRollFilter]);

  useEffect(() => {
    sethideTableColumns(<Menu items={checkBoxData}></Menu>);
  }, [activeKey, checkBoxData]);

  useEffect(() => {
    let checkBox = [];

    rentRollListCurrent?.visibleColumns?.map((item) => {
      if (!item.isVisible) {
        checkBox.push(item.columnName);
      }
    });
    setToggle(checkBox);

    const uCD = checkBoxData?.map((item) => {
      if (checkBox.includes(item.value)) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
      return item;
    });
    setCheckBoxData(uCD);
  }, [rentRollListCurrent]);

  useEffect(() => {
    if (rentRollListCurrent) {
      const a = rentRollListCurrent.visibleColumns?.map(
        (item) => item.moduleId
      );
      setModuleIdValue(a?.pop());

      const b = rentRollListCurrent.visibleColumns?.map(
        (item) => item.moduleTabId
      );
      setModuleTabIdValue(b?.pop());
    }
  }, [rentRollListCurrent]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    const uCD = checkBoxData.map((item) => {
      if (item.value == value) {
        item.isChecked = checked;
      }
      return item;
    });
    setCheckBoxData(uCD);
  };

  const handleRemove = (checkBoxData) => {
    let data;
    let finalPayload;
    let hideBtn = document.querySelectorAll(".hide-btn")[0];
    let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
    const checkBox = [];
    checkBoxData.map((cb) => {
      if (cb.isChecked === false && cb.key !== 1 && cb.key !== 7) {
        checkBox.push(cb.value);
        let obj = {
          moduleId: moduleIdValue,
          moduleTabId: moduleTabIdValue,
          columnName: checkBox,
        };

        data = obj;
      }
      let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
      finalPayload = checkBox.length === 0 ? _obj : data;
    });
    dispatch(
      updateVisibleColumns({
        finalData: finalPayload,
        moveToNext2,
        notifyToaster,
      })
    );
    hideBtn.disabled = true;
    hideBtn2.disabled = true;
  };

  const handleReset = () => {
    const uCD = checkBoxData.map((item) => {
      item.isChecked = false;
      return item;
    });
    setCheckBoxData(uCD);
    handleRemove(uCD);
  };

  useEffect(() => {
    const cBD = checkBoxData.map((checkbox) => {
      return checkbox.isChecked.toString();
    });
    checkBoxData.map((checkbox) => {
      let key = checkbox?.key;
      if (key == 1) {
        checkbox.label = (
          <div>
            <h4 style={{ color: "#000" }}>Hide table columns</h4>
          </div>
        );
      } else if (key == 7) {
        checkbox.label = (
          <div>
            <hr style={{ border: "1px solid #F2F3F5" }} />
            <div className="d-flex justify-content-evenly">
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn2"
                onClick={handleReset}
              >
                Reset
              </Button>{" "}
              <Button
                disabled={!cBD.includes("true")}
                style={{ width: "130px" }}
                className="reset-dropdown-btn hide-btn"
                onClick={() => handleRemove(checkBoxData)}
              >
                hide Column
              </Button>
            </div>
          </div>
        );
      } else {
        checkbox.label = (
          <MyCheckbox
            value={checkbox.value}
            name={checkbox.name}
            isChecked={checkbox.isChecked}
            handleChange={handleChange}
          />
        );
      }
    });
  }, [checkBoxData]);

  return (
    <>
    <div className="rentroll-table">
      <Form form={form} component={false}>
        <DataTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          columns={currentRentcolumns.filter((item) => item.hidden === false)}
          dataSource={
            rentRollListCurrent?.currentRent
              ? rentRollListCurrent?.currentRent
              : ""
          }
          pagination={{
            current: rentRollFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: rentRollListCurrent?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setRentRollFilter({
                ...rentRollFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey={(record) => record.rentRollId}
          scroll={{
            x: 700,
            y: "calc(100vh - 472px)",
          }}
        />
      </Form>
      </div>
      {rentRollListCurrent?.currentRent?.length > 0 && (
        <>
        <div className="table-count">
          <h5 className="table-count-content">
            Showing {pageNumbers} to {nextPageNumber} of{" "}
            {rentRollListCurrent?.totalRecords} entries
          </h5>
        </div>
      
      <Row gutter={24} className="ml-0">
        <Col
          xs={24}
          style={{
            padding: 0,
          }}
        >
          <div className="table-footer-btn d-flex align-items-center">
            <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
              <Button
                className="btn-action d-flex justify-content-center align-items-center text-danger"
                onClick={() => setDeleteUserModal(true)}
                disabled={selectedRowKeys?.length === 0 ? "true" : false}
              >
                <div className="active-action d-flex align-items-center">
                  <MdDelete />
                  <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                </div>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      </>
      )}
      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Asset"
        onSubmit={deleteProjectDetail}
        isLoaded={isLoading}
      />
      <CreateUpdateRentRoll
        visible={addUpdateNewRentRoll}
        handleCancel={AddUpdateNewRentRollcloseModal}
      />
    </>
  );
}

export default CurrentRent;

// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };
CurrentRent.propTypes = {
  sethideTableColumns: PropTypes.node.isRequired,
  activeKey: PropTypes.node.isRequired,
};
