import { CaretDownOutlined } from "@ant-design/icons";
import { Card, Cascader, Col, Input, Row } from "antd";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";

const options = [
  {
    value: "demo",
    label: "Net initial yield (Potential)",
  },
  {
    value: "demo2",
    label: "Jiangsu",
  },
];

function VhlExitValue() {
  return (
    <Card bordered={false}>
      <div className="d-flex justify-content-between">
        <h3 className="text-black">Exit Value</h3>
        <h3 style={{ color: "#545454" }}>5,600 € /sqm</h3>
        <h3 className="text-black">23,400,000 €</h3>
      </div>
      <hr className="mt-0 border-top-0 border-2" />
      <Row className="pt-2 justify-content-between" gutter={14}>
        <Col>
          <h4 className="text-black">Valuation by target value</h4>
          <Cascader
            suffixIcon={<CaretDownOutlined />}
            options={options}
            placeholder="Net initial yield (Potential)"
          />
        </Col>
        <Col>
          <h4 className="text-black">Steps</h4>
          <div className="d-flex align-items-center">
            <div>
              <Input
                style={{
                  width: 50,
                  border: "1px solid #B7B7B7",
                  padding: "12px",
                }}
              />
            </div>
            <div>
              <p className="mb-0 pl-2">BP</p>
            </div>
          </div>
        </Col>
        <Col>
          <h4 className="text-black">Target Value %</h4>
          <div>
            <IncDecCounter className="m-0" />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default VhlExitValue;
