/* eslint-disable react/require-default-props */
import { Button } from "antd";
import { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import PropTypes from "prop-types";
import { prototype } from "apexcharts";

function IncDecCounter(props) {
  const { className, count, setNumValue } = props;
  // const [num, setNum] = useState(count);
  const [num, setNumState] = useState(count);

  const incNum = () => {
    if (num >= 0) {
      setNumState(Number(num) + 1);
      setNumValue(Number(num) + 1);
    }
  };

  const decNum = () => {
    if (num > 0) {
      setNumState(num - 1);
      setNumValue(num - 1);
    }
  };

  return (
    <div
      className={`counter-field d-flex align-items-center justify-content-between ${className}`}
    >
      <Button
        icon={<AiOutlineMinus />}
        onClick={decNum}
        className="dec-num-btn"
      />
      <p>{num}</p>
      <Button
        icon={<AiOutlinePlus />}
        onClick={incNum}
        className="inc-num-btn"
      />
    </div>
  );
}

export default IncDecCounter;

IncDecCounter.propTypes = {
  className: PropTypes.string.isRequired,
  count: prototype.Number,
  setNumValue: prototype,
};
