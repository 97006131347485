import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";

export default function ConfirmationModal(props) {
  const { visible, handleCancel, confirmMessage, onSubmit } = props;

  return (
    <Modal
      visible={visible}
      handleCancel={handleCancel}
      style={{
        top: 155,
      }}
      footer={[<div />]}
      className="confirm-model"
    >
      <div className="user-management-delete-modal">
        <Row gutter={24}>
          <Col xs={24}>
            <div className="text-center delete-modal-section">
              <div>
                <h1 className="delete-modal-heading mt-2 mb-4 font-weight-light">
                  Are you sure?
                </h1>
              </div>
              <div>
                <h4 className="delete-modal-content w-75 mx-auto font-weight-light">
                  Do you really want to {confirmMessage}?
                </h4>
              </div>
              <div className="delete-modal-btn">
                <Button className="btn-primary" onClick={handleCancel}>
                  No, not sure
                </Button>
                <Button onClick={onSubmit} className="btn-sure">
                  Yes, i am sure
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
ConfirmationModal.propTypes = {
  visible: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  confirmMessage: PropTypes.node.isRequired,
  onSubmit: PropTypes.node.isRequired,
};
