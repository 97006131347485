/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Select, Table, notification } from "antd";
import { Carousel } from "react-carousel-minimal";
import { BsDot } from "react-icons/bs";
import { IoMdImages } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { BiExpand } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IMAGES from "../../../../../assets/images";
import ValidationResults from "./component/Dashboard/ValidationResults";
import SensitiveAnalysisCard from "./component/Dashboard/SensitiveAnalysisCard";
import LeaseAnalysisChart from "./component/Dashboard/LeaseAnalysisChart";
import AreaIncomeAnalysis from "./component/Dashboard/AreaIncomeAnalysis";
import TanentsAnalysis from "./component/Dashboard/TanentsAnalysis";
import CashFlowChart from "./component/Dashboard/CashFlowChart";
import FinancialKeyFigures from "./component/Dashboard/FinancialKeyFigures";
import RetailBuildingCard from "./component/Dashboard/RetailBuildingCard";
import ShowMapModal from "./component/Dashboard/ShowMapModal";
import ImagePreview from "./component/Dashboard/ImagePreview";
import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";
import {
  AssetTerminalValueCalulation,
  SaveAssetTerminalValueCalulation,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";

function Dashboard() {
  const dispatch = useDispatch();
  const param = useParams();
  const [showImage, setShowImage] = useState(false);
  const [active, setactive] = useState("image");
  const [showMap, setShowMap] = useState(false);

  const { termialBaseRentType, termialValueType } = useSelector(
    (state) => state.lookUps
  );
  const { AssetTerminalValueCalulationData } = useSelector(
    (state) => state.corporateDashboardData
  );
  const [baseRentValue, setBaseRentValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  useEffect(() => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(AssetTerminalValueCalulation(finalData));
  }, []);

  useEffect(() => {
    const finalData = {
      type: "termialBaseRentType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "termialValueType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const initialCarouselData = [
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
    },
    {
      image:
        "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
    },
    {
      image:
        "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
    },
    {
      image:
        "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
    },
    {
      image:
        "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
    },
    {
      image:
        "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
    },
    {
      image:
        "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
    },
  ];

  const [carouselData, setCarouselData] = useState(initialCarouselData);

  const onImageChange = (event) => {
    setCarouselData((current) => [
      ...current,
      { image: URL.createObjectURL(event.target.files[0]) },
    ]);
  };

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "assetID",
      align: "center",
    },
    {
      title: "ID#2",
      dataIndex: "secondID",
      align: "center",
    },
    {
      title: "Cluster",
      dataIndex: "cluster",
      align: "center",
    },
    {
      title: "Analysis Date",
      dataIndex: "analysisDate",
      align: "center",
    },
    {
      title: "Last change",
      dataIndex: "lastChange",
      align: "center",
    },
    {
      title: "User",
      dataIndex: "user",
      align: "center",
    },
  ];
  const dataTable = [
    {
      assetID: "10",
      secondID: "101050",
      cluster: "----",
      analysisDate: "02/02/2022",
      lastChange: "02/02/2022",
      user: "JB",
    },
  ];
  const retailBuildingCard = [
    {
      image: IMAGES.BUILDING,
      title: "Primary Use",
      subtitle: "Office",
    },
    {
      image: IMAGES.SCREW,
      title: "Constr./Refurb.",
      subtitle: "1957/n/a",
    },
    {
      image: IMAGES.AREA,
      title: "Total lettable Area",
      subtitle: "1,666 sqm",
    },
    {
      image: IMAGES.SITE_AREA,
      title: "Site Area",
      subtitle: "5,000 sqm",
    },
    {
      image: IMAGES.VACANCY_RATE,
      title: "Vacancy Rate",
      subtitle: "0.9%",
    },
    {
      image: IMAGES.ANCHOR,
      title: "Anchor tenant",
      subtitle: "Paule ka SAS GmbH",
    },
    {
      image: IMAGES.WATCH,
      title: "Walt *",
      subtitle: "3.5 Years",
    },
    {
      image: IMAGES.OWNER_SHIP,
      title: "Ownership",
      subtitle: "Freehold/Leasehold",
    },
  ];

  const handleMapClick = () => {
    setShowMap(true);
    setactive("loc");
  };

  const handleCarouselClick = () => {};

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(AssetTerminalValueCalulation(finalData));
  };

  const handleFinancingValue = () => {
    const finalData = {
      terminalValueId: AssetTerminalValueCalulationData?.terminalValueId
        ? AssetTerminalValueCalulationData?.terminalValueId
        : 0,
      assetId: parseFloat(param.assetDetailId),
      baseRentId: baseRentValue
        ? baseRentValue
        : AssetTerminalValueCalulationData?.baseRentId,

      typeId: typeValue ? typeValue : AssetTerminalValueCalulationData?.typeId,
    };
    dispatch(
      SaveAssetTerminalValueCalulation({ finalData, moveToNext, notifyToaster })
    );
  };

  // const para = document.querySelector('p');
  // const compStyles = window.getComputedStyle(para);
  // para.textContent = `My computed font-size is ${compStyles.getPropertyValue('font-size')},\n` +
  //   `and my computed line-height is ${compStyles.getPropertyValue('line-height')}.`;

  // console.log(para)

  return (
    <div className="asset-dashboard-wrapper">
      <Row className="bg-white p-3 office-retail-wrapper" gutter={24}>
        <Col xs={24} xl={12}>
          <div className="parent">
            <div className="icon d-flex justify-content-between w-100 p-3">
              <span className="p-2 bg-white rounded-sm d-flex align-items-center">
                <div className="upload_btn">
                  <label htmlFor="upload" className="d-flex">
                    <IoMdImages
                      size={27}
                      className="text-blue ml-1 mr-2 my-0 cursor-pointer"
                      style={{
                        color: `${active === "image" ? "#00215B" : "#DADADA"}`,
                      }}
                      onClick={() => setactive("image")}
                    />
                    <input
                      id="upload"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={onImageChange}
                    />
                  </label>
                </div>
                <MdLocationPin
                  style={{
                    color: `${active === "loc" ? "#00215B" : "#DADADA"}`,
                  }}
                  size={27}
                  className="ml-2 mr-1 my-0 cursor-pointer"
                  onClick={() => handleMapClick()}
                />
              </span>
              <BiExpand
                size={40}
                style={{ backgroundColor: "#00000099" }}
                className="text-white shadow-sm p-2 rounded-circle cursor-pointer"
                onClick={() => setShowImage(true)}
              />
            </div>
            <Carousel
              // slideNumber
              onClick={() => handleCarouselClick()}
              className="slider"
              data={carouselData}
              height="450px"
              width="100%"
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails
            />
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <h2 className="text-blue mb-0">Office/Retail Building</h2>
          <p className="m-0 d-flex align-items-center text-subtitle ">
            Goethestr.27 <BsDot />
            60313 Frankfurt
            <BsDot /> Germany
          </p>
          <Table
            className="asset-table mt-2"
            size="small"
            columns={columns}
            dataSource={dataTable}
            pagination={false}
            bordered
          />
          <Row gutter={10} className="mt-3">
            {retailBuildingCard.map((item) => (
              <Col xs={24} md={12}>
                <RetailBuildingCard
                  image={item.image}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col xs={24} md={12}>
          <ValidationResults />
        </Col>
        <Col xs={24} md={12}>
          <SensitiveAnalysisCard />
        </Col>
        <Col xs={24} md={24} className="terminal-value-box">
          <Card
            title={
              <h2 className="mb-0 text-black">Terminal Value Calculation</h2>
            }
            className="validation-results-wrapper "
          >
            <hr className="mt-2 mx-3" />
            <Row className="px-3 pb-3 pt-2 align-items-end pb-4">
              <Col sx={24} md={8} className="pr-2">
                <h4 className="text-black">Base Rent</h4>
                <Select
                  placeholder="Base Rent"
                  allowClear
                  value={
                    baseRentValue
                      ? baseRentValue
                      : AssetTerminalValueCalulationData?.baseRentId
                  }
                  className="w-100"
                  onChange={(e) => setBaseRentValue(e)}
                >
                  {termialBaseRentType?.map((item) => (
                    <Select.Option key={item.lookUpId} value={item.lookUpId}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col sx={24} md={8} className="pr-2">
                <h4 className="text-black">Type</h4>
                <Select
                  placeholder="Select Type"
                  allowClear
                  // value={financingRateObj?.financingTypeId}
                  value={
                    typeValue
                      ? typeValue
                      : AssetTerminalValueCalulationData?.typeId
                  }
                  className="w-100"
                  onChange={(e) => setTypeValue(e)}
                >
                  {termialValueType?.map((item) => (
                    <Select.Option key={item.lookUpId} value={item.lookUpId}>
                      {item.text}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col sx={24} md={8}>
                <Button
                  type="primary"
                  className="btn-primary border-0"
                  onClick={handleFinancingValue}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24}>
          <LeaseAnalysisChart />
        </Col>
        <Col xs={24}>
          <AreaIncomeAnalysis />
        </Col>
        <Col xs={24}>
          <TanentsAnalysis />
        </Col>
        <Col xs={24} md={12}>
          <CashFlowChart />
        </Col>
        <Col xs={24} md={12}>
          <FinancialKeyFigures />
        </Col>
      </Row>
      <ShowMapModal
        visible={showMap}
        handleCancel={() => setShowMap(false)}
        setShowMap={setShowMap}
      />
      <ImagePreview
        visible={showImage}
        handleCancel={() => setShowImage(false)}
        setShowMap={setShowImage}
        image={carouselData[0].image}
      />
    </div>
  );
}

export default Dashboard;
