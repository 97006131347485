import { Card, Col, Row, Table } from "antd";

export default function ValidationResults() {

    const data = [
        {
            yiedProfile: 'Net initial yield',
            current: '3.00%',
            potential: '4.00%',
            market: '5.00%',
        },
        {
            yiedProfile: 'Gross Initial Yield',
            current: '3.00%',
            potential: '4.00%',
            market: '5.00%',
        },
        {
            yiedProfile: 'Gross multiple',
            current: '3.00%',
            potential: '4.00%',
            market: '5.00%',
        },
    ];

    const columns = [
        {
            title: 'Yied Profile',
            dataIndex: 'yiedProfile',
            align: 'left',
            width: "50%"
        },
        {
            title: 'Current',
            dataIndex: 'current',
            align: 'center'
        },
        {
            title: 'Potential',
            dataIndex: 'potential',
            align: 'center'
        },
        {
            title: 'Market',
            dataIndex: 'market',
            align: 'center'
        },
    ];

    return (
        <Card title={<h2 className="mb-0 text-black">Validation Results</h2>} className="validation-results-wrapper">
        <hr className="mt-2 mx-3" />
            <Row className="px-3 pb-3 pt-2">
                <Col xs={24} className="bg-light-blue d-flex justify-content-between p-2 rounded-sm">
                    <h4 className="text-blue m-0">Asset Value</h4>
                    <h4 className="text-blue m-0">45,520,600 € </h4>
                </Col>
                <Col xs={24} className="d-flex justify-content-between px-2 mt-2">
                    <p className="text-blue m-0">per sq M</p>
                    <p className="text-blue m-0">5,600 € </p>
                </Col>
                <Col xs={24} className="d-flex justify-content-between px-2">
                    <div className="d-flex align-items-center"><p className="text-blue m-0 mr-2 font-size-md font-weight-light ">GROSS VALUE</p> <p className="text-orange m-0">(Incl. 7.5% Acquisition Costs)</p></div>
                    <p className="text-blue m-0 font-size-md font-weight-light ">47,850,000 € </p>
                </Col>
                <Col xs={24}>
                    <Table
                        className="validation-result-table mt-2"
                        size="small"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </Col>
            </Row>
        </Card>
    )
}
