const PATH = {
  NOPAGE: "*",

  //  AUTH PATHS
  ROOT: "/",
  SIGN_UP: "/login/signup",
  FORGET_PASSWORD: "/login/forget-password",

  CORPORATE_DASHBOARD: "/corporate/dashboard",
  CORPORATE_DASHBOARD_TASKS_ASSIGNED: "/corporate/dashboard/tasks-assigned",
  INDIVIDUAL_DASHBOARD: "/individual/dashboard",
  PROJECT_MANAGER_DASHBOARD: "/projectManager/dashboard",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_ACTIVITY_LOG: "/activity-log",
  ADMIN_REPORTS: "/reports",
  ADMIN_PARAMETERS: "/parameters",

  // SHARED

  USER_MANAGEMENT_LIST: "/user-management",
  USER_MANAGEMENT_PROJECT_MANAGER: "/user-management/:userId/project-managers",
  USER_MANAGEMENT_USER_DETAIL: "/user-management/:userId/user-details",
  USER_MANAGEMENT_USER_PROJECT_DETAIL:
    "/user-management/:userId/user-details/:projectId/project-detail",
  USER_MANAGEMENT_USER_PROJECT_ASSET_DETAIL:
    "/user-management/:userId/user-details/:projectId/project-detail/:assetDetailId/asset-detail",
  USER_MANAGEMENT_USER_DETAIL_PORTFOLIO_DASHBOARD:
    "/user-management/:userId/user-details/:projectId/project-detail/:assetDetailId/portfolio-detail",
  TOTAL_TENANTS: "/total-tenants",

  PROJECT_LIST: "/projects",
  PROJECT_DETAILS: "/projects/:projectId/project-detail",
  ASSET_DETAILS: "/projects/:projectId/project-detail/:assetDetailId/asset-detail",
  PORTFOLIO_DETAILS: "/projects/:projectId/project-detail/:assetDetailId/portfolio-detail",

  // PROFILE SETTING
  TASK_ASSIGNED: "/task-assigned",
  PROFILE_SETTING: "/profile-setting",
};

export default PATH;
export const PAGES_INFO = [
  {
    pathName: PATH.ADMIN_DASHBOARD,
    pages: [
      {
        title: "Dashboard",
        path: PATH.ADMIN_DASHBOARD,
      },
    ],
  },
  {
    pathName: PATH.ADMIN_ACTIVITY_LOG,
    pages: [
      {
        title: "Activity Logs",
        path: PATH.ADMIN_ACTIVITY_LOG,
      },
    ],
  },
  {
    pathName: PATH.ADMIN_REPORTS,
    pages: [
      {
        title: "Reports",
        path: PATH.ADMIN_REPORTS,
      },
    ],
  },
  {
    pathName: PATH.ADMIN_PARAMETERS,
    pages: [
      {
        title: "Parameters",
        path: PATH.ADMIN_PARAMETERS,
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_LIST,
    pages: [
      {
        title: "User Management",
        path: PATH.USER_MANAGEMENT_LIST,
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_PROJECT_MANAGER,
    pages: [
      {
        title: " User Management ",
        path: PATH.USER_MANAGEMENT_LIST,
      },
      {
        title: "Project Manager",
        path: PATH.USER_MANAGEMENT_PROJECT_MANAGER,
        param: "userId",
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_USER_DETAIL,
    pages: [
      {
        title: " User Management ",
        path: PATH.USER_MANAGEMENT_LIST,
      },
      {
        title: " User Details",
        path: PATH.USER_MANAGEMENT_USER_DETAIL,
        param: "userId",
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL,
    pages: [
      {
        title: " User Management ",
        path: PATH.USER_MANAGEMENT_LIST,
      },
      {
        title: " User Details",
        path: PATH.USER_MANAGEMENT_USER_DETAIL,
        param: "userId",
      },
      {
        title: " Project Details",
        path: PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL,
        param: "id",
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_USER_PROJECT_ASSET_DETAIL,
    pages: [
      {
        title: " User Management ",
        path: PATH.USER_MANAGEMENT_LIST,
      },
      {
        title: " User Details",
        path: PATH.USER_MANAGEMENT_USER_DETAIL,
        param: "userId",
      },
      {
        title: " Project Details",
        path: PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL,
        param: "id",
      },
      {
        title: " Asset Details",
        path: PATH.USER_MANAGEMENT_USER_PROJECT_ASSET_DETAIL,
        param: "projectId",
      },
    ],
  },
  {
    pathName: PATH.USER_MANAGEMENT_USER_DETAIL_PORTFOLIO_DASHBOARD,
    pages: [
      {
        title: " User Management ",
        path: PATH.USER_MANAGEMENT_LIST,
      },
      {
        title: " User Details",
        path: PATH.USER_MANAGEMENT_USER_DETAIL,
        param: "userId",
      },
      {
        title: " Project Details",
        path: PATH.USER_MANAGEMENT_USER_PROJECT_DETAIL,
        param: "id",
      },
      {
        title: " Portfolio Details",
        path: PATH.USER_MANAGEMENT_USER_DETAIL_PORTFOLIO_DASHBOARD,
        param: "projectId",
      },
    ],
  },
  {
    pathName: PATH.CORPORATE_DASHBOARD,
    pages: [
      {
        title: "Dashboard",
        path: PATH.CORPORATE_DASHBOARD,
      },
    ],
  },
  {
    pathName: PATH.INDIVIDUAL_DASHBOARD,
    pages: [
      {
        title: "Dashboard",
        path: PATH.INDIVIDUAL_DASHBOARD,
      },
    ],
  },
  {
    pathName: PATH.PROJECT_MANAGER_DASHBOARD,
    pages: [
      {
        title: "Dashboard",
        path: PATH.PROJECT_MANAGER_DASHBOARD,
      },
    ],
  },
  {
    pathName: PATH.PROJECT_LIST,
    pages: [
      {
        title: "Projects",
        path: PATH.PROJECT_LIST,
      },
    ],
  },
  {
    pathName: PATH.PROJECT_DETAILS,
    pages: [
      {
        title: "Projects",
        path: PATH.PROJECT_LIST,
      },
      {
        title: "Project Details",
        path: PATH.PROJECT_DETAILS,
        param: "projectId",
      },
    ],
  },
  {
    pathName: PATH.ASSET_DETAILS,
    pages: [
      {
        title: "Projects",
        path: PATH.PROJECT_LIST,
      },
      {
        title: "Project Details",
        path: PATH.PROJECT_DETAILS,
        param: "projectId",
      },
      {
        title: "Asset Details",
        path: PATH.ASSET_DETAILS,
        param: "assetDetailId",
      },
    ],
  },
  {
    pathName: PATH.PORTFOLIO_DETAILS,
    pages: [
      {
        title: "Projects",
        path: PATH.PROJECT_LIST,
      },
      {
        title: "Project Details",
        path: PATH.PROJECT_DETAILS,
        param: "projectId",
      },
      {
        title: "Portfolio Details",
        path: PATH.PORTFOLIO_DETAILS,
        param: "assetDetailId",
      },
    ],
  },
  {
    pathName: PATH.TOTAL_TENANTS,
    pages: [
      {
        title: "Tenants",
        path: PATH.TOTAL_TENANTS,
      },
    ],
  },
  {
    pathName: PATH.TASK_ASSIGNED,
    pages: [
      {
        title: "Task Assigned",
        path: PATH.TASK_ASSIGNED,
      },
    ],
  },
  {
    pathName: PATH.PROFILE_SETTING,
    pages: [
      {
        title: "Profile Settings",
        path: PATH.PROFILE_SETTING,
      },
    ],
  },
];
